import React, {useEffect, useRef} from "react";

import Quill from 'quill/dist/quill.min.js';
import "quill/dist/quill.core.js";
import "quill/dist/quill.core.css";

import "./QuillTextarea.scss";
import {QUILL_DEFAULT} from "../../props/constants";


export default function QuillTextarea({onChange, value}) {
    const ref = useRef();
    const editor = useRef(null);

    useEffect(()=>{
        editor.current = new Quill(ref.current, QUILL_DEFAULT);
        editor.current.on('text-change', (delta,oldDelta, source)=>{
            if (source==="user" && onChange) {
                onChange(editor.current.getContents());
            }
        });
    }, []);

    useEffect(()=>{
        editor.current.setContents(value);
    }, [value]);

    return (
        <div ref={ref}>
        </div>
    );
}