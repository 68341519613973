import React, {useEffect, useState} from "react";
import DatePicker, {setDefaultLocale, registerLocale} from "react-datepicker";
import "./date-input.scss";
import baseInputStyles from "../../forms/fields/BaseInput.module.scss";

import cs from "date-fns/locale/cs";
registerLocale("cs", cs);
setDefaultLocale("cs");

export default function DateInput({selected, onChange, caption, showTimeSelect, disableInline, className, maxDate}) {

    const [date, setDate] = useState(selected);

    useEffect(()=>{
        if (!date && selected)
            setDate(selected);
    }, [selected]);

    const change = (value) => {
        setDate(value);
        onChange(value);
    };

    return (
        <div className={className}>
            {
                caption &&
                    <div className={baseInputStyles.caption}>
                        {caption}
                    </div>
            }
            <DatePicker
                selected={date}
                onChange={change}
                showTimeSelect={showTimeSelect}
                inline={!disableInline}
                dateFormat="d. M. yyyy"
                maxDate={maxDate}
            />
        </div>
    )
}