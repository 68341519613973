import React from "react";

import styles from "./HalfLayout.module.scss";
import DefaultLayout from "./DefaultLayout";

export default function HalfLayout({children, className}) {
    return (
        <DefaultLayout className={className}>
            <section className={styles.section}>
                {children}
            </section>
        </DefaultLayout>
    );
}