import React, {useContext, useState} from "react";
import AppContext from "../props/appContext";
import FormWrap from "./FormWrap";
import TextInput from "./fields/TextInput";
import Button from "../parts/content/Button";
import Textarea from "./fields/Textarea";
import {notice} from "../props/constants";
import {convertValues} from "../props/helpers";

export default function AskAQuestionForm() {
    const app = useContext(AppContext);

    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const submit = (values, clear) => {
        setError(undefined);

        const apiRequest = app.createApiRequest(
            "POST",
            "/premium/question"
        );

        apiRequest.setLoading(setLoading);
        apiRequest.setBody(convertValues(values));

        apiRequest.run().then(response => {
           if (response.ok) {
                setError({type: "success", msg: notice(1003)});
                clear();
           } else {
                setError(notice(7));
           }
        });
    };

    return (
        <FormWrap submit={submit} error={error} setError={setError}>
            <TextInput id="subject" caption="Předmět dotazu" clearAfterSubmit required />
            <Textarea id="content" caption="S čím potřebujete poradit?" clearAfterSubmit required />
            <Button loading={loading}>položit dotaz</Button>
        </FormWrap>
    );
}