import React, {useContext, useEffect, useRef, useState} from "react";
import SearchBox from "../../parts/content/SearchBox";
import DataGrid from "../../parts/content/table/DataGrid";
import AppContext from "../../props/appContext";
import {notice} from "../../props/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";

export default function UsersPage() {
    const app = useContext(AppContext);
    const history = useHistory();

    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(1);
    const [users, setUsers] = useState({data: []});
    const [search, setSearch] = useState("");
    const timer = useRef(-1);
    const controller = useRef(new window.AbortController());

    const searchRequest = (pattern) => {
        controller.current = new window.AbortController();
        const apiRequest = app.createApiRequest(
            "GET",
            `/admin/user/all/${page}?search=${pattern}`
        );
        apiRequest.setLoading(setLoading);
        apiRequest.setController(controller.current);
        apiRequest.run().then(response => {
            if (response.ok) {
                response.json().then(setUsers);
            } else
                app.messages.flashMessage("failed", notice(7));
        });
    };

    useEffect(()=>{
        searchRequest(search);

        return () => {
            controller.current.abort();
        };
    }, [page]);

    const makeSearch = (val) => {
        if (page!==1)
            setPage(1);
        else
            searchRequest(val);
    };

    const changeValue = (e) => {
        const val = e.target.value;
        setSearch(val);
        if (timer.current!==-1)
            clearTimeout(timer.current);
        timer.current = setTimeout(()=>makeSearch(val), 500);
    };

    const forceChangeValue = (value) => {
        if (timer.current!==-1)
            clearTimeout(timer.current);
        makeSearch(value);
    };

    return (
        <React.Fragment>
            <SearchBox
                value={search}
                onChange={changeValue}
                forceChange={forceChangeValue}
            />
            <DataGrid
                header={[
                    {type: "text", id: "username", caption: "Uživatelské jméno"},
                    {type: "text", id: "email", caption: "E-mail"},
                    {type: "datetime", id: "endOfPremium", caption: "Konec plánu"},
                    {type: "buttons", components: [
                        {onClick: ({id})=>history.push(`/vip-zona/uzivatel/${id}`),
                            caption: "detail", frontIcon: <FontAwesomeIcon icon={faInfo} />}
                    ]}
                ]}

                loading={loading}

                pagination={{
                    page: users.page,
                    lastPage: users.lastPage,
                    setPage: setPage
                }}

                showHeader
                data={users.data}
                responsive
            />
        </React.Fragment>
    );
}