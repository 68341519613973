import React, {useState} from "react";

import styles from "./MenuCards.module.scss";
import MenuCardItem from "./MenuCardsItem";

export default function MenuCards({items, menuClassName, itemClassName}) {
    const [current, setCurrent] = useState(items[0]);

    return (
        <React.Fragment>
            <div className={styles.menu}>
                {items.map((item)=>
                    <MenuCardItem
                        key={item.key}
                        active={current.key===item.key}
                        onClick={()=>setCurrent(item)}
                    >
                        {item.name}
                    </MenuCardItem>
                )}
            </div>
            <div className={styles.content}>
                {current.content}
            </div>
        </React.Fragment>
    );
}