import React, {useContext, useEffect, useState} from "react";
import NarrowLayout from "../../layout/NarrowLayout";
import CrossHeader from "../../parts/header/CrossHeader";
import {useHistory} from "react-router-dom";
import ChangeForgotPassForm from "../../forms/ChangeForgotPassForm";
import {useQuery} from "../../props/hooks";
import AppContext from "../../props/appContext";
import {notice} from "../../props/constants";
import Loading from "../../parts/content/Loading";

export default function ChangePassPage() {
    const app = useContext(AppContext);
    const history = useHistory();
    const queryParams = useQuery();
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        if (queryParams.get("id")==null || queryParams.get("token")==null)
            history.replace(`/vip-zona`);
        else {
            const id = queryParams.get("id");
            const token = queryParams.get("token");

            const apiRequest = app.createApiRequest(
                "POST",
                "/forgot-password/validity"
            );

            apiRequest.setLoading(setLoading);

            apiRequest.setBody({
                userId: id,
                token: token
            });

            apiRequest.run().then(response => {
               if (!response.ok) {
                   app.messages.flashMessage("failed", notice(12));
                   history.replace(`/vip-zona`);
               }
            });
        }
    }, []);

    return (
        <Loading show={loading}>
            <NarrowLayout>
                <CrossHeader animate={true} heading={"Změna hesla"} crossClick={()=>history.push(`/vip-zona`)} />
                <ChangeForgotPassForm id={queryParams.get("id")} token={queryParams.get("token")} />
            </NarrowLayout>
        </Loading>
    );
}