import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import React from "react";

import styles from "./SearchBox.module.scss";

export default function SearchBox({value, onChange, forceChange}) {
    return (
        <div className={styles.wrap}>
            <input type="text" value={value} onChange={onChange}
                   onKeyPress={(e)=>e.key==="Enter" ? forceChange(e.target.value) : {}}
                   className={styles.input} />
            <div className={styles.searchBtn} onClick={()=>forceChange(value)}>
                <FontAwesomeIcon icon={faSearch} />
            </div>
        </div>
    );
}