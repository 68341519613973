import React, {useState} from 'react';
import MenuItem from "./MenuItem";
import MenuItems from "./MenuItems";
import {Link} from "react-router-dom";
import {useScrollToAnchor} from "../../props/helpers";

export default function MainMenu({className, ...props}) {

    const [mobileMenu, setMobileMenu] = useState(false);

    const scroll = useScrollToAnchor(setMobileMenu);

    return (
        <MenuItems className={className} mobileMenu={mobileMenu} mobileMenuCallback={setMobileMenu} {...props}>
            <MenuItem func={() => scroll("o-nas")}>O nás</MenuItem>
            <MenuItem func={() => scroll("sluzby")}>Služby</MenuItem>
            <MenuItem func={() => scroll("recenze")}>Recenze</MenuItem>
            <MenuItem func={() => scroll("kontakt")}>Kontakt</MenuItem>
            <MenuItem special><Link to={"/vip-zona"} >V.I.P. zóna</Link></MenuItem>
        </MenuItems>
    );
}