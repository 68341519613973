import React from "react";

import styles from "./NumberedListItem.module.scss";

export default function NumberedListItem({children, number}) {
    return (
        <div className={styles.pack}>
            <div className={styles.number}>
                {number}
            </div>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    );
}