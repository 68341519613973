import React from "react";

import styles from "./Table.module.scss";

export default function TableRow({className, children, cellStyles, onClick}) {
    return (
        <div className={styles.row+(className ? " "+className : "")} onClick={onClick}>
            {
                React.Children.map(
                    children,
                    (child)=>React.cloneElement(child, {className: cellStyles, ...child.props})
                )
            }
        </div>
    )
}