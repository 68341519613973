import React, {useContext, useEffect, useState} from "react";
import BillingInfoForm from "../../forms/BillingInfoForm";
import AppContext from "../../props/appContext";
import Loading from "../../parts/content/Loading";
import {notice} from "../../props/constants";

export default function BillingInfoPage() {
    const app = useContext(AppContext);

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(()=>{
        const controller = new window.AbortController();
        const apiRequest = app.createApiRequest(
            "GET",
            "/profile/billing-info"
        );

        apiRequest.setLoading(setLoading);
        apiRequest.setController(controller);
        apiRequest.run().then(response => {
            if (response.ok) {
                response.json().then(setData);
            } else
                app.messages.flashMessage("failed", notice(7));
        });

        return ()=>{
            controller.abort();
        };
    }, []);

    return (
        <Loading show={loading}>
            <BillingInfoForm change data={data} />
        </Loading>
    );
}