import {useHistory, useLocation} from "react-router-dom";
import React from "react";

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function useSearchParams() {
    const location = useLocation();
    const history = useHistory();

    return (key, ...value) => {
        let queryParams = new URLSearchParams(location.search);
        const keys = key.split(",");
        for (let i=0; i<keys.length; i++) {
            queryParams.set(keys[i], value[i]);
        }
        history.push({search: queryParams.toString()});
    };
}

export function useCurrentPath() {
    return useLocation().pathname+useLocation().search;
}