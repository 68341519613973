import React from 'react';

import styles from "./MenuItems.module.scss";

export default function MenuItems({children, className, withoutBurger, burgerBlack, mobileMenu, mobileMenuCallback}) {

    let icons = [];

    const items = React.Children.map(children, child => {

        const element = React.cloneElement(child, {
            className: styles.menuItem + (withoutBurger ? " " + styles.withoutBurger : ""),
            withoutBurger: withoutBurger
        });

        if (child.props.icon) {
            icons.push(element);
            return null;
        }

        return element;
    });

    return (
        <React.Fragment>
            {
                !withoutBurger &&
                <div className={styles.burgerWrap}>
                    <div
                        className={styles.burger+(mobileMenu ? " "+styles.activeBurger : "")+(burgerBlack ? " "+styles.burgerBlack : "")}
                        onClick={()=>mobileMenuCallback(!mobileMenu)}
                    >
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            }
            <div
                className={styles.menuItems+(className ? " "+className : "")+(mobileMenu ? " "+styles.activeMenu : "")+(withoutBurger ? " "+styles.withoutBurger : "")}
            >
                {items}
                {
                    icons.length!==0 &&
                    <div className={styles.iconsWrap}>
                        {icons}
                    </div>
                }
            </div>
        </React.Fragment>
    );
}