import React, {useContext, useEffect, useState} from "react";
import Loading from "./Loading";
import AppContext from "../../props/appContext";
import {getImageSrc, readDataURL} from "../../props/helpers";

const CACHE_NAME = "fitmaliny-images";

function storeCacheDate() {
    const dateObj = new Date();
    localStorage.setItem(CACHE_NAME, `${dateObj.getMonth()}/${dateObj.getDate()}`);
}

function isTimeToDeleteCache() {
    const date = localStorage.getItem(CACHE_NAME);
    if (!date) return true;

    let [month, day] = date.split("/");
    month = Number(month);
    day = Number(day);

    const currDate = new Date();

    if (month<currDate.getMonth())
        return true;

    return month === currDate.getMonth() && day < currDate.getDate();
}

export default function Image({iri, id, alt, imageParams, wrapClassName, offCache, whiteLoading, setRef, useClassicLoad}) {
    const app = useContext(AppContext);

    const [loading, setLoading] = useState(!!useClassicLoad);
    const [image, setImage] = useState(false);

    const processResponse = (response) => {
        response.blob().then((image) => {
            readDataURL(image, (data) => {
                setImage(data);
                setLoading(false);
            });
        });
    };

    useEffect(()=>{
        const imageLoad = async () => {
            if (iri && id) {
                let isCacheSupported = 'caches' in window;
                let cache = undefined;
                let response = undefined;
                if (isCacheSupported) {
                    if (isTimeToDeleteCache()) {
                        await caches.delete(CACHE_NAME);
                        storeCacheDate();
                    }
                    cache = await caches.open(CACHE_NAME);
                    response = await cache.match(iri + id);
                }
                if (!offCache && response) {
                    processResponse(response);
                } else {
                    const apiRequest = app.createApiRequest(
                        "GET",
                        iri + id
                    );

                    setLoading(true);

                    apiRequest.run()
                        .then((response) => {
                            if (response.ok) {
                                if (isCacheSupported)
                                    cache.put(iri+id, response).then(()=> {
                                        cache.match(iri + id).then(response => processResponse(response))
                                    });
                                else processResponse(response);
                            }
                        });
                }
            } else {
                setImage(false);
            }
        };

        if (!useClassicLoad)
            imageLoad();
    }, [id, iri, app, useClassicLoad]);

    return (
        <Loading show={loading} wrapClassName={wrapClassName} white={whiteLoading}>
            {
                useClassicLoad
                    ?
                    (
                        id && iri &&
                        <img
                            src={getImageSrc(iri+id)}
                            ref={setRef}
                            onLoad={()=>id && setLoading(false)}
                            alt={alt}
                            {...imageParams}
                        />
                    )
                    :
                    (
                        image && <img src={image} ref={setRef} alt={alt} {...imageParams} />
                    )
            }
        </Loading>
    );
}