import React, {useContext, useEffect, useState} from "react";
import Modal from "./Modal";
import ChangeCaloriesForm from "../../forms/ChangeCaloriesForm";
import {useSpring, animated} from "react-spring";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import styles from "./ChangeCaloriesModal.module.scss";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import AppContext from "../../props/appContext";
import Button from "../content/Button";

export default function ChangeCaloriesModal({show, close}) {
    const app = useContext(AppContext);

    const [done, setDone] = useState(false);

    const formProps = useSpring(done ? {opacity: 0, zIndex: -10} : {opacity: 1, zIndex: 10});
    const doneProps = useSpring(done ? {opacity: 1, zIndex: 10} : {opacity: 0, zIndex: -10});

    const showDone = () => {
        setDone(true);
    };

    useEffect(()=>{
        if (!show) {
            setDone(false);
        }
    }, [show]);

    return (
        <Modal
            heading={"Vítejte"}
            show={show}
            close={close}
        >
            <animated.div style={formProps}>
                <p>
                    Vítáme Vás do rodiny FitMalin! Jsme rádi, že jste si vybrali zrovna naši aplikaci. Než se pustíte
                    do objevování, vypočítejte si Váš kalorický a makroživinný příjem na míru. Vypočtenou hodnotu můžete
                    kdykoliv změnit ve svém profilu (hlavní menu > ikonka profilu).
                </p>
                <ChangeCaloriesForm showDone={showDone} />
            </animated.div>
            <animated.div className={styles.doneBlock} style={doneProps}>
                <div className={styles.centerBlock}>
                    <div className={styles.checkCircleBlock}>
                        <FontAwesomeIcon icon={faCheckCircle} />
                    </div>
                    <div>
                        <div className={styles.heading}>Hotovo! Váš kalorický a makroživnný denní příjem je:</div>
                        {
                            app.calories && app.calories.total &&
                            <React.Fragment>
                                <div className={styles.macroBlock+" "+styles.caloriesBlock}>{app.calories.total}&nbsp;kcal</div>
                                <div className={styles.macroBlock}>{app.calories.proteins[0]}&nbsp;–&nbsp;{app.calories.proteins[1]}&nbsp;g bílkovin</div>
                                <div className={styles.macroBlock}>{app.calories.fats[0]}&nbsp;–&nbsp;{app.calories.fats[1]}&nbsp;g tuků</div>
                                <div className={styles.macroBlock}>{app.calories.carbohydrates[0]}&nbsp;–&nbsp;{app.calories.carbohydrates[1]}&nbsp;g sacharidů</div>
                            </React.Fragment>
                        }
                        <div className={styles.infoBlock}>
                            Nezapomeňte pravidelně zaznamenávat váhu, denní příjmy se Vám poté budou pravidelně aktualizovat.
                            Váhu zapíšete v nastavení profilu (hlavní menu > ikonka profilu > vážení).
                        </div>
                        <div className={styles.button}>
                            <Button onClick={()=>close()}>Pokračovat</Button>
                        </div>
                    </div>
                </div>
            </animated.div>
        </Modal>
    );
}