import React, {useState} from "react";
import Button from "../Button";
import TableCell from "./TableCell";
import TableRow from "./TableRow";
import TableWrap from "./TableWrap";
import {ReactComponent as Loading} from "../../../files/images/loading.svg";

import styles from "./DataGrid.module.scss";
import {convertToCzechFloatString, dateToCzech, truncateText} from "../../../props/helpers";
import Alert from "../../../forms/fields/Alert";
import TextInput from "../../../forms/fields/TextInput";
import SelectBox from "../../../forms/fields/SelectBox";
import Pagination from "../Pagination";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";

export default function DataGrid({data, header, loading, onClickRow, rowHover, pagination, className, showHeader, responsive}) {

    const [inputValues, setInputValues] = useState({});

    const append = (headerItem)=>headerItem.append ? <React.Fragment>&nbsp;{headerItem.append}</React.Fragment> : "";
    const caption = (headerItem)=> headerItem.caption ? <span className={styles.secondCaption}>{headerItem.caption}&nbsp;</span> : "";

    return (
        <div className={styles.wrapper+(className ? ` ${className}` : "")}>
            <div className={styles.wrapper+(className ? ` ${className}` : "")+(responsive ? ` ${styles.responsive}` : "")}>
                <TableWrap className={styles.table} cellStyles={styles.tableCell}>
                    {
                        showHeader &&
                        <TableRow className={styles.tableRow+" "+styles.tableRowHeader}>
                            {
                                header.map((item, i) =>
                                    <TableCell key={i}>
                                        {item.caption}
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    }
                    {
                        data.map(item =>
                            <TableRow key={item.id} onClick={onClickRow ? (e)=>onClickRow({event: e, item: item}) : undefined} className={styles.tableRow+(rowHover ? ` ${styles.rowHover}` : "")}>
                                {
                                    header.map(headerItem => {
                                        const key = item.id+headerItem.id;
                                        switch(headerItem.type) {
                                            case "boolean":
                                                return (
                                                    <TableCell key={key}>
                                                        {caption(headerItem)}
                                                        <FontAwesomeIcon icon={item[headerItem.id] ? faCheck : faTimes} />
                                                    </TableCell>
                                                );

                                            case "select":
                                                return (
                                                    <TableCell key={key}>
                                                        <SelectBox
                                                            id={headerItem.id}
                                                            value={item[headerItem.id]}
                                                            options={headerItem.options}
                                                            withoutCaption
                                                            valueCallback={(value, invalid)=>{
                                                                if (!invalid)
                                                                    setInputValues((oldValues)=>({...oldValues, [item.id]:{
                                                                            ...oldValues[item.id],
                                                                            [headerItem.id]: value
                                                                        }}));
                                                            }}
                                                        />
                                                    </TableCell>
                                                );

                                            case "inputText":
                                                return (
                                                    <TableCell key={key}>
                                                        <TextInput
                                                            id={headerItem.id}
                                                            value={item[headerItem.id]}
                                                            valueCallback={(value, invalid)=>{
                                                                if (!invalid)
                                                                    setInputValues((oldValues)=>({...oldValues, [item.id]:{
                                                                            ...oldValues[item.id],
                                                                            [headerItem.id]: value
                                                                        }}));
                                                            }}
                                                            required
                                                            withoutCaption
                                                        />
                                                    </TableCell>
                                                )

                                            case "text":
                                                const content = headerItem.translateFce
                                                    ? headerItem.translateFce(item[headerItem.id])
                                                    : item[headerItem.id];
                                                return (
                                                    <TableCell innerClassName={styles.textInnerCell} key={key}>
                                                        {caption(headerItem)}
                                                        {headerItem.truncate ? truncateText(content, headerItem.truncate) : content}
                                                        {append(headerItem)}
                                                    </TableCell>
                                                );

                                            case "translate":
                                                return (
                                                    <TableCell innerClassName={styles.textInnerCell} key={key}>
                                                        {caption(headerItem)}
                                                        {headerItem.translate(item[headerItem.id])}
                                                        {append(headerItem)}
                                                    </TableCell>
                                                );

                                            case "float":
                                                return (
                                                    <TableCell innerClassName={styles.textInnerCell} key={key}>
                                                        {caption(headerItem)}
                                                        {convertToCzechFloatString(item[headerItem.id])}{append(headerItem)}
                                                    </TableCell>
                                                );

                                            case "range":
                                                return (
                                                    <TableCell innerClassName={styles.textInnerCell} key={key}>
                                                        {caption(headerItem)}
                                                        {item[headerItem.id][0]}&nbsp;–&nbsp;{item[headerItem.id][1]}{append(headerItem)}
                                                    </TableCell>
                                                );

                                            case "datetime":
                                                return (<TableCell key={key}>{caption(headerItem)}{dateToCzech(new Date(item[headerItem.id]))}</TableCell>)

                                            case "buttons":
                                                return (
                                                    <TableCell key={key} className={styles.tableCellBtns+" "+styles.tableCell}>
                                                        {
                                                            headerItem.components.map((button, i) =>
                                                                !button.showCondition || button.showCondition(item) ?
                                                                    <div key={i} className={styles.tableCellBtn}>
                                                                        <Button
                                                                            onClick={(e)=>button.onClick({event: e, id: item.id, values: inputValues[item.id], item: item})}
                                                                            small
                                                                            frontIcon={button.frontIcon}
                                                                        >
                                                                            {button.caption}
                                                                        </Button>
                                                                    </div>
                                                                    : null
                                                            )
                                                        }
                                                    </TableCell>
                                                );

                                            default:
                                                return <TableCell key={key} />;
                                        }
                                    })
                                }
                            </TableRow>
                        )
                    }
                </TableWrap>
                {
                    !loading && data.length===0 &&
                    <Alert className={styles.noItems}>
                        {({
                            type: "info",
                            msg: "Žádné položky k zobrazení"
                        })}
                    </Alert>
                }
            </div>
            {
                pagination &&
                <Pagination
                    page={pagination.page}
                    lastPage={pagination.lastPage}
                    setPage={pagination.setPage}
                />
            }
            {
                loading &&
                <div className={styles.loading}>
                    <Loading />
                </div>
            }
        </div>
    );
}