import React, {useContext, useEffect, useState} from "react";
import TextInput from "../../forms/fields/TextInput";
import SelectBox from "../../forms/fields/SelectBox";
import Button from "../content/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTrash} from "@fortawesome/free-solid-svg-icons";

import styles from "./IngredientsParams.module.scss";
import AppContext from "../../props/appContext";
import {notice, UNITS} from "../../props/constants";
import {convertCzechFloatString, convertToCzechFloatString} from "../../props/helpers";
import Alert from "../../forms/fields/Alert";

export default function IngredientsParams({id, ingredientName, initAmount, initUnit, initAAmount, initAUnit, loading, remove, setLoading, articleId}) {
    const app = useContext(AppContext);

    const [values, setValues] = useState({
        amount: {value: convertToCzechFloatString(initAmount), invalid: false},
        unit: {value: initUnit, invalid: false},
        alternativeAmount: {value: convertToCzechFloatString(initAAmount), invalid: false},
        alternativeUnit: {value: initAUnit, invalid: false}
    });

    const [error, setError] = useState();

    useEffect(()=>{
        setValues({
            amount: {value: convertToCzechFloatString(initAmount), invalid: false},
            unit: {value: initUnit, invalid: false},
            alternativeAmount: {value: convertToCzechFloatString(initAAmount), invalid: false},
            alternativeUnit: {value: initAUnit, invalid: false}
        });
    }, [initAmount, initUnit, initAAmount, initAUnit]);

    const [disabledSubmit, setDisabledSubmit] = useState(true);

    const changeValue = (name, value, invalid) => {
        if (invalid) setDisabledSubmit(true);
        else {
            let isError = false;
            for (let [key, value] of Object.entries(values)) {
                if (key!==name) {
                    if (value.invalid)
                        isError = true;
                }
            }

            if (isError)
                setDisabledSubmit(true);
            else
                setDisabledSubmit(false);
        }

        setValues((oldValues)=>({
            ...oldValues,
            [name]: {value: value, invalid: invalid}
        }));
    };

    const submit = () => {
        const apiRequest = app.createApiRequest(
            "PUT",
            "/admin/article/choose-ingredient/change"
        );

        apiRequest.setLoading(setLoading);

        apiRequest.setBody({
            articleId: articleId,
            ingredientId: id,
            amount: convertCzechFloatString(values.amount.value),
            unit: values.unit.value,
            alternative_amount: convertCzechFloatString(values.alternativeAmount.value),
            alternative_unit: values.alternativeUnit.value
        });

        apiRequest.run().then(response => {
            if (response.ok)
                setError({type: "success", msg: notice(1006)});
            else
                setError(notice(7));
        });
    };

    return (
        <div className={styles.ingredient}>
            <div className={styles.ingredientName}>
                {ingredientName}
            </div>
            <div className={styles.amountInputsWrap}>
                <div className={styles.amountInputs}>
                    <div className={styles.amountInputWrap}>
                        <TextInput
                            validate="number"
                            value={values.amount.value}
                            caption="Množství"
                            inputClassName={styles.amountInput}
                            valueCallback={(value, invalid)=>changeValue("amount", value, invalid)}

                            required
                        />
                    </div>
                    <SelectBox
                        value={values.unit.value}
                        options={UNITS}
                        caption="Jednotka"
                        valueCallback={(value, invalid)=>changeValue("unit", value, invalid)}
                    />
                </div>
                <div className={styles.amountInputs}>
                    <div className={styles.amountInputWrap}>
                        <TextInput
                            validate="number"
                            value={values.alternativeAmount.value}
                            caption="A. množství"
                            inputClassName={styles.amountInput}
                            valueCallback={(value, invalid)=>changeValue("alternativeAmount", value, invalid)}
                        />
                    </div>
                    <SelectBox
                        value={values.alternativeUnit.value}
                        options={UNITS}
                        caption="Jednotka"
                        valueCallback={(value, invalid)=>changeValue("alternativeUnit", value, invalid)}
                    />
                </div>
            </div>
            {
                error &&
                    <Alert>
                        {error}
                    </Alert>
            }
            <div className={styles.ingredientButtons}>
                <Button
                    small
                    frontIcon={<FontAwesomeIcon icon={faSave} />}
                    loading={loading}
                    onClick={()=>submit()}
                    disabled={disabledSubmit}
                >
                    uložit
                </Button>
                <Button
                    onClick={()=>remove(id)}
                    small
                    frontIcon={<FontAwesomeIcon icon={faTrash} />}
                    loading={loading}
                >
                    smazat
                </Button>
            </div>
        </div>
    );
}