import React, {useContext, useEffect, useRef, useState} from "react";
import Header from "../../parts/header/Header";
import DefaultLayout from "../../layout/DefaultLayout";
import Heading from "../../parts/content/Heading";

import {useHistory} from "react-router-dom";
import SearchField from "../../parts/content/SearchField";
import Button from "../../parts/content/Button";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCarrot, faPlus, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import AppContext from "../../props/appContext";
import ArticlesGrid from "./ArticlesGrid";
import {useCurrentPath, useQuery} from "../../props/hooks";

import styles from "./ArticlesPage.module.scss";
import Modal from "../../parts/modal/Modal";
import AddIngredientForm from "../../forms/AddIngredientForm";
import DataGrid from "../../parts/content/table/DataGrid";
import { notice } from "../../props/constants";

export default function ArticlesPage() {
    const app = useContext(AppContext);
    const queryParams = useQuery();
    const prevValues = useRef("");
    const categoriesQuery = () => queryParams.get("kategorie")!==null ? queryParams.get("kategorie")  : "";
    const valueQuery = () => queryParams.get("hledat")!==null ? queryParams.get("hledat") : "";
    const pageQuery = () => queryParams.get("stranka")!==null ? Number(queryParams.get("stranka")) : 1;

    const history = useHistory();
    const [categories, setCategories] = useState(categoriesQuery());
    const [searchedValue, setSearchedValue] = useState(valueQuery());
    const [page, setPage] = useState(pageQuery());
    const path = useCurrentPath();

    const [ingredientsModal, setIngredientsModal] = useState(false);
    const [ingredientsLoading, setIngredientsLoading] = useState(false);
    const [ingredients, setIngredients] = useState({data:[]});
    const [ingredientsPage, setIngredientsPage] = useState(1);

    useEffect(()=>{
        if (prevValues.current!==window.location.search) {
            prevValues.current = window.location.search;
            setCategories(categoriesQuery());
            setSearchedValue(valueQuery());
            setPage(pageQuery());
        }
    }, [queryParams]);


    const ingredientsRequest = () => {
        const apiRequest = app.createApiRequest(
            "GET",
            `/admin/article/ingredients/${ingredientsPage}`
        );
        
        apiRequest.setLoading(setIngredientsLoading);
        
        apiRequest.run().then(response => {
            if (response.ok) {
                response.json().then(setIngredients);
            } else {
                app.messages.flashMessage("failed", notice(7));
            }
        });
    };


    useEffect(()=>{
        if (ingredientsModal) {
            ingredientsRequest();
        }
    }, [ingredientsModal, ingredientsPage]);

    const removeIngredient = ({id}) => {
        const conf = window.confirm(notice(10));
        if (conf) {
            const apiRequest = app.createApiRequest(
                "DELETE",
                "/admin/article/ingredient/"+id
            );
            apiRequest.setLoading(setIngredientsLoading);
            apiRequest.run().then(response=>{
                if (response.ok) {
                    ingredientsRequest();
                } else
                    app.messages.flashMessage("failed", notice(7));
            });
        }
    };

    const changeIngredient = ({values, id}) => {
        if (values.name!=="") {
            const apiRequest = app.createApiRequest(
                "PUT",
                "/admin/article/ingredient"
            );

            apiRequest.setLoading(setIngredientsLoading);
            apiRequest.setBody({
                id: id,
                name: values.name
            });

            apiRequest.run().then(response=>{
                if (!response.ok)
                    app.messages.flashMessage("failed", notice(7));
            });
        }
    };

    return (
        <React.Fragment>
            <Header narrow />
            <DefaultLayout>
                <Heading>Články</Heading>
                {
                    app.user.isAdmin &&
                    <div className={styles.adminButtons}>
                        <Button 
                            onClick={()=>setIngredientsModal(true)}
                            frontIcon={<FontAwesomeIcon icon={faCarrot} />} 
                            small
                        >
                            Ingredience
                        </Button>
                        <Button 
                            onClick={()=>history.push("/vip-zona/clanky/pridat", {prevLink: path})}
                            frontIcon={<FontAwesomeIcon icon={faPlus} />} 
                            small
                        >
                            Přidat článek
                        </Button>
                    </div>
                }
                <SearchField
                    categories={categories}
                />
                <ArticlesGrid
                    categories={categories}
                    searchedValue={searchedValue}
                    page={page}
                />
                <Modal
                    show={ingredientsModal}
                    heading="Ingredience"
                    close={()=>setIngredientsModal(false)}
                >
                    <AddIngredientForm refresh={ingredientsRequest} />
                    <DataGrid 
                        header={[
                            {type: "inputText", id: "name"},
                            {type: "buttons", components: [
                                {onClick: changeIngredient, caption: "uložit", frontIcon: <FontAwesomeIcon icon={faSave} />},
                                {onClick: removeIngredient, caption: "smazat", frontIcon: <FontAwesomeIcon icon={faTrash} />}
                            ]}
                        ]}

                        className={styles.ingredientsGrid}

                        loading={ingredientsLoading}

                        pagination={{
                            page: ingredients.page,
                            lastPage: ingredients.lastPage,
                            setPage: setIngredientsPage
                        }}

                        data={ingredients.data}
                    />
                </Modal>
            </DefaultLayout>
        </React.Fragment>
    );
}