import React from "react";

export default class ScrollAnimatedLayout extends React.Component {

    constructor(props) {
        super(props);

        this.innerRef=null;
        this.ref = React.createRef();
    }

    calculation() {
        let el = this.props.anchor ? this.props.anchor : (this.innerRef ? this.innerRef : this.ref.current);
        if (!el || !el.getBoundingClientRect) return false;
        return window.scrollY+((window.innerHeight/4)*3) >= el.getBoundingClientRect().top + window.scrollY;
    }

    innerRefFunc = (r) => {
      this.innerRef = r;
    };

    onScroll = () => {
        if (this.calculation()) {
            this.props.func();
            this.removeEvent();
        }
    };

    removeEvent() {
        window.removeEventListener("scroll", this.onScroll);
    }

    componentDidMount() {
        window.addEventListener("scroll", this.onScroll);
    }

    componentWillUnmount() {
        this.removeEvent();
    }

    render() {
        if (this.props.children)
            return React.Children.map(this.props.children, child => {
                if (typeof child.type === "function")
                    return React.cloneElement(child, {
                        innerRef: this.innerRefFunc,
                        ...child.props
                    });
                else
                    return React.cloneElement(child, {
                        ref: this.ref,
                        ...child.props
                    });
            });
        else return null;
    }
}