import React, {useEffect} from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import styles from "./WaitForRedirect.module.scss";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import Button from "../../parts/content/Button";

import {ReactComponent as Loading} from "../../files/images/loading.svg";

export default function WaitForRedirect({gwURL, text}) {

    useEffect(()=>{
        if (gwURL!=="")
            setTimeout(()=>window.location = gwURL, 5000);
    }, [gwURL]);

    return (
        <div className={styles.wrap}>
            <div className={styles.insideBlock}>
                <div className={styles.iconWrap}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                </div>
                <div>
                    <Loading className={styles.loading} />
                </div>
                <div className={styles.textBlock}>
                    {text}
                </div>
                <div>
                    <Button small onClick={()=>window.location = gwURL}>Přejít na platební bránu</Button>
                </div>
            </div>
        </div>
    );
}