import React from "react";
import {Spring} from "react-spring";
import ScrollAnimatedLayout from "../../layout/ScrollAnimatedLayout";

export default class AnimationPack extends React.Component {

    state = {show: false};

    render() {
        return (
            <React.Fragment>
                    <Spring native from={{opacity: 0}} to={{opacity: this.state.show ? 1 : 0}} delay={this.props.delay} >
                        {
                            props =>
                                <ScrollAnimatedLayout func={()=>this.setState({show: true})} >
                                    {React.Children.map(this.props.children, child =>
                                        React.cloneElement(child, {...child.props, style:{...props,...child.props.style}})
                                    )}
                                </ScrollAnimatedLayout>
                        }
                    </Spring>
            </React.Fragment>
        );
    }
}