import React, {useContext, useEffect, useRef, useState} from "react";
import Modal from "./Modal";
import DateTimeInput from "../content/DateTimeInput";
import TextInput from "../../forms/fields/TextInput";
import InputWrap from "../../forms/InputWrap";
import Button from "../content/Button";

import styles from "./MeasurementModal.module.scss";
import AppContext from "../../props/appContext";
import Alert from "../../forms/fields/Alert";
import {notice} from "../../props/constants";
import {convertCzechFloatString, getMacrosFromObject, getTimeString} from "../../props/helpers";
import MeasurementDataGrid from "../content/table/MeasurementDataGrid";

export default function MeasurementModal({show, close}) {
    const app = useContext(AppContext);

    const [values, setValues] = useState({});
    const [error, setError] = useState(undefined);
    const [formLoading, setFormLoading] = useState(false);

    const [currentCalories, setCurrentCalories] = useState(false);

    const weightRef = useRef();
    const dataGridRef = useRef();

    const sendMeasurement = () => {
        setError(undefined);

        weightRef.current.checkValidity(values.weight ? values.weight.value : "");
        if (!values.weight || values.weight.value==="" || values.weight.invalid) {
            setError(notice(102));
            return;
        }

        const apiRequest = app.createApiRequest(
            "POST",
            "/premium/calories/measure"
        );
        apiRequest.setLoading(setFormLoading);
        apiRequest.setBody({
            recorded_at: getTimeString(values.recordedAt.value),
            weight: convertCzechFloatString(values.weight.value)
        });
        apiRequest.run().then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setError({type: "success", msg: notice(1007)});
                    dataGridRef.current.refresh();
                    app.changeCalories(getMacrosFromObject(data));
                    setCurrentCalories(true);
                });
            } else
                setError(notice(7));
        });
    };

    useEffect(()=>{
        if (show && dataGridRef.current)
            dataGridRef.current.refresh();

        if (!show)
            setCurrentCalories(false);
    }, [show]);

    return (
        <Modal
            show={show}
            close={close}
            heading="Vážení"
        >
            <h3 className={styles.heading}>Zaznamenat vážení</h3>
            <InputWrap row>
                <DateTimeInput caption="Kdy jste vážili?"
                               onChange={(value)=>setValues(
                                   (oldValues)=>({
                                       ...oldValues,
                                       recordedAt: {value: value, invalid: false}
                                   }))}
                               maxDate={new Date()}
                               required />
                <TextInput caption="Váha [kg]" ref={(r)=>weightRef.current = r}
                           valueCallback={(value, invalid)=>setValues(
                               (oldValues)=>({
                                   ...oldValues, weight:{value: value, invalid: invalid}
                               }))}
                           validate="number,personWeight" required />
            </InputWrap>
            {
                error &&
                <Alert className={styles.alert}>
                    {error}
                </Alert>
            }
            <Button loading={formLoading} onClick={()=>sendMeasurement()}>přidat</Button>
            {
                currentCalories && app.calories && app.calories.total &&
                <Alert className={styles.infoAlert}>
                    {{type: "info", msg:
                            <React.Fragment>
                                Aktuální denní příjem: {app.calories.total}&nbsp;kcal,
                                <React.Fragment> </React.Fragment>{app.calories.proteins[0]}&nbsp;–&nbsp;{app.calories.proteins[1]}&nbsp;g bílkovin,
                                <React.Fragment> </React.Fragment>{app.calories.fats[0]}&nbsp;–&nbsp;{app.calories.fats[1]}&nbsp;g tuků,
                                <React.Fragment> </React.Fragment>{app.calories.carbohydrates[0]}&nbsp;–&nbsp;{app.calories.carbohydrates[1]}&nbsp;g sacharidů
                            </React.Fragment>
                    }}
                </Alert>
            }
            <h3 className={styles.heading}>Zaznamenaná měření</h3>
            <MeasurementDataGrid ref={dataGridRef} />
        </Modal>
    )
}