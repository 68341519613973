import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";

import styles from "./BaseInput.module.scss";
import {useSpring, animated} from "react-spring";
import {isEmail, isZIP} from "../../props/validations";
import {maxFileSize, notice} from "../../props/constants";
import AppContext from "../../props/appContext";

import update from "immutability-helper";
import {convertCzechFloatString} from "../../props/helpers";

const errorBaseStyle = {
    opacity: 0,
    marginTop: -10,
    maxHeight: 0
};

function addInvalid(valueState, key, msg) {
    valueState.invalid.set(key, msg);
}

function removeInvalid(valueState, key) {
    valueState.invalid.delete(key);
}

let usernameController;
let usernameRequest;

function checkUsername(username, app, valueState, storeInvalid) {
    if (usernameController && !usernameController.signal.aborted)
        usernameController.abort();

    usernameController = new window.AbortController();
    usernameRequest = app.createApiRequest(
        "POST",
        "/registration/exists-username"
    );
    usernameRequest.setController(usernameController);
    usernameRequest.setBody({username: username});
    usernameRequest.run().then((response)=>{
        if (response.ok) {
            addInvalid(valueState, "username", notice(2));
            storeInvalid();
        }
    });
}

let emailController;
let emailRequest;

function checkEmail(email, app, valueState, storeInvalid) {
    if (emailController && !emailController.signal.aborted)
        emailController.abort();

    emailController = new window.AbortController();
    emailRequest = app.createApiRequest(
        "POST",
        "/registration/exists-email"
    );
    emailRequest.setController(emailController);
    emailRequest.setBody({email: email});
    emailRequest.run().then(response=>{
        if (response.ok) {
            addInvalid(valueState, "uniqueEmail", notice(4));
            storeInvalid();
        }
    });
}

function isValid(value, required, validate, valueState, storeInvalid, app) {
    valueState.value = value;

    if (value!=="" && value!==undefined && validate!==undefined) {
        const parseValidate = validate.split(",");
        for (let part of parseValidate)
            switch (part) {
                case "email":
                    if (!isEmail(value))
                        addInvalid(valueState, "email", notice(101));
                    else
                        removeInvalid(valueState, "email");
                    break;

                case "uniqueEmail":
                    removeInvalid(valueState, "uniqueEmail");
                    checkEmail(value, app, valueState, storeInvalid);
                    break;

                case "username":
                    removeInvalid(valueState, "username");
                    checkUsername(value, app, valueState, storeInvalid);
                    break;

                case "password":
                case "pass":
                    if (value.length < 6)
                        addInvalid(valueState, "minLength", notice(103, 6));
                    else
                        removeInvalid(valueState, "minLength");
                    break;

                case "number":
                    value = convertCzechFloatString(value);
                    if (isNaN(value) || isNaN(parseFloat(value)))
                        addInvalid(valueState, "number", notice(105));
                    else
                        removeInvalid(valueState, "number");
                    break;

                case "integer":
                    if (isNaN(value) || !Number.isInteger(Number(value)))
                        addInvalid(valueState, "integer", notice(108));
                    else
                        removeInvalid(valueState, "integer");
                    break;

                case "year":
                    value = Number(value);
                    const currentYear = (new Date()).getFullYear()-1;
                    const fromYear = 1920;
                    if (value<=currentYear && value>=fromYear)
                        removeInvalid(valueState, "year");
                    else
                        addInvalid(valueState, "year", notice(109, fromYear, currentYear));
                    break;

                case "personHeight":
                    value = Number(value);
                    const maxHeight = 230;
                    const minHeight = 130;
                    if (value<minHeight || value>maxHeight)
                        addInvalid(valueState, "personHeight", notice(109, minHeight, maxHeight));
                    else
                        removeInvalid(valueState, "personHeight");
                    break;

                case "personWeight":
                    value = Number(value);
                    const maxWeight = 199;
                    const minWeight = 30;
                    if (value<minWeight || value>maxWeight)
                        addInvalid(valueState, "personWeight", notice(109, minWeight, maxWeight));
                    else
                        removeInvalid(valueState, "personWeight");
                    break;

                case "zip":
                    if (!isZIP(value))
                        addInvalid(valueState, "zip", notice(106));
                    else
                        removeInvalid(valueState, "zip");
                    break;

                case "fileSize":
                    let found = false;
                    for (let file of value) {
                        if (file.size>maxFileSize) {
                            addInvalid(valueState, "maxFileSize", notice(107));
                            found = true;
                            break;
                        }
                    }
                    if (!found) removeInvalid(valueState, "maxFileSize");
                    break;

                default:
                    break;
            }
    }

    if(required && (value==="" || value===undefined)) {
        addInvalid(valueState, "empty", notice(100));
    } else if (required && value!=="")
        removeInvalid(valueState, "empty");

    storeInvalid();
}


export default forwardRef((props, ref) => {
    const app = useContext(AppContext);

    const {
        children,
        caption,
        validate,
        required,
        value,
        valueCallback,
        style,
        labelClassName,
        captionClassName,
        withoutCaption,
        withoutMargin
    } = props;

    const [valueState, setValue] = useState({value: value ? value : "", invalid: new Map()});

    const errorProps = useSpring(
        valueState.invalid.size!==0 ? {opacity: 1, marginTop: 0, maxHeight: 100} : errorBaseStyle
    );
    const wrapProps = useSpring({marginBottom: valueState.invalid.state ? 15 : 25});

    const storeInvalid = ()=>{
        valueCallback(valueState.value, valueState.invalid.size!==0);
        setValue({value: valueState.value, invalid: valueState.invalid});
    };

    const callIsValid = (value) => isValid(value, required, validate, valueState, storeInvalid, app);

    useEffect(()=>{
        if (value) {
            callIsValid(value);
        }
    }, [value]);

    useImperativeHandle(
        ref,
        ()=> ({
            checkValidity(value) {
                callIsValid(value);
            },
            addInvalid(key, msg) {
                addInvalid(valueState, key, msg);
            },
            removeInvalid(key) {
                removeInvalid(valueState, key);
            },
            clear() {
                setValue(update(valueState, {value: {$set: ""}}));
            }
        })
    );

    return (
        <animated.div
            className={styles.inputGroup+(valueState.invalid.size!==0 ? " "+styles.invalid : "")}
            style={{...wrapProps, ...style, ...(withoutMargin ? {marginTop: 0} : {})}}
        >
            <label className={styles.label+(labelClassName ? " "+labelClassName : "")}>
                {React.Children.map(children,
                    child => {
                        if (child.type === "input" || child.type === "select" || child.type === "textarea")
                            return React.cloneElement(child, {
                                ...child.props,
                                onChange: (e) =>
                                    callIsValid(
                                        child.props.type==="checkbox"
                                            ? e.target.checked
                                            : (child.props.type==="file"
                                                ? e.target.files
                                                : e.target.value)
                                    ),
                            [child.props.type==="checkbox" ? "checked" : "value"]: child.props.type==="file" ? undefined : valueState.value
                            });
                        else return child;
                    }
                )}
                {
                    !withoutCaption &&
                    <span className={styles.caption+(captionClassName ? " "+captionClassName : "")}>{caption}{required ? <span className={styles.required}>*</span> : ""}</span>
                }
            </label>
            <animated.div style={errorProps} className={styles.error}>
                {valueState.invalid.size!==0 ? valueState.invalid.values().next().value : ""}
            </animated.div>
        </animated.div>
    );
});