import React from 'react';

import styles from "./Heading.module.scss";
import {headerHeightBottom} from "../../props/constants"

export default function Heading({children, withoutTopMargin, inline, centerLine, anchor}) {
    const style = {};
    if (withoutTopMargin) style.marginTop = "0";
    if (inline) style.display = "inline-block";

    return (
        <h1 className={styles.h1} style={style}>
            {children}
            <span className={"line "+styles.line+(centerLine ? " "+styles.centerLine : "")} />
            {
                anchor && <span id={anchor} className={styles.anchor} style={{top: -headerHeightBottom}} />
            }
        </h1>
    )
}