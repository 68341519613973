import React from "react";

import styles from "./Table.module.scss";

export default function TableCell({className, innerClassName, children}) {
    return (
        <div className={styles.cell+(className ? " "+className : "")}>
            <div className={innerClassName}>
                {children}
            </div>
        </div>
    )
}