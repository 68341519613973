import React from "react";

import styles from "./Alert.module.scss";

import {animated, useTransition} from "react-spring";

export default function Alert({children, className}) {

    const transitions = useTransition(true, {
        from: {opacity: 0},
        enter: {opacity: 1}
    });

    let type = styles.failed;
    if(children.type) {
        type = styles[children.type];
        children = children.msg;
    }

    return transitions(
        (style, item)=>
            item &&
            <animated.div className={styles.alert+(className ? ` ${className}` : "")+` ${type}`} style={style} key="alert">
                {children}
            </animated.div>
    );
}