import React, {useRef, useState} from "react";
import MeasurementDataGrid from "../../parts/content/table/MeasurementDataGrid";
import Button from "../../parts/content/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import AddCaloriesModal from "../../parts/modal/AddCaloriesModal";

import styles from "./CaloriesMacrosDataGrid.module.scss";

export default function CaloriesMacrosDataGrid({id, yearOfBirth}) {

    const [caloriesModal, setCaloriesModal] = useState(false);
    const dataGrid = useRef();

    const refresh = () => {
        dataGrid.current.refresh();
    };

    return (
        <React.Fragment>
            <AddCaloriesModal refresh={refresh} id={id} show={caloriesModal} close={()=>setCaloriesModal(false)} yearOfBirth={yearOfBirth} />
            <div className={styles.button}>
                <Button onClick={()=>setCaloriesModal(true)} small frontIcon={<FontAwesomeIcon icon={faPlus}/>}>přidat</Button>
            </div>
            <MeasurementDataGrid ref={dataGrid} admin id={id} />
        </React.Fragment>
    )
}