import React from "react";
import Modal from "./Modal";
import AddCaloriesForm from "../../forms/AddCaloriesForm";

export default function AddCaloriesModal({show, close, yearOfBirth, id, refresh}) {
    return (
        <Modal
            show={show}
            close={close}
            heading={"Přidat makra"}
        >
            <AddCaloriesForm yearOfBirth={yearOfBirth} id={id} refresh={refresh} />
        </Modal>
    )
}