import React, {useContext, useEffect, useState} from "react";

import styles from "./PlanChoosing.module.scss";
import Button from "../../../parts/content/Button";
import LineTextInput from "../../../parts/content/LineTextInput";
import AppContext from "../../../props/appContext";
import {notice} from "../../../props/constants";
import Loading from "../../../parts/content/Loading";
import {numberToCzech, round} from "../../../props/helpers";
import Alert from "../../../forms/fields/Alert";

export default function PlanChoosing({choosen}) {
    const app = useContext(AppContext);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [voucher, setVoucher] = useState("");
    const [voucherError, setVoucherError] = useState(false);

    const choosePlan = (planId) => {
        setVoucherError(false);
        const apiRequest = app.createApiRequest(
            "POST",
            app.isLoggedIn ? "/profile/personal-info/instant-payment" : "/registration/plan"
        );

        const body = {plan: planId};
        if (planId===4) body.code = voucher;
        apiRequest.setBody(body);

        apiRequest.setLoading(setLoading);
        apiRequest.run().then((response)=>{
            if (response.ok)
                if (app.isLoggedIn)
                    response.json().then(choosen);
                else
                    choosen();
            else if (response.status===404) {
                response.json().then((data)=>{
                    if (data.code===1) {
                        setVoucher("");
                        setVoucherError(true);
                    }
                });
            } else
                app.messages.flashMessage("failed", notice(7));
        });
    };

    useEffect(()=>{
        let unmounted = false;

        const apiRequest = app.createApiRequest(
            "GET",
            "/registration/plans"
        );
        apiRequest.run().then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    if (!unmounted) setData(data);
                });
            }
        });

        return ()=>{
            unmounted = true;
        };
    }, []);

    return (
        <Loading show={data.length===0}>
            <div className={styles.wrap}>
                {
                    data.map((plan)=>
                        <div key={plan.id} className={plan.id===4 ? styles.voucher : styles.card}>
                            {
                                plan.id===4
                                ?
                                    <React.Fragment>
                                        <LineTextInput
                                            label={"Využití voucheru"}
                                            buttonCaption={"použít"}
                                            className={styles.lineTextInput}
                                            labelClassName={styles.label}
                                            btnClassName={styles.button}
                                            loading={loading}
                                            value={voucher}
                                            onChange={(e)=>setVoucher(e.target.value)}
                                            onClick={()=>choosePlan(plan.id)}
                                        />
                                        {
                                            voucherError &&
                                                <Alert className={styles.alert}>
                                                    {notice(8)}
                                                </Alert>
                                        }
                                    </React.Fragment>
                                :
                                    <React.Fragment>
                                        <div className={styles.heading}>{plan.name}</div>
                                        <div className={styles.duration}>{plan.duration}&nbsp;dní&nbsp;*</div>
                                        <div className={styles.price}>{numberToCzech(round(plan.price/(plan.duration/31), 1))} Kč/měsíc</div>
                                        <Button loading={loading} className={styles.chooseBtn} onClick={()=>choosePlan(plan.id)}>vybrat</Button>
                                    </React.Fragment>
                            }
                        </div>
                    )
                }
            </div>
            <span className={styles.star}>* Platba probíhá předem a zpřístupní V.I.P. služby na daný počet dní. 2 dny
                před koncem členství dojde k opakování platby, které se dá zrušit v nastavení profilu.</span>
        </Loading>
    );
}