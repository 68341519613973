import recipes from "../files/images/food-min.png";
import training from "../files/images/training-min.png";
import school from "../files/images/school-min.png";
import React from "react";

export const headerHeightTop = 150;
export const headerHeightBottom = 60;
export const headerHeightMedium = 100;

export const apiAddress = "/backend";

export const maxFileSize = 3000000;
export const maxUploadSize = 6000000;

export const CATEGORY = {
    1: {name: "Recepty", minImage: recipes, webalize: "recepty"},
    2: {name: "Zacvič si", minImage: training, webalize: "zacvic-si"},
    3: {name: "Škola malin", minImage: school, webalize: "skola-malin"}
};

export const PAYMENT_STATE = {
    CREATED: "CREATED",
    GOPAY_SENT: "GOPAY_SENT",
    METHOD_CHOSEN: "METHOD_CHOSEN",
    PAID: "PAID",
    AUTHORIZED: "AUTHORIZED",
    CANCELED: "CANCELED",
    TIMEOUTED: "TIMEOUTED",
    REFUNDED: "REFUNDED",
    PARTIALLY_REFUNDED: "PARTIALLY_REFUNDED",
    USED_VOUCHER: "USED_VOUCHER"
};

export const PAYMENT_TRANSLATE = {
    [PAYMENT_STATE.CREATED]: "Vytvořena",
    [PAYMENT_STATE.GOPAY_SENT]: "Předáno GoPay",
    [PAYMENT_STATE.METHOD_CHOSEN]: "Vybrána metoda",
    [PAYMENT_STATE.PAID]: <span className="successColored">Zaplaceno</span>,
    [PAYMENT_STATE.AUTHORIZED]: "Autorizováno",
    [PAYMENT_STATE.CANCELED]: "Zrušeno",
    [PAYMENT_STATE.TIMEOUTED]: "Zrušeno",
    [PAYMENT_STATE.REFUNDED]: "Navráceno",
    [PAYMENT_STATE.PARTIALLY_REFUNDED]: "Částečně navráceno",
    [PAYMENT_STATE.USED_VOUCHER]: <span className="successColored">Využit voucher</span>,
};

export function notice(code, ...params) {
    switch (code) {
        // GENERAL
        case 0: return `Nesprávné uživatelské jméno nebo heslo.`;
        case 1: return `Nemáte zaplacený přístup do V.I.P. sekce, vaše možnosti jsou omezené.`;
        case 2: return `Toto uživatelské jméno už někdo používá, zvol jiné.`;
        case 3: return `Byl/a jste odhlášen/a.`;
        case 4: return `Tento e-mail už někdo používá, zvol jiný.`;
        case 5: return `Heslo změněno.`;
        case 6: return `Aktuální heslo není správné.`;
        case 7: return `Něco se nepovedlo, ${!window.navigator.onLine ? 'pravděpodobně nejste připojeni k internetu, ' : ''}zkuste to prosím později.`;
        case 8: return `Voucher není platný, rozlišují se velká a malá písmena.`;
        case 9: return `Vaše relace se serverem byla ukončena (pravděpodobně z důvodu nečinnosti), data byla zahozena, musíte tedy vyplnit registraci znovu.`;
        case 10: return `Doopravdy si to přejete smazat?`;
        case 11: return `Bohužel se nepovedlo toto smazat.`;
        case 12: return `Žádost pro obnovení hesla není platná, vygenerujte novou.`;
        case 13: return `Takový e-mail nemáme v databázi.`;
        case 14: return `Právě je platná žádost o obnovení hesla, kterou jsme odeslali na tento e-mail. Nová žádost půjde vygenerovat až po skončení platnosti.`;
        case 15: return `Doopravdy chcete tuto otázku označit jako zodpovězenou? Myslete na to, že klientovi se odešle e-mail, že jeho dotaz bude zodpovězen.`;
        case 16: return `Tato ingredience u tohoto receptu již je.`;
        case 17: return `Musíte souhlasit s obchodními podmínkami.`;
        case 18: return `Neevidujeme u Vás žádnou opakovanou platbu, nemohli jsme tedy žádnou takovou platbu zrušit.`;
        case 19: return `Opakovanou platbu se nepodařilo zrušit, je možné, že žádnou u Vás neevidujeme. Pokud Vám strháváme částky, obraťte se na info@fitmaliny.cz.`;
        case 20: return `Doopravdy si přejete zrušit opakování plateb? Pro obnovení budete muset znovu vygenerovat platbu novou.`;
        case 21: return `Musíte souhlasit s podmínkami opakovaných plateb.`

        // FORMS
        case 100: return `Toto pole je povinné`;
        case 101: return `Napište validní e-mailovou adresu`;
        case 102: return `Některé položky nejsou validní`;
        case 103: return `Minimální délka musí být ${params[0]} znaků`;
        case 104: return `Kontrola hesla se neshoduje s heslem`;
        case 105: return `Toto pole musí být číselné`;
        case 106: return `Napište validní PSČ`;
        case 107: return `Maximální velikost souboru je ${maxFileSize/1000000} MB, pokoušíte se nahrát soubor, který je větší`;
        case 108: return `Toto pole musí být celočíselné`;
        case 109: return `Toto pole musí být v rozmezí od ${params[0]} do ${params[1]}`;

        // SUCCESS
        case 1000: return `Uživatelské jméno bylo změněno.`;
        case 1001: return `E-mail byl změněn.`;
        case 1002: return `Na daný e-mail jsme odeslali odkaz na obnovu hesla.`;
        case 1003: return `Dotaz byl odeslán, očekávejte jeho odpověď v nedělním článku s odpověďmi.`;
        case 1004: return `Otázka byla označena jako zodpovězena.`;
        case 1005: return `Úspěšně smazáno.`;
        case 1006: return `Uloženo.`;
        case 1007: return `Přidáno.`;
        case 1008: return `Opakovaná platba byla zrušena.`;

        default: return "";
    }
}

export const QUILL = {
    FORMATS: ["bold","italic","link","strike","list", "indent", "header"],
    TOOLBAR: [
        [{ 'header': [2, 3, 4, false] }],
        ["bold","italic","strike"],
        ["link"],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        ['clean']
    ]
};

export const QUILL_DEFAULT = {
    theme: 'snow',
    formats: QUILL.FORMATS,
    modules: {
        toolbar: QUILL.TOOLBAR
    }
};

export const UNITS = [
    {key: "", val: null, caption: "nevybrána"},
    {key: "ml", val: "ml", caption: "mililitry"},
    {key: "l", val: "l", caption: "litry"},
    {key: "g", val: "g", caption: "gramy"},
    {key: "kus", val: "ks", caption: "kusy"},
    {key: "bez množství", val:"bez množství", caption: "bez množství"},
    {key: "čajová lžíce", val: "čajová lžíce", caption: "čajová lžíce"},
    {key: "polévková lžíce", val: "polévková lžíce", caption: "polévková lžíce"}
];

export const PSWP_ELEMENT = (
    <div className="pswp" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="pswp__bg"/>
        <div className="pswp__scroll-wrap">
            <div className="pswp__container">
                <div className="pswp__item" />
                <div className="pswp__item" />
                <div className="pswp__item" />
            </div>
            <div className="pswp__ui pswp__ui--hidden">
                <div className="pswp__top-bar">
                    <div className="pswp__counter" />
                    <button className="pswp__button pswp__button--close" title="Zavřít (Esc)"/>
                    <button className="pswp__button pswp__button--fs" title="Celá obrazovka"/>
                    <button className="pswp__button pswp__button--zoom" title="Přiblížit/Oddálit"/>
                    <div className="pswp__preloader">
                        <div className="pswp__preloader__icn">
                            <div className="pswp__preloader__cut">
                                <div className="pswp__preloader__donut" />
                            </div>
                        </div>
                    </div>
                </div>
                <button className="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
                </button>
                <button className="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
                </button>
                <div className="pswp__caption">
                    <div className="pswp__caption__center" />
                </div>
            </div>
        </div>
    </div>
);