import React, {useContext, useEffect, useState} from "react";
import DataGrid from "./DataGrid";
import {notice, PAYMENT_TRANSLATE} from "../../../props/constants";
import AppContext from "../../../props/appContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faReceipt} from "@fortawesome/free-solid-svg-icons";

export default function PaymentsGrid({userId}) {
    const app = useContext(AppContext);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [payments, setPayments] = useState({data:[]});

    const getHistory = () => {
        const apiRequest = app.createApiRequest(
            "GET",
            userId
                ? `/admin/user/${userId}/payments/${page}`
                : `/profile/billing-info/payments/${page}`
        );
        apiRequest.setLoading(setLoading);
        apiRequest.run().then(response => {
            if (response.ok) {
                response.json().then(setPayments);
            } else
                app.messages.flashMessage("failed", notice(7));
        });
    };

    const getInvoice = ({item}) => {
        const apiRequest = app.createApiRequest(
            "GET",
            userId
                ? `/admin/user/invoice/${item.id}`
                : `/profile/personal-info/invoice/${item.id}`
        );

        apiRequest.setLoading(setLoading);

        apiRequest.run().then(response => response.blob()).then(blob => {
            const file = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = file;
            link.download = `Faktura-${item.invoice_number}.pdf`;
            link.dispatchEvent(new MouseEvent('click'));
        });
    };

    useEffect(()=>{
        getHistory();
    }, [page]);

    return (
        <DataGrid
            header={[
                {type: "datetime", id: "created_at", caption: "Vytvořeno"},
                {type: "datetime", id: "modified_at", caption: "Poslední změna"},
                {type: "translate", id: "state", caption: "Stav", translate: (state)=>PAYMENT_TRANSLATE[state]},
                {type: "float", id: "price", caption: "Částka", append: "Kč"},
                {type: "text", id: "invoice_number", caption: "Číslo faktury"},
                {type: "buttons", components: [
                    {
                        onClick: getInvoice,
                        caption: "faktura",
                        frontIcon: <FontAwesomeIcon icon={faReceipt} />,
                        showCondition: (item)=>item.isInvoice
                    }
                ]}
            ]}
            responsive
            showHeader
            loading={loading}

            pagination={{
                page: payments.page,
                lastPage: payments.lastPage,
                setPage: setPage
            }}

            data={payments.data}
        />
    );
}