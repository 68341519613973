import React from "react";

import styles from "./MenuCardItem.module.scss"

export default function MenuCardItem({children, active, onClick}) {
    return (
        <div className={styles.item+(active ? " "+styles.active : "")} onClick={onClick}>
            {children}
        </div>
    )
}