import React, {useContext, useState} from "react";
import FormWrap from "./FormWrap";
import TextInput from "./fields/TextInput";
import Button from "../parts/content/Button";
import AppContext from "../props/appContext";
import InputWrap from "./InputWrap";
import styles from "./ChangeCaloriesForm.module.scss";
import SelectBox from "./fields/SelectBox";
import {notice} from "../props/constants";
import {convertValues, getMacrosFromObject} from "../props/helpers";

export const ACTIVITIES = [
    {key: "low", val: "low", caption: "žádná sportovní aktivita / sedavé zaměstnání"},
    {key: "light", val: "light", caption: "1-2x týdně sportovní aktivita / mírná fyzická, pracovní aktivita"},
    {key: "moderate", val: "moderate", caption: "3-4x týdně sportovní aktivita / zvýšená pracovní aktivita"},
    {key: "vigorous", val: "vigorous", caption: "5-7x týdně pracovní aktivita / vysoká fyzická pracovní aktivita"}
];

export const METHODS = [
    {key: "loss", val: "loss", caption: "hubnout"},
    {key: "gain", val: "gain", caption: "nabírat"},
    {key: "maintain", val: "maintain", caption: "udržovat váhu"}
];

export default function ChangeCaloriesForm({showDone}) {
    const app = useContext(AppContext);

    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const submit = (values, clear) => {
        setError(undefined);

        const apiRequest = app.createApiRequest(
            "POST",
            "/premium/calories"
        );

        apiRequest.setLoading(setLoading);
        apiRequest.setBody(convertValues(values));

        apiRequest.run().then(response => {
            if (response.ok) {
                response.json().then(data => {
                    app.changeCalories(getMacrosFromObject(data));
                    showDone();
                });
            } else
                app.messages.flashMessage("failed", notice(7));
        });
    };

    return (
        <FormWrap submit={submit} error={error} setError={setError}>
            <div className={styles.info}>
                Pro výpočet potřebujeme znát některé údaje o vás:
            </div>
            <InputWrap className={styles.inputsWrap} row>
                <TextInput id="weight" caption="Váha [kg]" validate="number,personWeight" inputClassName={styles.input} required />
                <TextInput id="height" caption="Výška [cm]" validate="integer,personHeight" inputClassName={styles.input} required />
                <TextInput id="year_of_birth" caption="Rok narození" validate="integer,year" inputClassName={styles.input} required />
            </InputWrap>
            <div className={styles.info}>
                Jak moc jste aktivní?
            </div>
            <SelectBox id="activity" withoutCaption options={ACTIVITIES} value="low" />
            <div className={styles.info}>
                Co je Vaším cílem?
            </div>
            <SelectBox id="method" withoutCaption options={METHODS} value="loss" />
            <Button loading={loading}>vypočítat</Button>
        </FormWrap>
    );
}