import React from "react";
import Modal from "./Modal";

export default function TermsAndConditionsModal({show, close}) {
    return (
        <Modal
            show={show}
            close={close}
            heading={"Obchodní podmínky"}
        >
            <h3>1. Kontaktní údaje poskytovatele</h3>
            <p>
                1.1. Tyto všeobecné obchodní podmínky (dále jen „Podmínky“) platí pro prodej členství prodávajícího,
                kterým je Bc. Michaela Malínková s adresou Postoloprtská 382, Cítoliby 439 02, IČO 07019670 (dále jen "Prodávající").
            </p>
            <p>
                1.2. Všeobecné obchodní podmínky upravují v souladu s ustanovením § 1751 odst. 1 zákona č. 89/2012 Sb.,
                občanský zákoník (dále jen „občanský zákoník“) vzájemná práva a povinnosti smluvních stran vzniklé v
                souvislosti nebo na základě kupní smlouvy (dále jen „kupní smlouva“) uzavírané mezi prodávajícím a jinou
                fyzickou nebo právnickou osobou (dále jen „kupující“) prostřednictvím internetových stránek www.fitmaliny.cz. (dále jen „webová stránka“).
            </p>
            <p>
                1.3. Znění obchodních podmínek může poskytovatel měnit či doplňovat. Tímto ustanovením nejsou dotčena práva a povinnosti vzniklá po dobu účinnosti předchozího znění obchodních podmínek.
            </p>
            <p>
                1.5. Prodávané členství jsou informačním produktem. Jakékoliv šíření nebo poskytování obsahu či jeho částí třetím osobám je zakázáno.
            </p>
            <h3>2. Informace o členství</h3>
            <p>
                2.1. Členská sekce pro webové stránky je nabízena ve třech časových termínech: měsíční členství za 79,- Kč, půlroční členství za 399,- Kč a roční členství za 699,- Kč. Kupující si při objednávce volí délku členství. Měsíční členství je na 31 dní, půlroční členství na 186 dní a roční členství na 372 dní.
            </p>
            <p>
                2.2. Prodává se členství, které má přístup do VIP zóny, kde jsou recepty, články, poradna a další zajímavý obsah. Kupující si platí členství a tím dostává přístup k této VIP zóně. Obsah je stále majetkem prodávajícího a členství umožňuje pouze shlédnutí obsahu. Je zakázáno šířit obsah třetím osobám, protože se tím porušuje autorský zákon.
            </p>
            <p>
                2.3. Platba probíhá přes platební bránu GoPay.
            </p>
            <p>
                2.4. Kupující bere na vědomí, že členská sekce nemusí být dostupná nepřetržitě, a to zejména s ohledem na nutnou údržbu hardwarového a softwarového vybavení prodávajícího, popř. nutnou údržbu hardwarového a softwarového vybavení třetích osob.
            </p>
            <h3>3. Doručení členství, zrušení objednávky a odstoupení od smlouvy</h3>
            <p>
                3.1. Po registraci vzniká kupujícímu přístup do aplikace, kde může spravovat svůj účet (osobní údaje, fakturační údaje, členství). Po zaplacení dané částky je umožněn vstup do VIP zóny.
            </p>
            <h4>3.2. Zrušení objednávky a odstoupení od smlouvy</h4>
            <p>
                3.3. Vzhledem tomu, že prodávající poskytuje pouze členství k webové stránce, po přihlášení kupujícího člena nelze požadovat vrácení částky. Jakýkoli nesouhlas s poskytovaným obsahem webové stránky nelze uplatňovat.
            </p>
            <p>
                3.4.  Členství se prodlužuje automaticky, vždy dva dny před skončením stávajícího členství (aby nevzniklo riziko zániku členství z důvodu opožděné autorizace následné platby), pokud kupující nemá zájem o prodloužení členství, je nutné odstoupit od smlouvy dříve než dva dny před skončením členství.
            </p>
            <p>
                3.5. Členství je možné zrušit po přihlášení v aplikaci ve správě profilu (platby a fakturace).
            </p>
            <p>
                3.6. Členství se prodlužuje automaticky po dobu maximálně 5 let. Poté je nutné znovu vytvořit platbu.
            </p>
            <p>
                3.7. Kupující má také právo na odstranění svého uživatelského účtu, je nutné se obrátit na kontaktní e-mailovou adresu uvedenou na webových stránkách, aby došlo k výmazu uživatelského účtu včetně veškerých osobních dat.
            </p>
            <h3>4. Vyřízení reklamace</h3>
            <p>
                4.1. Reklamace se vztahuje na neumožnění přihlášení při aktivaci členství, prodávající se zavazuje, nahradit novým přihlášením nebo řešením této situace tak, aby byl umožněn plný členský přístup k VIP zóně.
            </p>
            <p>
                4.2. Prodávající nenese odpovědnost za chybné vyplnění údajů kupujícího při registraci a platební transakci a reklamace se na toto nevztahuje.
            </p>
            <h3>5. Zabezpečení a ochrana autorský práv</h3>
            <p>
                5.1. Přístupové údaje  jsou určeny pouze pro osobní potřebu kupujícího. Přístup k uživatelskému účtu je zabezpečen e-mailem a heslem. Hesla jsou ukládána jednosměrnou šifrou (prodávající nemůže zjistit heslo kupujícího). Kupující je povinen zachováva mlčenlivost ohledně informací nezbytných k přístupu do jeho uživatelského účtu ve VIP zóně. Prodávající nenese žádnou odpovědnost za zneužití uživatelského jména a hesla třetí osobou.
            </p>
            <p>
                5.2. Veškeré informace na uživatelském účtě kupujícího jsou pouze pro osobní potřebu a prodávající je povinen údaje dále nešířit a nezneužívat. E-mailová adresa kupujícího nebude nabízena třetím stranám, bude sloužit pouze k přístupu do aplikace a k upozorněním na události ohledně plateb a správy členství.
            </p>
            <p>
                5.3. S údaji kupujícího a jeho platební karty GoPay se nakládá podle mezinárodního bezpečnostního standardu PCI-DSS Level 1 (jedná se o nejvyšší úroveň datové bezpečnosti ve finančním sektoru).
            </p>
            <h3>6. Ochrana osobních údajů</h3>
            <p>
                6.1. Účel zpracování, rozsah osobních údajů a právní základ pro zpracování. Kupující bere na vědomí, že pro účel uzavření kupní smlouvy, její následné plnění (zpracování objednávky) a případného řešení práv z vadného plnění (reklamací) správce zpracuje a uchová v souladu s nařízením Evropského parlamentu a Rady (EU) 2016/679, obecné nařízení o ochraně osobních údajů (dále jen GDPR), jeho osobní údaje v následujícím rozsahu: jméno, příjmení, adresa, e-mail a telefonní číslo a platební údaje. Právním základem pro zpracování osobních údajů je v souladu s článkem 6 odst. 1 písm. b) GDPR plnění smlouvy, jejíž smluvní stranou je kupující.
            </p>
            <h3>7.Řešení sporů</h3>
            <p>
                7.1. Vzájemné spory mezi prodávajícím a kupujícím řeší obecné soudy. Kupující, který je spotřebitelem, má podle zákona o ochraně spotřebitele (č. 634/1992 Sb.) právo na mimosoudní řešení spotřebitelského sporu z kupní smlouvy nebo ze smlouvy o poskytování služeb. Subjektem, který je oprávněn mimosoudní řešení sporu provádět, je Česká obchodní inspekce. Bližší informace jsou dostupné na webových stránkách www.coi.cz.
            </p>
            <h3>8. Závěrečná ustanovení</h3>
            <p>
                8.1. Uživatel členství (kupující) byl seznámen s tím, že všechny informace poskytované v rámci VIP zóny, jsou určeny výhradně ke studijním účelům a slouží jako všeobecná doporučení bez znalosti konkrétní situace jednotlivého uživatele. Poskytovatel (prodávající) neodpovídá za způsob, jakým uživatelé (kupující) členské sekce jeho rady aplikují v praxi, a proto nepřebírá odpovědnost ani za konkrétní rozhodnutí jednotlivých uživatelů a jejich případné následky. Rozhodnutí o způsobu využití je odpovědností každého uživatele (kupujícího, který je oprávněn členskou sekci využívat) a jedině on sám nese za svá rozhodnutí plnou odpovědnost. Ustanovení § 2950 zákona č. 89/2012 Sb., občanský zákoník, proto na právní vztahy Poskytovatele (prodávajícího) a Uživatele (kupujícího) nedopadá.
            </p>
            <p>
                8.2. Tyto Podmínky a vztah mezi Poskytovatelem (prodávajícím) a Uživatelem (kupujícím) se řídí právním řádem České republiky.
            </p>
            <p>
                8.3. Jakákoliv komunikace mezi Poskytovatelem (prodávajícím) a Uživatelem (kupujícím) probíhá elektronicky formou elektronické pošty (e-mailu) na adresách uvedených na webových stránkách.
            </p>
            <p>
                8.4. Smlouva je uzavírána v českém jazyce, archivovaná v elektronické podobě a není přístupná třetím osobám.
            </p>
            <p>
                8.5. Smlouva je uzavřena až v okamžiku, kdy je objednávka kupujícího zaplacena přes platební bránu.
            </p>
            <p>
                8.6. V případě, že je některé ustanovení těchto Podmínek neplatné, neúčinné nebo nepoužitelné (nebo se takovým stane), použije se namísto něj ustanovení, které se svým smyslem nejvíce blíží neplatnému, neúčinnému nebo nepoužitelnému ustanovení. Neplatností, neúčinností nebo nepoužitelností jednoho ustanovení není dotčena platnost ostatních ustanovení. Měnit či doplňovat smlouvu (včetně obchodních podmínek) lze pouze písemnou formou.
            </p>
            <p>
                8.7. Vezměte, prosím, na vědomí, že Prodávající je oprávněn tyto Podmínky jednostranně měnit, pro Kupujícího však vždy platí text Podmínek účinný v okamžiku odeslání objednávky.
            </p>
            <p>
                8.8. Tyto Podmínky nabývají účinnosti dne 20.02.2021
            </p>
        </Modal>
    );
}