import React, {useContext, useState} from "react";
import FormWrap from "./FormWrap";
import TextInput from "./fields/TextInput";
import Button from "../parts/content/Button";
import {notice} from "../props/constants";
import AppContext from "../props/appContext";
import {convertValues, getTimeString} from "../props/helpers";
import InputWrap from "./InputWrap";
import SelectBox from "./fields/SelectBox";
import {ACTIVITIES, METHODS} from "./ChangeCaloriesForm";
import DateTimeInput from "../parts/content/DateTimeInput";

export default function AddCaloriesForm({yearOfBirth, id, refresh}) {
    const app = useContext(AppContext);

    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const [recorded, setRecorded] = useState(new Date());

    const submit = (values, clear) => {
        setError(undefined);

        const apiRequest = app.createApiRequest(
            "POST",
            `/admin/calories/${id}`
        );
        apiRequest.setLoading(setLoading);
        apiRequest.setBody({
            ...convertValues(values),
            recorded_at: getTimeString(recorded)
        });
        apiRequest.run().then(response => {
            if (response.ok) {
                setError({type: "success", msg: notice(1007)});
                clear();
                refresh();
            } else
                setError(notice(7));
        });
    };

    const inputStyle = {
        width: "50px",
        flexGrow: 1
    };

    return (
        <FormWrap submit={submit} error={error} setError={setError}>
            <InputWrap row>
                <TextInput id="weight" caption="Váha [kg]" validate="number,personWeight" clearAfterSubmit style={inputStyle} required />
                <TextInput id="height" caption="Výška [cm]" validate="integer,personHeight" clearAfterSubmit style={inputStyle} required />
                <TextInput id="year_of_birth" caption="Rok narození" validate="integer,year" style={inputStyle} value={yearOfBirth} required />
            </InputWrap>
                <TextInput id="calories" caption="Kalorie [kcal]" clearAfterSubmit validate="integer" required />
            <InputWrap row>
                <TextInput id="proteins_l" caption="Proteiny spodní hranice" clearAfterSubmit validate="integer" style={inputStyle} required />
                <TextInput id="proteins_u" caption="Proteiny horní hranice" clearAfterSubmit validate="integer" style={inputStyle} required />
            </InputWrap>
            <InputWrap row>
                <TextInput id="fats_l" caption="Tuky spodní hranice" clearAfterSubmit validate="integer" style={inputStyle} required />
                <TextInput id="fats_u" caption="Tuky horní hranice" clearAfterSubmit validate="integer" style={inputStyle} required />
            </InputWrap>
            <InputWrap row>
                <TextInput id="carbohydrates_l" caption="Sacharidy spodní hranice" clearAfterSubmit validate="integer" style={inputStyle} required />
                <TextInput id="carbohydrates_u" caption="Sacharidy horní hranice" clearAfterSubmit validate="integer" style={inputStyle} required />
            </InputWrap>
            <SelectBox id="activity" options={ACTIVITIES} value="low" caption="Aktivita" />
            <SelectBox id="method" options={METHODS} value="loss" caption="Cíl" />
            <DateTimeInput caption="Zaznamenáno"
                           onChange={setRecorded}
                           required omit />
            <Button loading={loading}>přidat</Button>
        </FormWrap>
    );
}