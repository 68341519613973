import React, {forwardRef} from "react";
import BaseInput from "./BaseInput";

export default forwardRef((props, ref) => {
    const {maxLength, value} = props;
    return (
        <BaseInput ref={ref} {...props}>
            <textarea
                value={value}
                maxLength={maxLength ? maxLength : 2000}
            />
        </BaseInput>
    );
});