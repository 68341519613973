import React, {useEffect, useState} from "react";

import styles from "./CrossHeader.module.scss";

export default function CrossHeader({className, crossClick, heading, animate}) {

    const [wait, setWait] = useState(true);

    useEffect(()=>{
        if (animate===true)
            setTimeout(()=>setWait(false), 1000);
    }, [animate]);

    return (
        <div className={styles.crossHeader+(className ? " "+className : "")}>
            <div className={styles.heading}>{heading}</div>
            <div onClick={crossClick} className={styles.cross+(wait ? " "+styles.wait : "")}>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}