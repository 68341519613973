import {apiAddress, maxUploadSize, notice} from "./props/constants";

export default class ApiRequest {

    constructor(appMethods, method, uri) {
        this._method = method;
        this._uri = uri;
        this._appMethods = appMethods;

        this._automatic401 = true;

        this._body = undefined;
        this._loading = undefined;
        this._controller = undefined;
        this._progressCallback = undefined;
        this._isPartUpload = false;
    }

    disableAutomatic401() {
        this._automatic401 = false;
    }

    setBody(body) {
        this._body = JSON.stringify(body);
    }

    setLoading(callback) {
        this._loading = callback;
    }

    setController(controller) {
        this._controller = controller;
    }

    setProgress(progressCallback) {
        this._progressCallback = progressCallback;
    }

    _error(error) {
        if (error.name==="AbortError") return;
        if (this._loading) this._loading(false);
        console.error(error);
        this._appMethods.addFlashMessage("failed", notice(7));
    }

    run() {
        return new Promise((resolve) => {
            if (this._loading) this._loading(true);

            let headers = {};

            const csrfToken = localStorage.getItem('csrfToken');
            if (csrfToken) headers['csrf-token'] = csrfToken;

            if (this._body) headers['Content-Type'] = "application/json";

            fetch(apiAddress+this._uri, {
                method: this._method,
                credentials: 'include',
                body: this._body ? this._body : undefined,
                signal: this._controller ? this._controller.signal : undefined,
                headers: headers
            })
                .then(response => {
                    if (this._loading) this._loading(false);
                    if (this._automatic401 && this._appMethods.loginModal && response.status===401) this._appMethods.loginModal();

                    resolve(response);
                })
                .catch((e)=>this._error(e));
        });
    }

    upload(files, params, prevSize, allPartsSize) {
        return new Promise(resolve => {
            if (this._loading) this._loading(true);

            let formData = new FormData();
            let totalSize = 0;
            for (let i=0; i<files.length; i++) {
                formData.append("file" + i, files[i]);
                if (params.length!==0)
                    formData.append("param"+i, JSON.stringify(params[i]));
                totalSize+=files[i].size;
            }

            const xhr = new XMLHttpRequest();
            xhr.open(this._method, apiAddress+this._uri);

            xhr.withCredentials = true;
            const csrfToken = localStorage.getItem('csrfToken');
            if (csrfToken) xhr.setRequestHeader("csrf-token", csrfToken);

            xhr.upload.onprogress = (e) => {
                if (this._progressCallback && e.lengthComputable) {
                    if (!this._isPartUpload)
                        this._progressCallback(e.loaded/totalSize);
                    else
                        this._progressCallback((e.loaded+prevSize)/allPartsSize)
                }
            };

            xhr.addEventListener("error", (e)=>this._error(e));
            xhr.addEventListener("load", e => {
                if (this._loading) this._loading(false);
                if (this._automatic401 && this._appMethods.loginModal && xhr.status===401) this._appMethods.loginModal();

                resolve(xhr);
            });

            xhr.send(formData);
        });
    }

    partsUpload(files, params) {
        this._isPartUpload = true;
        return new Promise(async (resolve) => {
            let tmpFiles = [];
            let tmpParams = [];
            let partSize = 0;
            let prevSize = 0;
            let resolves = [];
            let totalSize = 0;

            for(let file of files) totalSize += file.size;

            let paramsIndex = 0;
            for(let file of files) {
                if ((file.size+partSize)>maxUploadSize) {
                    let value = await this.upload([...tmpFiles], [...tmpParams],prevSize, totalSize);
                    resolves.push(value);
                    prevSize += partSize;
                    partSize=0;
                    tmpFiles = [];
                    tmpParams = [];
                }

                tmpFiles.push(file);
                if (params)
                    tmpParams.push(params[paramsIndex]);
                partSize += file.size;
                paramsIndex++;
            }

            let value = await this.upload(tmpFiles, tmpParams, prevSize, totalSize);
            resolves.push(value);
            resolve(resolves);
        });
    }

}