import React, {useContext, useState} from "react";
import AppContext from "../props/appContext";
import {notice} from "../props/constants";
import FormWrap from "./FormWrap";
import TextInput from "./fields/TextInput";
import Button from "../parts/content/Button";
import update from "immutability-helper";
import {convertValues} from "../props/helpers";

export default function ChangeUniqueCredential({type}) {
    const app = useContext(AppContext);

    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const submit = (values) => {
        setError(undefined);

        const apiRequest = app.createApiRequest(
            "PUT",
            `/profile/personal-info/change-${type}`
        );
        apiRequest.setLoading(setLoading);
        apiRequest.setBody(convertValues(values));
        apiRequest.run().then((response)=>{
            if (response.ok) {
                app.changeCredentials(update(app.user, {
                    [type]: {$set:values[type].value}
                }));
                setError({type: "success", msg: notice(type==="username" ? 1000 : 1001)});
            } else if (response.status===409)
                setError(notice(type==="username" ? 2 : 4));
        });
    };

    return (
        <FormWrap submit={submit} error={error} setError={setError}>
            <TextInput id={type} caption={type==="username" ? "Uživatelské jméno" : "E-mail"}
                       validate={type+(type==="email" ? ",uniqueEmail" : "")} value={app.user[type]} required />
            <Button loading={loading}>změnit</Button>
        </FormWrap>
    );
}