import React from "react";
import NarrowLayout from "../../layout/NarrowLayout";
import CrossHeader from "../../parts/header/CrossHeader";
import {useHistory} from "react-router-dom";
import ForgotPassEmailForm from "../../forms/ForgotPassEmailForm";

export default function EmailPage() {
    const history = useHistory();

    return (
        <NarrowLayout>
            <CrossHeader animate={true} heading={"Zapomenuté heslo"} crossClick={()=>history.goBack()} />
            <p>
                Níže napište Vaši e-mailovou adresu, kterou používáte k přihlášení se do aplikace. Obratem Vám
                přijde e-mail s odkazem na nastavení nového hesla.
            </p>
            <p style={{fontWeight: "bold"}}>
                Možnost nastavit si nové heslo bude platná 3 hodiny.
            </p>
            <ForgotPassEmailForm />
        </NarrowLayout>
    );
}