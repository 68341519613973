import React, {useContext, useState} from "react";
import {dateToCzech} from "../../props/helpers";
import Button from "../../parts/content/Button";
import AppContext from "../../props/appContext";

import styles from "./PersonalInfoBlock.module.scss";
import TableWrap from "../../parts/content/table/TableWrap";
import TableRow from "../../parts/content/table/TableRow";
import TableCell from "../../parts/content/table/TableCell";
import Modal from "../../parts/modal/Modal";
import ChangeUniqueCredential from "../../forms/ChangeUniqueCredential";
import ChangePassForm from "../../forms/ChangePassForm";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWeight} from "@fortawesome/free-solid-svg-icons";
import MeasurementModal from "../../parts/modal/MeasurementModal";

export default function () {
    const app = useContext(AppContext);
    const [modal, setModal] = useState(false);

    const [measurementModal, setMeasurementModal] = useState(false);

    const closeModal = () => setModal(false);

    return (
        <React.Fragment>
            <Modal show={modal==="username"}
                   close={closeModal}
                   heading={"Změna uživatelského jména"}
            >
                <ChangeUniqueCredential type="username" />
            </Modal>
            <Modal show={modal==="email"}
                   close={closeModal}
                   heading={"Změna e-mailu"}
            >
                <ChangeUniqueCredential type="email" />
            </Modal>
            <Modal show={modal==="pass"}
                   close={closeModal}
                   heading={"Změna hesla"}
            >
                <ChangePassForm />
            </Modal>
            {
                app.premium.isActive && app.calories.total &&
                    <React.Fragment>
                        <div className={styles.weightButtonWrap}>
                            <Button onClick={()=>setMeasurementModal(true)} frontIcon={<FontAwesomeIcon icon={faWeight} />}>
                                Vážení
                            </Button>
                        </div>
                        <MeasurementModal show={measurementModal} close={()=>setMeasurementModal(false)} />
                    </React.Fragment>
            }
            <TableWrap className={styles.table} rowStyles={styles.row} cellStyles={styles.cell}>
                {
                    !app.user.isAdmin &&
                    <TableRow>
                        <TableCell>Konec plánu</TableCell>
                        <TableCell>
                        <span className={app.premium.isActive ? "" : styles.attention}>
                            {" "+dateToCzech(app.premium.date)}
                        </span>
                        </TableCell>
                        <TableCell />
                    </TableRow>
                }
                {
                    app.premium.isActive &&
                    <TableRow>
                        <TableCell>Denní příjem</TableCell>
                        <TableCell>{app.calories.total ? <React.Fragment>{app.calories.total}&nbsp;kcal</React.Fragment> : <i>neurčeno</i>}</TableCell>
                        <TableCell><Button onClick={()=>app.openCaloriesModal()} small>změnit</Button></TableCell>
                    </TableRow>
                }
                {
                    app.calories.proteins &&
                        <TableRow>
                            <TableCell />
                            <TableCell>{app.calories.proteins[0]}&nbsp;–&nbsp;{app.calories.proteins[1]}&nbsp;g bílkovin</TableCell>
                            <TableCell />
                        </TableRow>
                }
                {
                    app.calories.fats &&
                    <TableRow>
                        <TableCell />
                        <TableCell>{app.calories.fats[0]}&nbsp;–&nbsp;{app.calories.fats[1]}&nbsp;g tuků</TableCell>
                        <TableCell />
                    </TableRow>
                }
                {
                    app.calories.carbohydrates &&
                    <TableRow>
                        <TableCell />
                        <TableCell>{app.calories.carbohydrates[0]}&nbsp;–&nbsp;{app.calories.carbohydrates[1]}&nbsp;g sacharidů</TableCell>
                        <TableCell />
                    </TableRow>
                }
                <TableRow>
                    <TableCell>Uživatelské jméno</TableCell>
                    <TableCell>{app.user.username}</TableCell>
                    <TableCell><Button onClick={()=>setModal("username")} small>změnit</Button></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>E-mail</TableCell>
                    <TableCell>{app.user.email}</TableCell>
                    <TableCell><Button onClick={()=>setModal("email")} small>změnit</Button></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Heslo</TableCell>
                    <TableCell><strong>&bull; &bull; &bull; &bull; &bull; &bull; &bull;</strong></TableCell>
                    <TableCell><Button onClick={()=>setModal("pass")} small>změnit</Button></TableCell>
                </TableRow>
            </TableWrap>
        </React.Fragment>
    );
}