import React, {forwardRef} from "react";
import BaseInput from "./BaseInput";

export default forwardRef((props, ref) => {
    const {accept, multiple} = props;

    return (
        <BaseInput ref={ref} {...props}>
            <input type="file"
                   accept={accept}
                   multiple={multiple}
            />
        </BaseInput>
    );
});