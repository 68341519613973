import React from "react";

import styles from "./Table.module.scss";

export default function TableWrap({className, rowStyles, cellStyles, children}) {
    return (
        <div className={styles.table+(className ? " "+className : "")}>
            {
                React.Children.map(
                    children,
                    (child)=>child!=null ? React.cloneElement(child, {
                        className: rowStyles,
                        cellStyles: cellStyles,
                        ...child.props
                    }) : null
                )
            }
        </div>
    );
}