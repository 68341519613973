import React from "react";
import styles from "./ImageGallery.module.scss";
import Image from "./Image";
import {getImageSrc, openPhotos} from "../../props/helpers";

function translateForPswp(images) {
    return images.map((image)=>({
        src: getImageSrc(`/premium/article/image/id/${image.id}`),
        msrc: getImageSrc(`/premium/article/image/id/${image.fitId}`),
        w: image.dimension.width,
        h: image.dimension.height,
        title: image.description ? image.description : undefined
    }));
}

export default function ImageGallery({images}) {
    let imagesEl = [];
    const pswpsImages = translateForPswp(images);

    if (images.length===1)
        return (
            <div className={styles.oneImage} onClick={()=>openPhotos(pswpsImages, 0, imagesEl)}>
                <Image
                    iri={"/premium/article/image/id/"}
                    id={images[0].fitId}
                    alt={images[0].description}
                    useClassicLoad
                    wrapClassName={styles.imageWrap}
                    setRef={(ref)=>imagesEl.push(ref)}
                />
                {
                    images[0].description!=="" &&
                <div className={styles.oneImageDescription}>
                    {images[0].description}
                </div>
                }
            </div>
        );

    return (
        <div className={styles.photoGallery}>
            <div className={styles.imagesWrap}>
                {
                    images.map((image, i) => {
                        if (i<3)
                            return (<div className={styles.image} key={image.fitId}
                                         onClick={() => openPhotos(pswpsImages, i, imagesEl)}>
                                <Image
                                    iri={"/premium/article/image/id/"}
                                    id={image.fitId}
                                    alt={image.description}
                                    useClassicLoad
                                    wrapClassName={styles.imageWrap}
                                    setRef={(ref) => imagesEl.push(ref)}
                                />
                            </div>);
                        return null;
                    })
                }
            </div>
            <div className={styles.footer} onClick={()=>openPhotos(pswpsImages, images.length>3 ? 3 : images.length-1, imagesEl)}>
                <div>Fotogalerie &bull; {images.length} fotek</div>
                <div>zobrazit</div>
            </div>
        </div>
    );
}