import React from "react";

import styles from "./Modal.module.scss";
import {Spring, Transition, animated} from "react-spring";
import {config} from "react-spring";

export default class Modal extends React.Component {

    constructor(props) {
        super(props);

        this.ref = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.show!==this.props.show) {
            document.body.classList.toggle('noscroll', this.props.show);
        }
    }

    click = (e) => {
        if (e.target===this.ref.current)
            if (this.props.close) this.props.close();
    };

    render() {
        return (
            <Transition
                native
                items={this.props.show}
                from={{opacity: 0}}
                enter={{opacity: 1}}
                leave={{opacity: 0}}
            >
                {(props, show) => show &&

                        <animated.div ref={this.ref}
                                      className={styles.pack+(this.props.outerClassName ? " "+this.props.outerClassName : "")}
                                      style={props} onClick={this.click}>
                            <Transition native items={show}
                                        from={{ transform: 'translate3d(0,-40px,0)' }}
                                        enter={{ transform: 'translate3d(0,0px,0)' }}
                                        leave={{ transform: 'translate3d(0,-40px,0)' }}>
                                {(props, show) => show &&
                                        <animated.div className={styles.modal} style={props}>
                                            <div className={styles.header}>
                                                <div className={styles.heading}>{this.props.heading}</div>
                                                {
                                                    this.props.close &&
                                                    <div className={styles.closeBtn}
                                                         onClick={() => this.props.close()}>&times;</div>
                                                }
                                            </div>
                                            <Spring
                                                native
                                                from={{width: "0%"}} to={{width: "30%"}}
                                                delay={500}
                                                config={config.molasses}
                                            >
                                                {props =>
                                                    <animated.div className={styles.line} style={props} />
                                                }
                                            </Spring>
                                            <div className={styles.content}>{this.props.children}</div>
                                        </animated.div>
                                }
                            </Transition>
                        </animated.div>
                }
            </Transition>
        );
    }
}