import React from "react";

import styles from "./Service.module.scss";

export default function Service({children, heading}) {
    return (
        <div className={styles.service}>
            <div className={styles.heading}>{heading}</div>
            {children.map((child, i)=>(
                <div key={i} className={styles.item} dangerouslySetInnerHTML={{__html: child}} />
            ))}
        </div>
    );
}