import React from "react";
import Header from "../../parts/header/Header";
import HalfLayout from "../../layout/HalfLayout";

import styles from "./VIPPage.module.scss";
import Heading from "../../parts/content/Heading";
import NumberedList from "../../parts/content/NumberedList";
import NumberedListItem from "../../parts/content/NumberedListItem";

import {ReactComponent as Line} from "../../files/images/registration-line.svg";
import DefaultLayout from "../../layout/DefaultLayout";
import Copyright from "../../layout/Copyright";
import LoginForm from "../../forms/LoginForm";
import PlanChoosing from "./parts/PlanChoosing";
import {useHistory, useRouteMatch} from "react-router-dom";

export default function() {

    const history = useHistory();
    const match = useRouteMatch();

    return (
        <React.Fragment>
            <Header narrow />
            <HalfLayout className={styles.regSign}>
                <div>
                    <div className={styles.innerRegSign+" "+styles.innerRegSignNoBorder}>
                        <div>
                            <Heading withoutTopMargin>Registrace</Heading>
                            <p>Staň se také malinou a získej:</p>
                            <NumberedList>
                                <NumberedListItem>Nový pohled na stravování.</NumberedListItem>
                                <NumberedListItem>Spoustu receptů.</NumberedListItem>
                                <NumberedListItem>Možnost sestavit si vlastní jídelníček na míru.</NumberedListItem>
                                <NumberedListItem>Tipy, rady, triky.</NumberedListItem>
                                <NumberedListItem>Online poradnu.</NumberedListItem>
                                <NumberedListItem>Tréninkové plány.</NumberedListItem>
                            </NumberedList>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.innerRegSign}>
                        <div>
                            <Heading withoutTopMargin anchor={"prihlaseni"}>Přihlášení</Heading>
                            <p>Máte již účet?</p>
                            <LoginForm />
                        </div>
                    </div>
                </div>
            </HalfLayout>
            <div className={styles.linePack}>
                <Line className={styles.line} />
            </div>
            <DefaultLayout className={styles.registrationBlock}>
                <Heading withoutTopMargin anchor={"registrace"}>Vyberte plán</Heading>
                <PlanChoosing choosen={()=>history.push(match.url+"/registrace/")} />
            </DefaultLayout>
            <Copyright className={styles.copyright} />
        </React.Fragment>
    );
}