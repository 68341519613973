import React, {useContext, useState} from "react";

import styles from "./PartsRegistration.module.scss";
import PlanChoosing from "../../VIPmain/parts/PlanChoosing";
import BillingInfoForm from "../../../forms/BillingInfoForm";
import PersonalInfoForm from "../../../forms/PersonalInfoForm";
import {animated, useSpring} from "react-spring";
import AppContext from "../../../props/appContext";
import {notice} from "../../../props/constants";
import {useScrollToAnchor} from "../../../props/helpers";
import WaitForRedirect from "../../payments/WaitForRedirect";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faSadTear} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../parts/content/Button";
import {useHistory} from "react-router-dom";

export default function PartsRegistration({partNumber, setPartNumber}) {
    const app = useContext(AppContext);
    const [animate, setAnimate] = useState({show: true, nextPart: partNumber});
    const history = useHistory();

    const scroll = useScrollToAnchor(()=>{});

    const [gwURL, setGwURL] = useState("");

    const props = useSpring({
        to: async (next, cancel) => {
            if (animate.show) {
                await next({opacity: 1});
            } else {
                await next({opacity: 0});
                setPartNumber(animate.nextPart);
                setAnimate({show: true, nextPart: animate.nextPart});
            }
        }
    });

    const backFunc = () => {
        setAnimate({show: false, nextPart: 1});
        app.addFlashMessage("failed", notice(9));
    };

    const nextPart = (next) => {
        setAnimate({show: false, nextPart: next});
        scroll("kroky");
    };

    const data = [
        {
            number: 1,
            heading: "Výběr plánu",
            content: (
                <PlanChoosing choosen={()=>nextPart(2)} />
            )
        },
        {
            number: 2,
            heading: "Fakturační informace",
            content: (
                <BillingInfoForm success={()=>nextPart(3)} back={backFunc} />
            )
        },
        {
            number: 3,
            heading: "Osobní údaje",
            content: (
                <PersonalInfoForm success={(url)=>{nextPart(4); setGwURL(url)}} back={backFunc} />
            )
        },
        {
            number: 4,
            heading: "Platba",
            content: gwURL!=="valid-voucher" && gwURL!=="invalid-voucher"
                ? (
                    <WaitForRedirect gwURL={gwURL}
                                     text={`Registrace proběhla úspěšně, od této chvíle se můžete přihlásit. 
                                     Za moment budete přesměrován/a na platební bránu. Nedojde-li k automatickému přesměrování, 
                                     klikněte na tlačítko níže.`} />
                )
                : (
                    <div className={styles.voucherBlock}>
                        <div className={styles.iconWrap}>
                            {gwURL==="invalid-voucher" && <FontAwesomeIcon icon={faSadTear} />}
                            {gwURL==="valid-voucher" && <FontAwesomeIcon icon={faCheckCircle} />}
                        </div>
                        {
                            gwURL==="invalid-voucher" &&
                                <p>
                                    Bohužel, než jste stihli vyplnit potřebné údaje, tak voucher, který jste na začátku vyplnili
                                    už není validní (buď Vám ho někdo vyfoukl, nebo mu vypršela platnost). I&nbsp;přes to proběhla
                                    registrace v&nbsp;pořádku. Pro uplatnění jiného voucheru, nebo výběr plánu se přihlaste do aplikace
                                    a&nbsp;pokračujte na sekci profilu, kartu platby a fakturace. Moc nás to mrzí.
                                </p>
                        }
                        {
                            gwURL==="valid-voucher" &&
                                <p>
                                    Registrace proběhla v&nbsp;pořádku a&nbsp;voucher byl uplatněn. Nyní se můžete přihlásit.
                                </p>
                        }
                        <p>
                            <Button onClick={()=>history.push("/vip-zona")} small>Přejít na V.I.P. sekci</Button>
                        </p>
                    </div>
                )
        }
    ];

    let currPart = undefined;
    return (
        <React.Fragment>
            <div className={styles.numbersWrap} id="kroky">
            {
                data.map((part)=> {
                        if (part.number === partNumber) currPart = part;

                        return (
                            <div
                                key={part.number}
                                onClick={!currPart ? ()=>setPartNumber(part.number) : undefined}
                                className={styles.numberItem+(part.number === partNumber ? " " + styles.active : "")+(!currPart ? " "+styles.done : "")}
                            >
                                <div>
                                    <div className={styles.number}>{part.number}.</div>
                                    <div className={styles.heading}>{part.heading}</div>
                                </div>
                            </div>
                        );
                    }
                )
            }
            </div>
            <animated.div className={styles.content} style={props}>
                {currPart.content}
            </animated.div>
        </React.Fragment>
    )
}