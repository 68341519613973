import React from "react";
import {ReactComponent as LoadingClassic} from "../../files/images/loading.svg";
import {ReactComponent as LoadingWhite} from "../../files/images/loading-white.svg";

import styles from "./Loading.module.scss";
import {useSpring, animated} from "react-spring";

export default function Loading({show, children, white, wrapClassName}) {

    const loadingProps = useSpring({
        opacity: show ? 1 : 0,
        maxHeight: show? 100 : 0
    });

    const childrenProps = useSpring({
        opacity: show ? 0 : 1
    });

    return (
        <React.Fragment>
            <animated.div className={styles.wrap+(wrapClassName ? ` ${wrapClassName}` : "")} style={loadingProps}>
                {white ? <LoadingWhite /> : <LoadingClassic />}
            </animated.div>
            <animated.div style={childrenProps} className={wrapClassName}>
                {children}
            </animated.div>
        </React.Fragment>
    );
}