import React, {useContext, useState} from "react";
import TextInput from "./fields/TextInput";
import AppContext from "../props/appContext";

import Button from "../parts/content/Button";
import FormWrap from "./FormWrap";
import {convertValues} from "../props/helpers";
import {notice} from "../props/constants";

export default function AddIngredientForm({refresh}) {
    const app = useContext(AppContext);

    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const submit = (values, clear) => {
        setError(undefined);
        const apiRequest = app.createApiRequest(
            "POST",
            "/admin/article/ingredient"
        );

        apiRequest.setLoading(setLoading);
        apiRequest.setBody(convertValues(values));

        apiRequest.run().then(response=>{
            if (response.ok) {
                setError({type: "success", msg: notice(1006)});
                refresh();
                clear();
            } else 
                setError({type: "failed", msg: notice(7)});
        })
    };

    return (
        <FormWrap submit={submit} error={error} setError={setError}>
            <TextInput id="name" caption="Název" required clearAfterSubmit />
            <Button loading={loading}>přidat</Button>
        </FormWrap>
    );
}