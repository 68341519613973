import React from 'react';

import Header from "../parts/header/Header";

import main1 from "../files/images/misa.jpeg";
import graphic from "../files/images/graphic.png";
import TitleSubtitle from "../parts/content/TitleSubtitle";

import styles from "./MainPage.module.scss";
import DiagonalLayout from "../layout/DiagonalLayout";
import DefaultLayout from "../layout/DefaultLayout";
import Heading from "../parts/content/Heading";
import Button from "../parts/content/Button";
import Reviews from "../parts/reviews/Reviews";
import Review from "../parts/reviews/Review";

import {ReactComponent as OnlyRaspberry} from "../files/images/logo-only-raspberry.svg";
import {ReactComponent as LogoWhite} from "../files/images/logo-white.svg";
import {ReactComponent as Facebook} from "../files/images/facebook.svg";

import BeRaspberryLayout from "../layout/BeRaspberryLayout";
import MainMenu from "../parts/header/MainMenu";
import Copyright from "../layout/Copyright";

import {headerHeightTop} from "../props/constants";
import Services from "../parts/services/Services";
import Service from "../parts/services/Service";
import Measure from "react-measure";
import ScrollAnimatedLayout from "../layout/ScrollAnimatedLayout";
import Modal from "../parts/modal/Modal";
import {Spring, animated} from "react-spring";
import BlankHref from "../parts/content/BlankHref";

import adela from "../files/images/adela.png";
import petra1 from "../files/images/petra1.png";
import dagmar from "../files/images/dagmar.png";
import petra2 from "../files/images/petra2.png";
import InstagramBlock from "../parts/content/InstagramBlock";

let serviceAnchorRef = null;

export default class MainPage extends React.Component {

    state = {
        aboutUsModal: false,
        serviceAnimation: false,
        servicesDimension: {width: -1, height: -1}
    };

    render() {
        const {servicesDimension, aboutUsModal, serviceAnimation} = this.state;

        return (
            <React.Fragment>
                <Header mainMenu />
                <DiagonalLayout image={main1} className={styles.introBlock} style={{paddingTop: headerHeightTop}}>
                    <TitleSubtitle className={styles.introText} title={<React.Fragment>S náma líná nebudeš!</React.Fragment>} subtitle={"www.fitmaliny.cz"} />
                </DiagonalLayout>
                <DefaultLayout className={styles.aboutUsBlock}>
                    <Heading anchor={"o-nas"} withoutTopMargin>O nás</Heading>
                    <p>
                        Dlouho jsme přemýšlely, jak zkusit změnit život těm, kteří chtějí zhubnout pár kilogramů, chtějí být zdravější,
                        v kondici, nabrat svaly, ale děsí je přísné diety, utracené peníze za hubnoucí čaje, hodiny při běhu a nechtějí
                        úplně měnit své dosavadní životy.
                    </p>
                    <p>
                        Název Maliny vznikl z našeho příjmení – Malínků a většina známých náš právě přezdívá maliny. Maliny si jsou
                        podobné a to my také. Jsme dvojčata s velkými sny a cíly. Jsme ying a yang, proto se skvěle doplňujeme a rádi
                        bychom společnými silami předaly i něco Vám.
                    </p>
                    <Button onClick={()=>this.setState({aboutUsModal: true})}>číst dále</Button>
                    <div id={"sluzby"} style={{position: "absolute", bottom: 0, left: 0}} ref={(r) => serviceAnchorRef = r} />
                </DefaultLayout>
                <Modal show={aboutUsModal}
                       close={()=>this.setState({aboutUsModal: false})}
                       heading={"O nás"}
                >
                    <h3>Malina Míša o sobě říká</h3>
                    <p>
                        Celá má sportovní kariéra začala během, ke kterému jsem postupně začala zařazovat domácí kruhové
                        tréninky až jsem poprvé navštívila posilovnu, kde mě činky a posilování natolik oslovilo, že se
                        fitness stalo nejen mým největším koníčkem a zálibou, ale rovněž jsem začala v tomto odvětví podnikat.
                        Nyní působím jako výživová poradkyně a fitness trenérka a také jako instruktorka kruhových tréninků.
                        Fitness je můj lifestyle, kolem kterého se točí celý můj život. Mým cílem je ukázat lidem správnou
                        cestu zdravého životního stylu, podat ruku při prvních krocích a určit správný směr. A jaké je mé
                        životní heslo? „Není čas ztrácet čas“! Proto neváhej, staň se malinou a pojď změnit svůj životní styl.
                    </p>
                    <h3>Malina Káťa o sobě říká</h3>
                    <p>
                        A jak už je to u dvojčat zvykem, jedna je yin a druhý yang. Já jsem vždy byla to zlo s kouskem dobra.
                        O fitness jsem se začala zajímat až později. Silový trénink jsem si zamilovala ihned, ale stravu jsem
                        v té době neřešila. Zlom nastal po nabrání nechtěných kilogramů. Ze dne na den jsem začala zapisovat
                        přijaté kalorie a zajímat se také o zdravé stravování. Začátky byly těžké, všude spousta informací,
                        spousta různých diet, u kterých jsem vydržela pouze pár týdnů a mé znalosti byly nulové. Proto bych
                        také Vám všem ulehčila práci a předala vše, co teď už vím a ušetřila Vám tak čas se střídáním různých
                        diet a striktních jídelníčků. Jde především o změnu životního stylu a rovnováhu. Není to věda.
                        A to vše bych Vám rada společně s mou sestrou předala.
                    </p>
                    <h3>Co s námi získáte?</h3>
                    <p>
                        Jednoduché recepty, tipy a triky, cvičební plány, informace ohledně stravy a jídla, zábavu, možnost
                        se naučit si sestavit svůj jídelníček a také možná změnu života k lepšímu.
                    </p>
                    <p>
                        Díky dostatku pohybu a zdravému jídlu nezískáte pouze vysněnou postavu, ale také zdravější tělo,
                        odolnější imunitu, více energie, lepší trávení a lepší náladu.
                    </p>
                    <p>
                        Spojme tedy síly a staňme se všichni malinami.
                    </p>
                    <div className={styles.ourGraphic}>
                        <img src={graphic} alt="Naše grafika" />
                    </div>
                </Modal>
                <ScrollAnimatedLayout func={()=>this.setState({serviceAnimation: true})} anchor={serviceAnchorRef}>
                    <Measure
                        bounds
                        onResize={contentRect => this.setState({servicesDimension: contentRect.bounds})}
                    >
                        {({ measureRef }) => (
                            <Spring native
                                to={{
                                    marginTop: servicesDimension.height!==-1 ? (serviceAnimation ? 0 : -servicesDimension.height) : 0,
                                    marginBottom: servicesDimension.height!==-1 ? (serviceAnimation ? 0 : servicesDimension.height) : 0
                                }}
                            >
                                {props =>
                                    <animated.div style={props}>
                                        <div ref={measureRef}>
                                            <DefaultLayout className={styles.servicesBlock}>
                                                <Heading withoutTopMargin centerLine>Služby</Heading>
                                                <Services>
                                                    <Service heading={"Stravovací plány"}>
                                                        {
                                                            [
                                                                "měsíční stravovací plány",
                                                                "tříměsíční stravovací plány",
                                                                "jednorázový jídelníček"
                                                            ]
                                                        }
                                                    </Service>
                                                    <Service heading={"Tréninkové služby"}>
                                                        {
                                                            [
                                                                "individuální trénink ve&nbsp;fitness&nbsp;centru",
                                                                "trénink ve fitness centru pro&nbsp;dva&nbsp;a&nbsp;více",
                                                                "osobní trénink „doma“",
                                                                "kruhové tréninky",
                                                                "tréninkové plány"
                                                            ]
                                                        }
                                                    </Service>
                                                    <Service heading={"Ostatní služby"}>
                                                        {
                                                            [
                                                                "osobní konzultace",
                                                                "přednášky o zdravé&nbsp;výživě",
                                                                "online poradenství"
                                                            ]
                                                        }
                                                    </Service>
                                                </Services>
                                            </DefaultLayout>
                                        </div>
                                    </animated.div>
                                }
                            </Spring>
                        )}
                    </Measure>
                </ScrollAnimatedLayout>
                <DefaultLayout className={styles.reviewBlock}>
                    <Heading withoutTopMargin anchor={"recenze"}>Recenze</Heading>
                    <Reviews>
                        <Review name="Dagmar" image={petra1}>
                            Míša je skvělá trenérka, ale taky úžasný člověk. Má individuální přístup ke klientům a jídelníčky dělané přímo na míru.
                            Jsem ráda, že je má trenérka a můžu snad i říct, že také kamarádka. Pokud něko ještě váhá, Míšu můžu jen a jen doporučit.
                            Jděte do toho!
                        </Review>
                        <Review name="Petra" image={adela}>
                            Slečnu Míšu můžu jen a jen doporučit, měla jsem možnost poznat i osobně a je sympatická, příjemná, vstřícná,
                            naslouchá potřebám klienta a je i skvělá psychická podpora při hubnutí a skvěle motivuje. Jídla od Míši jsou chutné a rychlé na přípravu.
                        </Review>
                        <Review name="Adéla" image={dagmar}>
                            Nechala jsem si vypracovat jídelníček od Míši a mohla jsem si říct, co mám ráda a co naopak ne.
                            Hned první týden byly vidět výsledky a jídla chutná a jednoduchá na přípravu. Končím druhý měsíc a mám dole 7kg.
                            Míšu můžu jen doporučit! Nejen jako výživovou poradkyni, ale i jako trenérku.
                        </Review>
                        <Review name="Petra" image={petra2}>
                            Tak zítra mám pátý den a já už mám na břiše – 1cm a na stehnech – 2cm. Zatím držím jen jídelníček
                            a ještě necvičím, ale myslím si, že jsi úžasná čarodějka co se týče jídelníčku na míru. Vážit
                            se budu až za týden, ale výsledky už vidím teď. Jsem strašně ráda za ty tvoje jídelníčky!
                        </Review>
                    </Reviews>
                </DefaultLayout>
                <BeRaspberryLayout
                    backgroundImage={<OnlyRaspberry />}
                >
                    <Heading withoutTopMargin inline centerLine anchor={"kontakt"}>Staň se malinou!</Heading>
                    <p>
                        Získáš přístup do V.I.P zóny, kde najdeš spoustu článků,<br />
                        tréninkových plánů a dalších výhod.
                    </p>
                    <Button link={"/vip-zona"}>registruj se do V.I.P. zóny</Button>
                    <div className={"line "+styles.line} />
                    <h2 className={styles.contactName}>Michaela Malínková</h2>
                    <div className={styles.contactDesc}>instruktorka fitness a výživová poradkyně</div>
                    <p>
                        Telefon: <a href="tel:+420792324532">+420 792 324 532</a>
                    </p>
                    <p>
                        Email: <a href="mailto:info@fitmaliny.cz">info@fitmaliny.cz</a>
                    </p>
                    <div className={styles.socialWrap}>
                        <BlankHref href="https://www.facebook.com/michalka.malinku"><Facebook className={styles.facebookIco} />Facebook</BlankHref>
                    </div>
                </BeRaspberryLayout>
                <DefaultLayout>
                    <Heading withoutTopMargin>Instagram</Heading>
                    <InstagramBlock />
                </DefaultLayout>
                <DefaultLayout className={styles.vipBlock}>
                    <div>V.I.P. zóna</div>
                    <Button link={"/vip-zona"}>vstoupit</Button>
                </DefaultLayout>
                <DefaultLayout className={styles.bottomMenu} innerClassName={styles.bottomMenuInner}>
                    <LogoWhite />
                    <MainMenu withoutBurger />
                </DefaultLayout>
                <Copyright />
            </React.Fragment>
        );
    }
}