import React, {useContext, useEffect, useRef, useState} from "react";
import Loading from "../../parts/content/Loading";

import styles from "./UnpublishedBlock.module.scss";
import AppContext from "../../props/appContext";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {useHistory, useParams} from "react-router-dom";

export default function UnpublishedBlock({id}) {
    const app = useContext(AppContext);
    const params = useParams();
    const history = useHistory();

    const [items, setItems] = useState([]);

    useEffect(()=>{
        let unmounted = false;

        const apiRequest = app.createApiRequest(
            "GET",
            "/admin/article/unpublished"
        );

        apiRequest.run().then((response) => {
            if (response.ok)
                response.json().then((data) => {
                    if (!unmounted) {
                        setItems(data);
                    }
                });
        });

        return ()=>{
            unmounted = true;
        }
    }, [params.id, app, id]);

    const tmpItems = items.filter((item)=>item.id!==Number(params.id) && item.id!==Number(id));
    return (
        <div>
            {tmpItems.length!==0 && <h2>Nepublikované články</h2>}
            <div className={styles.wrap}>
                {
                    tmpItems.map((item)=>
                        <div key={item.id} className={styles.item} onClick={()=>history.push(`/vip-zona/clanky/upravit/${item.id}`)}>
                            <FontAwesomeIcon icon={faEdit} /> {item.name}
                        </div>
                    )
                }
            </div>
        </div>
    )
}