import React, {useContext, useEffect, useState} from "react";
import Modal from "./Modal";

import styles from "./CropImageModal.module.scss";
import ReactCrop from "react-image-crop";
import 'react-image-crop/lib/ReactCrop.scss';
import Button from "../content/Button";
import {blobToSHA1} from "file-to-sha1";
import Loading from "../content/Loading";
import AppContext from "../../props/appContext";
import {readDataURL} from "../../props/helpers";
import Image from "../content/Image";


export default function CropImageModal({show, setShow, heading, aspect, onChange, file, hasId}) {
    const app = useContext(AppContext);
    const [crop, setCrop] = useState({unit: '%', aspect: aspect});
    const [loading, setLoading] = useState(true);
    const [src, setSrc] = useState();
    const [miniature, setMiniature] = useState();
    const [btnLoading, setBtnLoading] = useState(false);

    const clear = () => {
        setSrc(undefined);
        setMiniature(undefined);
        setLoading(true);
        setCrop({unit: '%', aspect: aspect});
        setBtnLoading(false);
    };

    const loadImageForCrop = () => {
        readDataURL(file, (data)=>{
            setSrc(data);
            setLoading(false);
        });
    };

    useEffect(()=>{
        const checkIfExists = async () => {
            if (file) {
                clear();
                const hash = await blobToSHA1(file);

                const apiRequest = app.createApiRequest(
                    "GET",
                    `/premium/article/miniature-id/sha1/${hash}`
                );

                apiRequest.run().then(async response => {
                    if (response.ok) {
                        response.json().then(data=>{
                            setMiniature(data);
                            setLoading(false);
                        });
                    } else if (response.status === 404)
                        loadImageForCrop();
                });
            }
        };
        checkIfExists();
    }, [file]);

    return (
        <Modal
            heading={heading}
            show={show}
            close={()=>setShow(false)}
        >
            <Loading show={loading}>
                {
                    miniature &&
                        <React.Fragment>
                            <p>
                                Tento obrázek už existuje v databázi a má miniaturu vytvořenou (viz níže). Pokud
                                si přejete tuto miniaturu zachovat, klikněte na zachovat. Chcete-li miniaturu
                                změnit (změní se i u předchozích článků), klikněte na změnit.
                            </p>
                            <Image
                                alt={"miniatura"}
                                iri={"/premium/article/image/id/"}
                                id={miniature.miniId}
                                wrapClassName={styles.image}
                                offCache={true}
                            />
                            <div className={styles.footer}>
                                <Button onClick={()=>hasId(miniature.id)}>Zachovat</Button>
                                <Button onClick={()=>{
                                    clear();
                                    loadImageForCrop();
                                }}>Změnit</Button>
                            </div>
                        </React.Fragment>
                }
                {
                    src &&
                        <React.Fragment>
                            <ReactCrop
                                className={styles.crop}
                                src={src}
                                crop={crop}
                                onChange={(crop,percentCrop)=>setCrop(percentCrop)}
                            />
                            <div className={styles.footer}>
                                <Button loading={btnLoading} onClick={()=>{
                                    setBtnLoading(true);
                                    onChange(crop);
                                }}>Použít</Button>
                            </div>
                        </React.Fragment>
                }
            </Loading>
        </Modal>
    )
}