import React from "react";

import styles from "./NarrowLayout.module.scss";

export default function NarrowLayout({children}) {
    return (
        <section className={styles.outerSection}>
            <section className={styles.innerSection}>
                {children}
            </section>
        </section>
    )
}