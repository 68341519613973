import React, {useContext, useEffect, useState} from "react";
import NarrowLayout from "../../layout/NarrowLayout";
import CrossHeader from "../../parts/header/CrossHeader";
import Loading from "../../parts/content/Loading";
import AppContext from "../../props/appContext";
import {useHistory, useParams} from "react-router-dom";
import {notice} from "../../props/constants";
import ChangeUserInfosForm from "../../forms/ChangeUserInfosForm";
import DateInput from "../../parts/content/DateInput";

import styles from "./UserDetailPage.module.scss";
import Button from "../../parts/content/Button";
import {dateToCzech} from "../../props/helpers";
import BillingInfoForm from "../../forms/BillingInfoForm";
import CaloriesMacrosDataGrid from "./CaloriesMacrosDataGrid";
import PaymentsGrid from "../../parts/content/table/PaymentsGrid";

export default function UserDetailPage() {
    const app = useContext(AppContext);
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const history = useHistory();
    const [data, setData] = useState({});

    const [date, setDate] = useState();

    const load = () => {
        if (params.id) {
            const apiRequest = app.createApiRequest(
                "GET",
                `/admin/user/${params.id}`
            );

            apiRequest.setLoading(setLoading);

            apiRequest.run().then(response=>{
                if (response.ok) {
                    response.json().then(setData);
                } else
                    app.messages.flashMessage("failed", notice(7));
            });
        }
    };

    useEffect(()=>{
        load();
    }, []);

    const changePlan = () => {
        const apiRequest = app.createApiRequest(
            "PUT",
            `/admin/user/${params.id}/end-of-premium`
        );

        apiRequest.setLoading(setLoading);
        apiRequest.setBody({
            endOfPremium: date
        })

        apiRequest.run().then(response => {
            if (response.ok) {
                app.messages.flashMessage("success", notice(1006));
                load();
            } else
                app.messages.flashMessage("failed", notice(7));
        });
    };

    return (
        <Loading show={loading}>
            <NarrowLayout>
                <CrossHeader animate={true} heading={"Správa uživatele"} crossClick={()=>history.push(`/vip-zona/profil`)} />
                <h3 className={styles.heading}>Obecné údaje</h3>
                <ChangeUserInfosForm username={data.username} email={data.email} id={params.id} />
                <h3 className={styles.heading}>Aktuální fakturační údaje</h3>
                <BillingInfoForm change data={data.billing_info} userId={params.id} />
                <h3 className={styles.heading}>Plán</h3>
                <DateInput selected={data.end_of_premium ? new Date(data.end_of_premium) : undefined} onChange={setDate} />
                <p>
                    <strong>Poslední zaplacený plán:</strong> {data.plan ? data.plan.name : "žádný"} do {dateToCzech(new Date(data.end_of_premium))}
                </p>
                <Button onClick={()=>changePlan()}>Změnit</Button>
                <h3 className={styles.heading}>Platby</h3>
                <PaymentsGrid userId={params.id} />
                <h3 className={styles.heading}>Kalorie & makroživiny</h3>
                <p>Vždy se bere poslední zaznamenaný údaj, jako ten hlavní.</p>
                <CaloriesMacrosDataGrid id={params.id} yearOfBirth={data.year_of_birth} />
            </NarrowLayout>
        </Loading>
    );
}