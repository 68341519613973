import React, {useContext, useRef} from "react";
import QuillTextarea from "../../parts/content/QuillTextarea";

import styles from "./ArticleCreationBlock.module.scss";
import ChooseVideo from "../../parts/content/ChooseVideo";
import UploadPhotoGallery from "../../parts/content/UploadPhotoGallery";
import SelectBox from "../../forms/fields/SelectBox";
import AppContext from "../../props/appContext";
import {notice} from "../../props/constants";

export default function ArticleCreationBlock({type, remove, callback, value, orders, order, setOrder, changedValue}) {
    const app = useContext(AppContext);

    let content = null;

    switch (type) {
        case "text":
            content = <QuillTextarea onChange={callback} value={value} />;
            break;

        case "youtube_video":
            content = <ChooseVideo onChange={callback} value={value} />;
            break;

        case "images":
            content = <UploadPhotoGallery onChange={callback} value={value} />;
            break;

        default:
            return null;
    }

    const removeBlock = () => {
        const conf = window.confirm(notice(10));
        if (conf) {
            if (type==="images") {
                const body = changedValue.map((image)=>image.id);
                const apiRequest = app.createApiRequest(
                    "DELETE",
                    "/admin/article/content-images"
                );
                apiRequest.setBody(body);
                apiRequest.run().then(response => {
                   if (response.ok) {
                       remove();
                   } else
                       app.messages.flashMessage("failed", notice(11));
                });
            } else remove();
        }
    };

    return (
        <div className={styles.wrap}>
            <div className={styles.header}>
                <div className={styles.cross} onClick={()=>removeBlock()}>
                    <div />
                    <div />
                </div>
                <SelectBox withoutCaption withoutMargin id="order"
                           options={orders}
                           valueCallback={setOrder}
                           value={order}
                />
            </div>
            {content}
        </div>
    );
}