import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import React from "react";

import styles from "./Pagination.module.scss";

export default function Pagination({page, lastPage, setPage}) {
    const changePage = (diff) => {
        const newPage = page+diff;
        if (newPage<=lastPage && newPage>0)
            setPage(page+diff);
    };

    if (lastPage!==1 && lastPage!==0)
        return (
            <div className={styles.pagination}>
                <div>
                    <div className={styles.arrow+(page===1 ? ` ${styles.disabled}` : "")}
                         onClick={()=>setPage(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></div>
                    <div className={styles.arrow+(page===1 ? ` ${styles.disabled}` : "")}
                         onClick={()=>changePage(-1)}><FontAwesomeIcon icon={faAngleLeft} /></div>
                    <div>{page} z {lastPage}</div>
                    <div className={styles.arrow+(page===lastPage ? ` ${styles.disabled}` : "")}
                         onClick={()=>changePage(+1)}><FontAwesomeIcon icon={faAngleRight} /></div>
                    <div className={styles.arrow+(page===lastPage ? ` ${styles.disabled}` : "")}
                         onClick={()=>setPage(lastPage)}><FontAwesomeIcon icon={faAngleDoubleRight} /></div>
                </div>
            </div>
        );
    return null;
}