import React, {useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { useLocation } from "react-router-dom";
import MainPage from "./page/MainPage";

import {ReactComponent as Loading} from "./files/images/loading.svg";

import "./App.scss";

import {useSpring, animated} from "react-spring";

import { AppProvider } from "./props/appContext";
import ApiRequest from "./ApiRequest";
import VIPRouter from "./router/VIPRouter";
import {notice} from "./props/constants";
import update from 'immutability-helper';
import Modal from "./parts/modal/Modal";
import LoginForm from "./forms/LoginForm";
import EmailPage from "./page/forgotPass/EmailPage";
import ChangePassPage from "./page/forgotPass/ChangePassPage";
import ChangeCaloriesModal from "./parts/modal/ChangeCaloriesModal";
import {getMacrosFromObject} from "./props/helpers";
import AfterPaymentPage from "./page/payments/AfterPaymentPage";
import TermsAndConditionsModal from "./parts/modal/TermsAndConditionsModal";
import RecurrenceConditionsModal from "./parts/modal/RecurrenceConditionsModal";

const logoutUser = {
  isLoggedIn: false,
  premium: {},
  user: {
    isAdmin: false
  }
};

let flashMessageId = 0;

const loggedUser = (data) => {
  const date = new Date(data.endOfPremium);

  let calories = {};
  if (data.calories) {
    calories = getMacrosFromObject(data.calories);
  }

  return {
    isLoggedIn: true,
    premium: {
      date: date,
      isActive: data.isPremium
    },
    user: {
      email: data.email,
      username: data.username,
      isAdmin: data.isAdmin
    },
    calories: calories
  }
};

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [user, setUser] = useState(logoutUser);
  const [loading, setLoading] = useState(true);
  const [flashMessages, setFlashMessages] = useState({});
  const [loginModal, setLoginModal] = useState(false);
  const [caloriesModal, setCaloriesModal] = useState(false);
  const [termsModal, setTermsModal] = useState(false);
  const [recurrenceModal, setRecurrenceModal] = useState(false);

  const loadingProps = useSpring(loading ? {opacity: 1, top: 0} : {opacity: 0, top: -window.outerHeight});

  const createApiRequest = (...params) => {
    return new ApiRequest({
      loginModal: ()=>setLoginModal(true),
      addFlashMessage: (type, msg)=>addFlashMessage(type, msg)
    }, ...params);
  };

  useEffect(() => {
    const apiRequest = createApiRequest(
        "GET",
        "/auth"
    );
    apiRequest.disableAutomatic401();
    apiRequest.setLoading((l)=>setLoading(l));
    apiRequest.run()
        .then((response)=>{
          if (response.ok) response.json().then((data)=>setUser(loggedUser(data)));
        });
  }, []);


  const changeCalories = (calories) => {
      setUser((oldInfo)=>({...oldInfo, calories: calories}));
  };


  const addFlashMessage = (type, msg) => {
    const id = flashMessageId++;
    setFlashMessages(update(flashMessages, {$merge: {
      [id]: {type: type, msg: msg, id: id}
    }}));
    setTimeout(
        ()=>setFlashMessages((current)=>update(current, {$unset: [id]})),
        10*1000);
  };

  const changeCredentials = (credentials) => {
    setUser((user)=>update(user, {user: {$set: credentials}}));
  };

  const login = (userInfo)=>{
    localStorage.setItem("csrfToken", userInfo.csrfToken);
    setUser(loggedUser(userInfo));
  };

  const logout = ()=>{
    const apiRequest = new ApiRequest({},"GET", "/auth/logout");
    apiRequest.run();

    localStorage.removeItem("csrfToken");
    setUser(logoutUser);
    addFlashMessage("info", notice(3));
  };

  return (
    <AppProvider value={
        update(user, {$merge: {
            login: login,
            logout: logout,
            changeCredentials: changeCredentials,
            createApiRequest: createApiRequest,
            openCaloriesModal: ()=>setCaloriesModal(true),
            changeCalories: changeCalories,
            openTermsAndConditions: ()=>setTermsModal(true),
            openRecurrenceConditions: ()=>setRecurrenceModal(true),
            messages: {
              flashMessage: addFlashMessage
            }
          }})
      }>
      <animated.div className="fullPageLoading" style={loadingProps}>
        <Loading/>
      </animated.div>
      <Router>
        <ChangeCaloriesModal show={caloriesModal} close={()=>setCaloriesModal(false)} />
        <TermsAndConditionsModal show={termsModal} close={()=>setTermsModal(false)}/>
        <RecurrenceConditionsModal show={recurrenceModal} close={()=>setRecurrenceModal(false)} />
        <Modal show={loginModal}
               close={false}
               heading={"Přihlášení"}
               outerClassName="loginModal"
        >
          <p>
            Jejda! Zdá se, že jste byl/a buď odhlášen/a ze serveru, nebo nemáte přístup do této sekce.
            Abyste nepřišel/a o to, co právě děláte, nabízíme Vám možnost se znovu přihlásit
            a pokračovat. <span className="link" onClick={()=>{logout(); setLoginModal(false);}}>Přejít do sekce pro odhlášené.</span>
          </p>
          <LoginForm closeModal={()=>setLoginModal(false)} />
        </Modal>
        <ScrollToTop />
          <Switch>
            <Route path="/platba">
              <AfterPaymentPage />
            </Route>
            <Route path="/zapomenute-heslo">
              <EmailPage />
            </Route>
            <Route path="/zmena-hesla">
              <ChangePassPage />
            </Route>
            <Route path="/vip-zona">
              <VIPRouter />
            </Route>
            <Route path="/">
              <MainPage />
            </Route>
          </Switch>
          {
            Object.values(flashMessages).length!==0 &&
                <div className="flashMessageWrap">
                  {
                    Object.values(flashMessages).map((
                        value =>
                            <div key={value.id} className={`flashMessage ${value.type}`}>
                              {value.msg}
                            </div>
                    ))
                  }
                </div>
          }
      </Router>
    </AppProvider>
  );
}

export default App;
