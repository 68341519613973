import React, {useContext, useState} from "react";
import FormWrap from "./FormWrap";
import TextInput from "./fields/TextInput";
import Button from "../parts/content/Button";
import {notice} from "../props/constants";
import AppContext from "../props/appContext";
import {convertValues} from "../props/helpers";
import {Link} from "react-router-dom";

import styles from "./LoginForm.module.scss";

export default function LoginForm({closeModal}) {
    const app = useContext(AppContext);

    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const submit = (values, clear) => {
        setError(undefined);
        const apiRequest = app.createApiRequest(
            "POST",
            "/auth/login"
        );
        apiRequest.disableAutomatic401();
        apiRequest.setLoading((l)=>setLoading(l));
        apiRequest.setBody(convertValues(values));
        apiRequest.run().then((response)=>{
            if (response.ok) {
                response.json().then(data=>{
                    clear();
                    app.login(data);
                    if (closeModal) closeModal();
                    if (!data.calories && data.isPremium) app.openCaloriesModal();
                });
            } else {
                clear();
                if (response.status===401)
                    setError(notice(0));
            }
        });
    };

    return (
        <FormWrap submit={submit} error={error} setError={setError}>
            <TextInput id="email" caption="E-mail" validate="email" type="email" required />
            <TextInput id="pass" caption="Heslo" type="password" clearAfterSubmit required />
            <div className={styles.forgotPassBlock}>
                <Link to={"/zapomenute-heslo"}>Zapomenuté heslo</Link>
            </div>
            <Button loading={loading}>přihlásit</Button>
        </FormWrap>
    );
}