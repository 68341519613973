import React, {useContext, useEffect, useRef, useState} from "react";

import styles from "./ArticlesGrid.module.scss";
import AppContext from "../../props/appContext";
import {CATEGORY} from "../../props/constants";
import Loading from "../../parts/content/Loading";
import Button from "../../parts/content/Button";

import {faHeartBroken} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";
import {useCurrentPath, useSearchParams} from "../../props/hooks";
import Image from "../../parts/content/Image";

export default function ArticlesGrid({categories, searchedValue, page}) {
    const app = useContext(AppContext);
    const history = useHistory();
    const searchParams = useSearchParams();
    const path = useCurrentPath();

    const [articles, setArticles] = useState({articles: []});
    const [loading, setLoading] = useState(false);

    const request = (page, categories, searchedValue) => {
        const apiRequest = app.createApiRequest(
            "GET",
            `/premium/article/catalog/${page}?categories=${categories}&search=${searchedValue}`
        );
        apiRequest.setLoading(setLoading);
        apiRequest.run().then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setArticles(old => ({
                        ...data,
                        articles: data.articles
                    }));
                });
            }
        });
    };

    useEffect(()=>{
        request(page, categories, searchedValue);
    }, [page, categories, searchedValue]);

    return (
        <Loading show={loading}>
            <section className={styles.wrap}>
                {
                    page!==1 &&
                    <div className={styles.topPart}>
                        <Button onClick={()=>searchParams("stranka", page-1)}>Načíst mladší</Button>
                    </div>
                }
                {
                    articles.articles.map((article)=> {
                        const category = CATEGORY[article.category];
                        return (<div key={article.id} className={styles.item}
                                     onClick={()=>history.push(`/vip-zona/clanek/${article.id}`, {prevLink: path})}>
                            <div
                                className={styles.image}
                                style={article.mini_image ? undefined : {backgroundImage: `url(${category.minImage})`}}
                            >
                                {
                                    article.mini_image &&
                                        <Image
                                            iri={"/premium/article/image/id/"}
                                            id={article.mini_image}
                                            alt={`Úvodní obrázek – ${article.name}`}
                                            wrapClassName={styles.wrapClassName}
                                            whiteLoading
                                        />
                                }
                            </div>
                            <div className={styles.subtitle}>
                                {category.name}
                            </div>
                            <div className={styles.title}>
                                {article.name}
                            </div>
                        </div>);
                    })
                }
                {
                    page!==articles.lastPage && articles.lastPage!==0 &&
                    <div className={styles.bottomPart}>
                        <Button onClick={()=>searchParams("stranka", page+1)}>Načíst starší</Button>
                    </div>
                }
                {
                    articles.lastPage===0 &&
                        <div className={styles.emptyResult}>
                            <FontAwesomeIcon icon={faHeartBroken} /> Bohužel, takový hledaný článek jsme nenašli...
                        </div>
                }
            </section>
        </Loading>
    );
}