import React from "react";
import TimeField from "react-simple-timefield";

import styles from "./TImeInput.module.scss";

export default function TimeInput({value, onChange, className}) {
    return (
        <div className={styles.wrap+(className ? " "+className : "")}>
            <TimeField
                value={value}
                onChange={(event, value) => onChange(value)}
                style={{flexGrow: 1}}
            />
        </div>
    )
}