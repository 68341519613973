import React, {useContext, useState} from "react";
import FormWrap from "./FormWrap";
import Button from "../parts/content/Button";
import AppContext from "../props/appContext";
import PassCheckerInput from "./fields/PassCheckerInput";
import {convertValues} from "../props/helpers";
import {notice} from "../props/constants";
import {useHistory} from "react-router-dom";

export default function ChangeForgotPassForm({id, token}) {
    const app = useContext(AppContext);
    const history = useHistory();

    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const submit = (values, clear) => {
        setError(undefined);
        values = convertValues(values);
        values["userId"] = id;
        values["token"] = token;

        const apiRequest = app.createApiRequest(
            "POST",
            "/forgot-password/change"
        );

        apiRequest.setBody(values);
        apiRequest.setLoading(setLoading);

        apiRequest.run().then(response => {
           if (response.ok) {
               app.messages.flashMessage("success", notice(5));
               history.replace(`/vip-zona`);
           } else {
               app.messages.flashMessage("failed", notice(12));
               history.replace(`/vip-zona`);
           }
        });
    };

    return (
        <FormWrap submit={submit} error={error} setError={setError}>
            <PassCheckerInput id="pass" clearAfterSubmit required />
            <Button loading={loading}>změnit</Button>
        </FormWrap>
    );
}