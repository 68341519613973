import React, {useRef} from "react";

import styles from "./FormWrap.module.scss";
import Button from "../parts/content/Button";
import Alert from "./fields/Alert";
import {notice} from "../props/constants";
import InputWrap from "./InputWrap";

function cloning(child, setRefs, valueCallback, submitClear, values) {
    if (child===null) return null;
    if (child.props.clearAfterSubmit) submitClear.push(child.props.id);
    if (child.props.omit) return child;

    let nextProps = {};
    if (values[child.props.id])
        nextProps.value = values[child.props.id].value;

    return React.cloneElement(child, {
        ref: node => {
            return !node ? setRefs.delete(child.props.id) : setRefs.set(child.props.id, node);
        },
        valueCallback: (value, invalid) => valueCallback(child.props.id, value, invalid),
        ...nextProps,
        ...child.props
    });
}

export default function FormWrap({children, submit, error, setError, ...props}) {

    let setRefs = useRef(new Map()).current;
    let values = useRef({}).current;
    let submitClear = useRef([]).current;
    submitClear = [];

    let buttons = [];

    const valueCallback = (id, value, invalid)=>{
        values[id] = {value: value, isInvalid: invalid};
    };

    const clear = () => {
        for (let id of submitClear) {
            values[id].value = "";
            setRefs.get(id).clear();
        }
    };

    const submitForm = (e) => {
        e.preventDefault();

        let whichValues = {};
        setRefs.forEach((value, key) => {
            if (values[key]) value.checkValidity(values[key].value)
            else value.checkValidity("");

            whichValues[key] = values[key];
        });

        for (const value of Object.values(whichValues)) {
            if (value.isInvalid) {
                setError(notice(102))
                return;
            }
        }

        submit(values, clear);
    };

    return (
        <form className={styles.form} onSubmit={submitForm} {...props}>
            {React.Children.map(children, child => {
                if (child===null) return null;

                if (child.type==="div") return child;

                if (child.type===Button) {
                    buttons.push(child);
                    return null;
                }

                if (child.type===InputWrap)
                    return (
                        React.cloneElement(child, {
                            ...child.props,
                            children: React.Children.map(child.props.children, childI => cloning(childI, setRefs, valueCallback, submitClear, values))
                        })
                    )

                return React.Children.map(child, child => cloning(child, setRefs, valueCallback, submitClear, values));
            })}
            {
                error &&
                <Alert className={styles.alert}>
                    {error}
                </Alert>
            }
            {buttons}
        </form>
    );
}