import React, {useContext, useState} from "react";
import FormWrap from "./FormWrap";
import TextInput from "./fields/TextInput";
import PassCheckerInput from "./fields/PassCheckerInput";
import Button from "../parts/content/Button";
import AppContext from "../props/appContext";
import {convertValues} from "../props/helpers";
import CheckboxInput from "./fields/CheckboxInput";
import {notice} from "../props/constants";
import InputWrap from "./InputWrap";
import BlankHref from "../parts/content/BlankHref";

import styles from "./PersonalInfoForm.module.scss";

export default function PersonalInfoForm({success, back}) {
    const app = useContext(AppContext);


    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const [termsAgreement, setTermsAgreement] = useState(false);
    const [termsRecurrence, setTermsRecurrence] = useState(false);

    const submit = (values) => {
        if (!termsAgreement) {
            setError(notice(17));
            return;
        }

        if (!termsRecurrence) {
            setError(notice(21));
            return;
        }

        setError(undefined);
        const apiRequest = app.createApiRequest(
            "POST",
            "/registration/personal-data"
        );
        apiRequest.setLoading(setLoading);
        apiRequest.setBody(convertValues(values));
        apiRequest.run().then((response)=>{
            if (response.ok) {
                response.json().then(data=>{
                    if (!data.voucher)
                        success(data.gw_url);
                    else
                        success("valid-voucher")
                });
            } else if (response.status===400) {
                response.json().then((data)=>{
                    if (data.code===0) back();
                    if (data.code===3) success("invalid-voucher");
                });
            } else
                setError(notice(7));
        });
    };

    return (
        <FormWrap submit={submit} error={error} setError={setError}>
            <TextInput id="email" caption="E-mail" type="email" validate="email,uniqueEmail" required />
            <TextInput id="username" caption="Uživatelské jméno" validate="username" required />
            <PassCheckerInput clearAfterSubmit required id="pass" />
            <div className={styles.terms}>
                Při zaplacení inicializační platby se vytvoří opakovaná platba, kdy se automaticky prodlužuje členství
                2 dny před koncem členství. Částka je fixní. Je možné po přihlášení v nastavení profilu změnit plán členství, přičemž
                dojde k úpravě strhávané příští částky (a prodloužení členství) o parametry nového členství. Prodávající
                si klade možnost změnit ceník jednotlivých členství, v takovém případě bude všem uživatelům, kteří by
                tím pádem platili jinou částku, zrušena automatická platba a uživatel si musí po přihlášení vybrat členství jiné
                (o této skutečnosti bude informován e-mailem). Automatické platby je možné zrušit v nastavení profilu po přihlášení,
                nedaří-li se takto zrušit opakování plateb, kontaktujte nás na
                e-mail <BlankHref href="mailto:info@fitmaliny.cz">info@fitmaliny.cz</BlankHref>. Automatické platby jsou automaticky
                nastavené na 5 let od provedení první inicializační platby, poté je potřeba zopakovat inicializační platbu.
            </div>
            <CheckboxInput
                valueCallback={setTermsRecurrence}
                caption={<React.Fragment>Souhlasím s <span onClick={()=>app.openRecurrenceConditions()} className="likeLink">podmínkami opakovaných plateb</span></React.Fragment>}
                value={termsRecurrence}
                omit
            />
            <CheckboxInput
                valueCallback={setTermsAgreement}
                caption={<React.Fragment>Souhlasím s <span onClick={()=>app.openTermsAndConditions()} className="likeLink">obchodními podmínkami</span></React.Fragment>}
                value={termsAgreement}
                omit
            />
            <Button loading={loading}>registrovat a přejít k platbě</Button>
        </FormWrap>
    );
}