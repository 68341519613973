import React, {useContext, useState} from "react";
import FormWrap from "./FormWrap";
import TextInput from "./fields/TextInput";
import Button from "../parts/content/Button";
import AppContext from "../props/appContext";
import {notice} from "../props/constants";
import {convertValues} from "../props/helpers";

export default function ChangeUserInfosForm({username, email, id}) {
    const app = useContext(AppContext);

    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const submit = (values, clear) => {
        setError(undefined);

        const apiRequest = app.createApiRequest(
            "PUT",
            `/admin/user/${id}/credentials`
        );

        apiRequest.setLoading(setLoading);
        apiRequest.setBody(convertValues(values));

        apiRequest.run().then(response => {
            if (response.ok) {
                setError({type: "success", msg: notice(1006)});
            } else if (response.status === 400) {
                response.json().then(data=>{
                   if (data.code===1) {
                       setError(notice(4));
                   } else if (data.code===2) {
                       setError(notice(2));
                   }
                });
            } else
                setError(notice(7));
        });
    };

    return (
        <FormWrap submit={submit} error={error} setError={setError}>
            <TextInput id="username" caption="Uživatelské jméno" value={username} required />
            <TextInput id="email" caption="E-mail" validate="email" type="email" value={email} required />
            <Button loading={loading}>změnit</Button>
        </FormWrap>
    );
}