import React from 'react';
import DefaultLayout from "./DefaultLayout";

import styles from "./DiagonalLayout.module.scss";

export default function DiagonalLayout({image, children, className, ...props}) {
    return (
        <section className={styles.section+(className ? " "+className : "")} {...props}>
            <DefaultLayout>
                {children}
            </DefaultLayout>
            <div className={styles.after} style={{backgroundImage: `url(${image})`}} />
        </section>
    );
}