import React, {useEffect, useState} from "react";

import styles from "./Button.module.scss";
import {useHistory} from "react-router-dom";

import {ReactComponent as Loading} from "../../files/images/loading-white.svg";
import {useSpring, animated} from "react-spring";

import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Button({children,link,onClick, disabled, loading, small, selected, frontIcon}) {

    const [loadingState, setLoading] = useState(!!loading);
    useEffect(()=>{
        setLoading(loading);
    }, [loading]);

    const textProps = useSpring({opacity: loadingState ? 0 : 1});
    const loadingProps = useSpring({opacity: loadingState ? 1 : 0});

    let history = useHistory();
    let click = link ? ()=>history.push(link) : undefined;

    return (
        <button
            onClick={click ? click : onClick}
            className={styles.button+(loading ? ` ${styles.loadingState}` : "")+(small ? ` ${styles.small}` : "")+(selected ? ` ${styles.selected}` : "")}
            disabled={loading ? true : disabled}
        >
            <animated.span className={styles.content} style={textProps}>
                {
                    selected
                        ?
                            <span className={styles.frontIcon}>
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                        : (frontIcon
                            && 
                                <span className={styles.frontIcon}>
                                    {frontIcon}
                                </span>)

                }
                <span className={styles.textContent}>
                    <div>{children}</div>
                </span>
            </animated.span>
            <animated.span className={styles.loading} style={loadingProps}>
                <Loading />
            </animated.span>
        </button>
    );
}