import React from "react";
import Button from "./Button";

import styles from "./LineTextInput.module.scss";

export default function LineTextInput({label, buttonCaption, labelClassName, className, btnClassName, value, onChange, onClick, loading}) {
    return (
        <div className={styles.wrap+(className ? " "+className : "")}>
            <label className={styles.label}>
                <span className={labelClassName}>{label}</span>
                <input
                    type="text"
                    value={value}
                    onChange={onChange}
                />
            </label>
            <div className={styles.button+(btnClassName ? " "+btnClassName : "")}>
                <Button loading={loading} onClick={onClick}>{buttonCaption}</Button>
            </div>
        </div>
    );
}