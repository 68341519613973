import React from "react";

export default function NumberedList({children}) {
    let number = 1;

    return (
        <div>
            {React.Children.map(children, (child)=>{
                return React.cloneElement(child, {number: number++, ...child.props});
            })}
        </div>
    );
}