import React, {useContext, useEffect, useState} from "react";
import AppContext from "../props/appContext";
import FormWrap from "./FormWrap";
import TextInput from "./fields/TextInput";
import Button from "../parts/content/Button";
import {convertValues} from "../props/helpers";
import {notice} from "../props/constants";

export default function ForgotPassEmailForm() {
    const app = useContext(AppContext);

    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const submit = (values, clear) => {
        setError(undefined);
        const apiRequest = app.createApiRequest(
            "POST",
            "/forgot-password"
        );
        apiRequest.setLoading(setLoading);
        apiRequest.setBody(convertValues(values));
        apiRequest.run().then(response => {
           if (response.ok) {
               setError({type: "success", msg: notice(1002)});
           } else switch (response.status) {
               case 404:
                   setError({type: "failed", msg: notice(13)});
                   break;

               case 409:
                   setError({type: "failed", msg: notice(14)});
                   break;

               default:
                   setError({type: "failed", msg: notice(7)});
                   break;
           }
        });
    };

    return (
        <FormWrap submit={submit} error={error} setError={setError}>
            <TextInput id="email" caption="E-mail" validate="email" type="email" required />
            <Button loading={loading}>odeslat</Button>
        </FormWrap>
    );
}