import React, {useContext, useEffect, useState} from "react";
import FormWrap from "./FormWrap";
import TextInput from "./fields/TextInput";
import Button from "../parts/content/Button";
import AppContext from "../props/appContext";
import CheckboxInput from "./fields/CheckboxInput";
import DateInput from "../parts/content/DateInput";
import InputWrap from "./InputWrap";
import {convertValues, getTimeString} from "../props/helpers";
import {notice} from "../props/constants";
import DateTimeInput from "../parts/content/DateTimeInput";

export default function AddVoucherForm({refresh}) {
    const app = useContext(AppContext);

    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const [validUntil, setValidUntil] = useState(new Date());

    const submit = (values, clear) => {
        setError(undefined);

        const apiRequest = app.createApiRequest(
            "POST",
            "/admin/voucher"
        );

        apiRequest.setLoading(setLoading);
        apiRequest.setBody({
            valid_until: getTimeString(validUntil),
            ...convertValues(values)
        });

        apiRequest.run().then(response => {
           if (response.ok) {
               setError({type: "success", msg: notice(1006)});
               refresh();
           } else
               setError(notice(7));
        });
    };

    return (
        <FormWrap submit={submit} error={error} setError={setError}>
            <InputWrap row>
                <TextInput id="code" caption="Kód" style={{flexGrow: 1}} required />
                <TextInput id="duration" caption="Doba V.I.P. členství [dny]" validate="integer" required />
            </InputWrap>
            <DateTimeInput caption="Platné do" onChange={setValidUntil} value={validUntil} omit />
            <CheckboxInput id="one_use" value={true} caption="Na jedno použití" />
            <Button loading={loading}>přidat</Button>
        </FormWrap>
    );
}