import React, {useContext, useEffect, useState} from "react";
import AppContext from "../../props/appContext";
import Loading from "../../parts/content/Loading";
import {notice} from "../../props/constants";
import ChangePlanForm from "../../forms/ChangePlanForm";
import PlanChoosing from "../VIPmain/parts/PlanChoosing";

import styles from "./PaymentsPage.module.scss";
import PaymentsGrid from "../../parts/content/table/PaymentsGrid";
import Button from "../../parts/content/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faRedo} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../parts/modal/Modal";
import WaitForRedirect from "../payments/WaitForRedirect";

export default function PaymentsPage() {
    const app = useContext(AppContext);
    const [loading, setLoading] = useState(true);
    const [plan, setPlan] = useState({});

    const [gwURL, setGwURL] = useState(undefined);

    useEffect(()=>{
        const apiRequest = app.createApiRequest(
            "GET",
            `/profile/personal-info/plan`
        );
        apiRequest.setLoading(setLoading);
        apiRequest.run().then(response => {
            if (response.ok) {
                response.json().then(setPlan);
            } else
                app.messages.flashMessage("failed", notice(7));
        });
    }, []);

    const choosePlan = (data) => {
        if (!data.voucher)
            setGwURL(data.gw_url);
        else {
            setGwURL("voucher");
        }
    };

    const stopRecurrence = () => {
        const conf = window.confirm(notice(20));

        if (conf) {
            const apiRequest = app.createApiRequest(
                "DELETE",
                `/profile/personal-info/payment-recurrence`
            );
            apiRequest.setLoading(setLoading);
            apiRequest.run().then(response => {
                if (response.ok) {
                    setPlan((oldPlan) => ({
                        ...oldPlan,
                        recurrence: false
                    }))
                    app.messages.flashMessage("success", notice(1008));
                } else {
                    response.json().then(data => {
                        if (data.code === 0)
                            app.messages.flashMessage("failed", notice(19));
                        if (data.code === 1)
                            app.messages.flashMessage("failed", notice(18));
                    });
                }
            });
        }
    };

    return (
        <Loading show={loading}>
            <Modal
                heading="Platba"
                show={gwURL}
                close={()=>setGwURL(undefined)}
            >
                {
                    gwURL==="voucher"
                        ?
                            <div className={styles.voucherBlock}>
                                <div className={styles.iconWrap}>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                </div>
                                <p>
                                    Voucher byl úspěšně uplatněn. Změna se projeví až po aktualizaci stránky.
                                </p>
                            </div>
                        :
                            <WaitForRedirect gwURL={gwURL}
                                             text={`Výběr plánu proběhl úspěšně. Za moment budete přesměrován/a na platební bránu.
                                     Nedojde-li k automatickému přesměrování, klikněte na tlačítko níže.`} />
                }
            </Modal>
            {
                plan.recurrence &&
                    <div className={styles.cancelRecurrenceWrap}>
                        <Button onClick={stopRecurrence} frontIcon={<FontAwesomeIcon icon={faRedo} />}>
                            Zrušit opakování plateb
                        </Button>
                    </div>
            }
            {
                plan.plan
                    ?
                        <React.Fragment>
                            <h3 className={styles.h3}>Změna plánu</h3>
                            <strong>Aktuální plán:</strong> {plan.plan.name}
                            <ChangePlanForm initPlan={plan.plan.id} />
                            <h3 className={styles.h3}>Okamžité prodloužení</h3>
                            <PlanChoosing choosen={choosePlan} />
                        </React.Fragment>
                    :
                        <React.Fragment>
                            <h3 className={styles.h3}>Okamžitá platba</h3>
                            <div className={styles.noPlan}>U Vás zatím neevidujeme žádný plán, chcete-li prodloužit členství, vyberte si jeden z plánů níže.</div>
                            <PlanChoosing choosen={choosePlan} />
                        </React.Fragment>
            }
            <h3 className={styles.h3}>Historie</h3>
            <PaymentsGrid />
        </Loading>
    );
}