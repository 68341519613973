import React, {useContext, useState} from "react";
import MenuItem from "./MenuItem";
import MenuItems from "./MenuItems";
import AppContext from "../../props/appContext";
import {useScrollToAnchor} from "../../props/helpers";
import {useHistory} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';

const basePath = "/vip-zona";

export default function VIPMenu({className, ...props}) {
    const app = useContext(AppContext);

    const [mobileMenu, setMobileMenu] = useState(false);
    const history = useHistory();

    const scroll = useScrollToAnchor(setMobileMenu);

    const loggedIn = [
        {
            hide: !app.premium.isActive,
            key: "poradna",
            name: "poradna",
            func: () => history.push(basePath+"/poradna")
        },
        {
            hide: !app.premium.isActive,
            key: "recepty",
            name: "recepty",
            func: () => history.push(basePath+"/clanky?kategorie=recepty")
        },
        {
            hide: !app.premium.isActive,
            key: "zacvič si",
            name: "zacvič si",
            func: () => history.push(basePath+"/clanky?kategorie=zacvic-si")
        },
        {
            hide: !app.premium.isActive,
            key: "škola malin",
            name: "škola malin",
            func: () => history.push(basePath+"/clanky?kategorie=skola-malin")
        },
        {
            key: "profil",
            name: <FontAwesomeIcon icon={faUserCircle} />,
            icon: true,
            func: () => history.push(basePath+"/profil")
        },
        {
            key: "odhlasit",
            name: <FontAwesomeIcon icon={faSignOutAlt} />,
            icon: true,
            func: ()=>app.logout()
        }
    ];

    const logout = [
        {
            key: "registrace",
            name: "registrace",
            func: ()=>history.push(basePath+"/registrace")
        },
        {
            key: "prihlaseni",
            name: "přihlášení",
            func: ()=>scroll("prihlaseni")
        },
    ];

    const current = app.isLoggedIn ? loggedIn : logout;

    return (
        <MenuItems className={className} mobileMenu={mobileMenu} mobileMenuCallback={setMobileMenu} {...props}>
            {current.map((item, i)=>
                <MenuItem key={item.key} func={item.func} hide={item.hide} icon={item.icon}>{item.name}</MenuItem>
            )}

        </MenuItems>
    );
}