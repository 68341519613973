import React, {useContext, useEffect, useState} from "react";
import CrossHeader from "../../parts/header/CrossHeader";
import NarrowLayout from "../../layout/NarrowLayout";
import {useHistory, useLocation, useParams} from "react-router-dom";
import Loading from "../../parts/content/Loading";
import AppContext from "../../props/appContext";
import Button from "../../parts/content/Button";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";

import styles from "./ArticlePage.module.scss";
import Image from "../../parts/content/Image";
import {
    getImageSrc,
    htmlFromDelta,
    openPhotos,
    valueUnitString
} from "../../props/helpers";
import ImageGallery from "../../parts/content/ImageGallery";

import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import {CATEGORY, PSWP_ELEMENT} from "../../props/constants";

import {PieChart} from "react-minimal-pie-chart";

import variables from "../../variables.module.scss";
import {useCurrentPath} from "../../props/hooks";

function processBlock(block, i) {
    if (block.type==="text")
        return (
            <div
                key={i}
                className={styles.textBlock}
                dangerouslySetInnerHTML={{__html: htmlFromDelta(block.content)}}
            />
        );

    if (block.type==="youtube_video")
        return (
            <div
                key={i}
                className={styles.youtubeVideo}
            >
                <iframe className={styles.iframe}
                        src={"https://www.youtube.com/embed/"+block.content}
                        frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen />
            </div>
        );

    if (block.type==="images") {
        return <ImageGallery key={i} images={block.content} />;
    }

}

export default function ArticlePage() {
    const app = useContext(AppContext);
    const history = useHistory();
    const params = useParams();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [macrosChart, setMacrosChart] = useState(false);
    const [article, setArticle] = useState({});
    const [prevLink, setPrevLink] = useState(`/vip-zona/`);
    const path = useCurrentPath();

    useEffect(()=>{
        if (location.state && location.state.prevLink) {
            setPrevLink(location.state.prevLink);
        }
    }, [location.state]);


    useEffect(()=>{
        const controller = new window.AbortController();
        const apiRequest = app.createApiRequest(
            "GET",
            `/premium/article/${params.id}`
        );
        apiRequest.setController(controller);
        apiRequest.run().then(response => {
           if (response.ok) {
               response.json().then(data=>{
                   setArticle(data);
                   setLoading(false);
               });
           } else if (response.status===404) {
               history.replace(`/vip-zona`);
           }
        });

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(()=>{
        if (loading===false)
            setMacrosChart(true);
    }, [loading]);

    let introImageRef = null;

    let chartData = null;
    if (article.macros) {
        chartData = [
            { first: true, title: 'Proteiny', value: article.macros.proteins, color: variables.colorLogo },
            { title: 'Sacharidy', value: article.macros.carbohydrates, color: variables.colorSpecial },
            { title: 'Tuky', value: article.macros.fats, color: variables.slightlyDarkGrayColor },
        ];
    }

    return (
        <Loading show={loading}>
            <NarrowLayout>
                <CrossHeader animate={true} heading={article.name} crossClick={()=>history.push(prevLink)} />
                {PSWP_ELEMENT}
                {
                    article.category &&
                    <div className={styles.category}>
                        {CATEGORY[article.category].name}
                    </div>
                }
                {
                    app.user.isAdmin &&
                        <div className={styles.editWrap}>
                            <Button 
                                small 
                                onClick={()=>history.push(`/vip-zona/clanky/upravit/${params.id}`, {prevLink: path})}
                                frontIcon={<FontAwesomeIcon icon={faEdit}/>}
                            >
                                Upravit
                            </Button>
                        </div>
                }
                {
                    article.image_id &&
                        <div className={styles.mainImage}>
                            <Image
                                iri={"/premium/article/image/id/"}
                                id={article.fit_image_id}
                                alt={`Úvodní obrázek – ${article.name}`}
                                wrapClassName={styles.mainImageWrap}
                                setRef={(r)=>introImageRef=r}
                                useClassicLoad
                                imageParams={{onClick: ()=>openPhotos([
                                        {
                                            w: article.original_image_dimension.width,
                                            h: article.original_image_dimension.height,
                                            src: getImageSrc(`/premium/article/image/id/${article.image_id}`),
                                            msrc: getImageSrc(`/premium/article/image/id/${article.fit_image_id}`)
                                        }
                                    ], 0, [introImageRef])}}
                            />
                        </div>
                }
                {
                    article.macros &&
                        <div className={styles.macrosWrap}>
                            <h3>Makroživiny na 100 g</h3>
                            <div className={styles.macros}>
                                <div className={styles.chartWrap}>
                                    {
                                        macrosChart &&
                                        <PieChart
                                            data={chartData}
                                            totalValue={article.macros.proteins+article.macros.carbohydrates+article.macros.fats}
                                            lineWidth={20}
                                            rounded
                                            radius={42}
                                            label={({dataEntry})=>dataEntry.first && article.macros.calories}
                                            labelStyle={{
                                                fill: "black",
                                                fontWeight: "bold"
                                            }}
                                            labelPosition={0}
                                            animate
                                        />
                                    }
                                </div>
                                <div className={styles.textWrap}>
                                    <div className={styles.textCalories}>
                                        {article.macros.calories}&nbsp;kcal
                                    </div>
                                    <div className={styles.textProteins}>
                                        Bílkoviny: {article.macros.proteins}&nbsp;g
                                    </div>
                                    <div className={styles.textCarbohydrates}>
                                        Sacharidy: {article.macros.carbohydrates}&nbsp;g
                                    </div>
                                    <div className={styles.textFats}>
                                        Tuky: {article.macros.fats}&nbsp;g
                                    </div>
                                </div>
                            </div>
                        </div>
                }
                {
                    article.ingredients && article.ingredients.length!==0 &&
                        <div className={styles.ingredientsBlock}>
                            <h3>Ingredience</h3>
                            {
                                article.ingredients.map(ingredient=>
                                    <div key={ingredient.id} className={styles.ingredient}>
                                        <React.Fragment><strong>{ingredient.name}</strong> {valueUnitString(ingredient.amount, ingredient.unit)} </React.Fragment>
                                        {ingredient.alternative_amount!==0 ? <span>({valueUnitString(ingredient.alternative_amount, ingredient.alternative_unit)})</span> : ""}
                                    </div>
                                )
                            }
                        </div>
                }
                <div className={styles.content}>
                    {
                        article.values && article.values.map((block, i)=>processBlock(block, i))
                    }
                </div>
            </NarrowLayout>
        </Loading>
    );
}