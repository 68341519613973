import React, {useContext, useEffect, useState} from "react";
import AppContext from "../../props/appContext";
import {useHistory} from "react-router-dom";
import CrossHeader from "../../parts/header/CrossHeader";
import NarrowLayout from "../../layout/NarrowLayout";

import styles from "./RegistrationPage.module.scss";
import PartsRegistration from "./parts/PartsRegistration";
import Loading from "../../parts/content/Loading";

export default function RegistrationPage() {

    const app = useContext(AppContext);
    const [loading, setLoading] = useState(true);
    const [headerAnimate, setHeaderAnimate] = useState(false);
    const history = useHistory();
    const [currentPart, setCurrentPart] = useState(1);

    useEffect(()=>{
        let unmounted = false;

        const apiRequest = app.createApiRequest(
            "GET",
            "/registration"
        );
        apiRequest.setLoading(setLoading);

        apiRequest.run().then((response) => {
            if (response.ok)
                response.json().then((data) => {
                    if (!unmounted) {
                        let curr = 1;
                        if (data.plan) curr = 2;
                        if (data.plan && data.billingInfo) curr = 3;
                        setCurrentPart(curr);
                        setHeaderAnimate(true);
                    }
                });
        });

        return ()=>{
            unmounted = true;
        }
    }, []);

    return (
        <Loading show={loading}>
            <section className={styles.section}>
                <NarrowLayout>
                    <CrossHeader animate={headerAnimate} heading={"Registrace"} crossClick={()=>history.push("/vip-zona")} className={styles.header} />
                    <PartsRegistration partNumber={currentPart} setPartNumber={setCurrentPart} />
                </NarrowLayout>
            </section>
        </Loading>
    );
}