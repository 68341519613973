import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import TextInput from "./TextInput";
import update from "immutability-helper";
import {notice} from "../../props/constants";

const initialValue = {
    newPass: {
        value: "",
        invalid: false
    },
    checkPass: {
        value: "",
        invalid: false
    }
};

export default forwardRef((props, ref) => {
    const {valueCallback, clearAfterSubmit, required} = props;

    const [value, setValue] = useState(initialValue);
    const newPassRef = useRef();
    const checkPassRef = useRef();

    const sendValue = (id, value, invalid) => {
        setValue((oldValue)=>{
            const newValue = update(oldValue, {[id]:{$set: {value: value, invalid: invalid}}});

            let invalidTmp = newValue.newPass.invalid || newValue.checkPass.invalid;
            if (newValue.newPass.value!==newValue.checkPass.value) {
                invalidTmp = true;
                checkPassRef.current.addInvalid("checkPass", notice(104));
            } else if (newValue.newPass.value===newValue.checkPass.value)
                checkPassRef.current.removeInvalid("checkPass");

            valueCallback(newValue.newPass.value, invalidTmp);

            return newValue;
        });
    };

    useImperativeHandle(
        ref,
        ()=> ({
            checkValidity(v) {
                newPassRef.current.checkValidity(v);
                checkPassRef.current.checkValidity(value.checkPass.value);
            },
            clear() {
                setValue(initialValue);
                newPassRef.current.clear();
                checkPassRef.current.clear();
            }
        })
    );

    return (
        <React.Fragment>
            <TextInput
                caption="Nové heslo"
                type="password"
                validate="pass"
                clearAfterSubmit={clearAfterSubmit}
                ref={newPassRef}
                valueCallback={(value,invalid)=>sendValue("newPass", value, invalid)}
                value={value.newPass.value}
                required={required}
            />
            <TextInput
                caption="Nové heslo znovu"
                type="password"
                clearAfterSubmit={clearAfterSubmit}
                ref={checkPassRef}
                valueCallback={(value,invalid)=>sendValue("checkPass", value, invalid)}
                value={value.checkPass.value}
                required={required}
            />
        </React.Fragment>
    )
});