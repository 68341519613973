import React, {useContext} from "react";
import AppContext from "../../props/appContext";
import Header from "../../parts/header/Header";
import DefaultLayout from "../../layout/DefaultLayout";
import Heading from "../../parts/content/Heading";
import Alert from "../../forms/fields/Alert";
import {notice} from "../../props/constants";
import MenuCards from "../../parts/content/MenuCards/MenuCards";
import PersonalInfoBlock from "./PersonalInfoBlock";

import styles from "./ProfilePage.module.scss";
import UsersPage from "./UsersPage";
import BillingInfoPage from "./BillingInfoPage";
import VouchersPage from "./VouchersPage";
import PaymentsPage from "./PaymentsPage";

export default function () {
    const app = useContext(AppContext);

    let menuCardsItems = [{
        key: "osobni-udaje",
        name: "osobní údaje",
        content: <PersonalInfoBlock />
    }];

    if (app.user.isAdmin)
        menuCardsItems = menuCardsItems.concat([
            {
                key: "uzivatele",
                name: "uživatelé",
                content: <UsersPage />
            },
            {
                key: "vouchery",
                name: "vouchery",
                content: <VouchersPage />
            }
        ]);
    else
        menuCardsItems = menuCardsItems.concat([
            {
                key: "fakturacni-udaje",
                name: "fakturační údaje",
                content: <BillingInfoPage />
            },
            {
                key: "platby-a-fakturace",
                name: "platby a fakturace",
                content: <PaymentsPage />
            }
        ]);

    return (
        <React.Fragment>
            <Header narrow />
            <DefaultLayout>
                {
                    !app.premium.isActive
                    &&
                        <Alert>{notice(1)}</Alert>
                }
                <Heading>Profil</Heading>
                <MenuCards
                    items={menuCardsItems}
                />
            </DefaultLayout>
        </React.Fragment>
    );
}