import React, {useContext, useState} from "react";
import FormWrap from "./FormWrap";
import TextInput from "./fields/TextInput";
import InputWrap from "./InputWrap";
import Button from "../parts/content/Button";
import CheckboxInput from "./fields/CheckboxInput";
import AppContext from "../props/appContext";
import {notice} from "../props/constants";

export default function BillingInfoForm({success, back, change, data, userId}) {
    const app = useContext(AppContext);

    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const [company, setCompany] = useState(false);

    const submit = (values) => {
        setError(undefined);

        let body = {};
        if (company) {
            body.company = values.company.value;
            body.ico = values.ico.value;
        } else {
            body.name = values.name.value;
            body.surname = values.surname.value;
        }
        body = {
            ...body,
            street: values.street.value,
            city: values.city.value,
            zip: values.zip.value,
            telephone: values.telephone.value==="" ? undefined : values.telephone.value
        };

        if (change) {
            const apiRequest = app.createApiRequest(
                "PUT",
                userId ? `/admin/user/${userId}/billing-info` : "/profile/billing-info/"
            );

            apiRequest.setLoading(setLoading);
            apiRequest.setBody(body);

            apiRequest.run().then(response => {
                if (response.ok) {
                    setError({type: "success", msg: notice(1006)});
                } else
                    setError(notice(7));
            });
        } else {
            const apiRequest = app.createApiRequest(
                "POST",
                "/registration/billing-info"
            );

            apiRequest.setLoading(setLoading);
            apiRequest.setBody(body);
            apiRequest.run().then(response => {
                if (response.ok) {
                    success();
                } else if (response.status === 400) {
                    response.json().then(data => {
                        if (data.code === 0) back();
                    });
                }
            });
        }
    };

    if (!data) data = {};

    return (
        <FormWrap submit={submit} error={error} setError={setError}>
            <CheckboxInput valueCallback={(value)=>setCompany(value)} caption="Chci fakturovat na firmu" value={data.company ? true : undefined} omit />
            {
                company
                    ?
                        <InputWrap key="company" row>
                            <TextInput id="company" caption="Společnost" style={{flexGrow: 1}} value={data.company ? data.company : undefined} required />
                            <TextInput id="ico" validate="number" caption="IČO" value={data.ico ? data.ico : undefined} required />
                        </InputWrap>
                    :
                        <InputWrap key="notACompany" row>
                            <TextInput id="name" caption="Jméno" style={{flexGrow: 1}} value={data.name ? data.name : undefined} required />
                            <TextInput id="surname" caption="Příjmení" style={{flexGrow: 1}} value={data.surname ? data.surname : undefined} required />
                        </InputWrap>
            }
            <TextInput id="street" caption="Ulice" value={data.street ? data.street : undefined} required />
            <InputWrap row>
                <TextInput id="city" caption="Město" value={data.city ? data.city : undefined} style={{flexGrow: 1}} required />
                <TextInput id="zip" caption="PSČ" validate="zip"  value={data.zip ? data.zip : undefined} required />
            </InputWrap>
            <TextInput id="telephone" caption="Telefon" value={data.telephone ? data.telephone : undefined} />
            <Button loading={loading}>{change ? "uložit" : "další"}</Button>
        </FormWrap>
    );
}