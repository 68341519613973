import React, {forwardRef, useContext, useImperativeHandle, useRef, useState} from "react";
import CheckboxInput from "./fields/CheckboxInput";
import TextInput from "./fields/TextInput";
import SelectBox from "./fields/SelectBox";
import FileChooser from "./fields/FileChooser";
import AppContext from "../props/appContext";

import styles from "./ArticleParamsForm.module.scss";
import ProgressBar from "./fields/ProgressBar";
import Image from "../parts/content/Image";
import CropImageModal from "../parts/modal/CropImageModal";
import { notice } from "../props/constants";
import Button from "../parts/content/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCarrot } from "@fortawesome/free-solid-svg-icons";
import IngredientsManageModal from "../parts/modal/IngredientsManageModal";

const categories = [
    {key: "recepty", val: 1, caption: "Recepty"},
    {key: "zacvic-si", val: 2, caption: "Zacvič si"},
    {key: "skola-malin", val: 3, caption: "Škola malin"}
];

export default forwardRef((props, ref) => {
    const {
        nameChanged,
        publishedChange,
        categoryChange,
        imageChange,
        macrosChange,
        name,
        published,
        category,
        image,
        macros,
        id,
        setId
    } = props;

    const app = useContext(AppContext);

    let refs = useRef(new Map());
    const [progress, setProgress] = useState(0);
    const [progressShow, setProgressShow] = useState(false);

    const [cropModal, setCropModal] = useState(false);
    const [img, setImg] = useState(false);
    const [imagesList, setImagesList] = useState([]);

    const [ingredientModal, setIngredientModal] = useState(false);

    useImperativeHandle(
        ref,
        ()=> ({
            clear() {
                refs.current.forEach((ref)=>ref.clear());
            }
        })
    );

    const addRef = (node, id) => {
        return !node ? refs.current.delete(id) : refs.current.set(id, node);
    };

    const uploadImage = (images, params) => {
        const apiRequest = app.createApiRequest(
            "POST",
            "/admin/article/intro-image" + (id ? "?id="+id : "")
        );

        setProgress(0);
        apiRequest.setLoading(setProgressShow);
        apiRequest.setProgress(setProgress);

        apiRequest.upload(images, params).then((xhr)=>{
            setCropModal(false);
            if (xhr.status===200) {
                const data = JSON.parse(xhr.response);
                imageChange(data.imageId);
                if (!id) setId(data.articleId);
            } else {
                app.messages.flashMessage("failed", notice(7));
            }
        });
    };

    const choosedImage = (imageId) => {
        const apiRequest = app.createApiRequest(
            "POST",
            `/admin/article/intro-image-id/${imageId}${id ? "?article_id="+id : ""}`
        );

        apiRequest.run().then(response => {
            if (response.ok) {
                response.json().then(data => {
                    imageChange(imageId);
                    if (!id) setId(data.articleId);
                });
            } else {
                app.messages.flashMessage("failed", notice(7));
            }
        });
    };

    const renderMacros = [
        {
            key: "calories",
            caption: "Kalorie [kcal]"
        },
        {
            key: "proteins",
            caption: "Bílkoviny [g/100g]"
        },
        {
            key: "carbohydrates",
            caption: "Sacharidy [g/100g]"
        },
        {
            key: "fats",
            caption: "Tuky [g/100g]"
        }
    ];
    
    return (
        <React.Fragment>
            <TextInput ref={(r)=>addRef(r, "name")} caption={"Název článku"} id="name" valueCallback={nameChanged} value={name} />
            <SelectBox ref={(r)=>addRef(r, "category")} caption={"Kategorie"} id="category" options={categories} valueCallback={categoryChange} value={category} />
            {
                Number(category)===1 && (
                    <React.Fragment>
                        <div className={styles.macros}>
                            {
                                renderMacros.map((macro)=>
                                    <TextInput key={macro.key} ref={(r)=>addRef(r, macro.key)}
                                            caption={macro.caption}
                                            id={macro.key}
                                            validate={"number"}
                                            valueCallback={(val, invalid)=>{
                                                if (!invalid)
                                                    macrosChange(macro.key, val);
                                            }}
                                            value={macros && macros[macro.key] ? macros[macro.key] : ""}
                                    />
                                )
                            }
                        </div>
                        <div className={styles.ingredientsButton}>
                            <Button 
                                frontIcon={<FontAwesomeIcon icon={faCarrot} />} 
                                small
                                onClick={()=>setIngredientModal(true)}
                            >
                                Ingredience
                            </Button>
                            <IngredientsManageModal
                                id={id}
                                setId={setId}
                                show={ingredientModal}
                                setShow={setIngredientModal}
                            />
                        </div>
                    </React.Fragment>
                )
            }
            <FileChooser ref={(r)=>addRef(r, "image")}
                         caption="Úvodní foto"
                         id="image"
                         accept="image/png,image/jpeg,image/gif"
                         valueCallback={(images, invalid)=>{
                             if (!invalid) {
                                 setImagesList(images);
                                 const reader = new FileReader();
                                 reader.onload = ()=>{
                                     setImg(reader.result);
                                     setCropModal(true);
                                 };
                                 reader.readAsDataURL(images[0]);
                             }
                         }}
                         validate="fileSize"
            />
            <CropImageModal
                show={cropModal}
                setShow={setCropModal}
                heading={"Vytvoření miniatury"}
                src={img}
                aspect={1}
                file={imagesList[0]}
                onChange={crop=>uploadImage(imagesList, [crop])}
                hasId={imageId=>{
                    choosedImage(imageId);
                    setCropModal(false);
                }}
            />
            <Image iri={"/premium/article/image/id/"}
                   id={image}
                   alt={"Úvodní obrázek"}
                   wrapClassName={styles.introImageWrap}
                   imageParams={{className: styles.introImage}}
            />
            <ProgressBar show={progressShow} progress={progress} />
            <CheckboxInput ref={(r)=>addRef(r, "published")} caption={"Publikovat"} id="published" valueCallback={publishedChange} value={published} />
        </React.Fragment>
    );
});