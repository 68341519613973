import React, {forwardRef} from "react";
import BaseInput from "./BaseInput";

export default forwardRef((props, ref) => {
    const {options, value} = props;

    const tmpOptions = options.map((option)=>
        <option key={option.key} value={option.val}>{option.caption}</option>
    );

    return (
        <BaseInput ref={ref} {...props}>
            <select
                value={value}
            >
                {tmpOptions}
            </select>
        </BaseInput>
    )
});