import React from "react";

import styles from "./InstagramBlock.module.scss";
import BlankHref from "./BlankHref";

import img1 from "../../files/images/instagram/1.jpg";
import img2 from "../../files/images/instagram/2.jpg";
import img3 from "../../files/images/instagram/3.jpg";
import img4 from "../../files/images/instagram/4.jpg";
import img5 from "../../files/images/instagram/5.jpg";
import img6 from "../../files/images/instagram/6.jpg";

const PHOTOS = [
    {
        url: "https://www.instagram.com/p/CL_rZMJHmId/?igshid=150m6a3bf51k0&fbclid=IwAR03OJpcJc3xhkCrfHXleyLxJxsYewQEPKCtAL2IEcjhpszsBn4Yz64w21o",
        id: 0,
        caption: "Krevety s rýžovými nudlemi",
        src: img1
    },
    {
        url: "https://www.instagram.com/p/CK07U6WFotJ/?igshid=7jx3bpjyy7cx&fbclid=IwAR1DQA1L9WhIlqVcQrI8UJ6UV1zPhCwsT6lWfjpsg49aj3yaaS-T_G0PTTk",
        id: 1,
        caption: "Zkuste jeden den podle nás! ",
        src: img2
    },
    {
        url: "https://www.instagram.com/p/CK8r4bXlCz_/?igshid=3xnikw6qx0zj&fbclid=IwAR1mDYTplvvH-wSOB1uHgS7Q3vg9TssxXzi42w0Ufgf8hjSUztEFXAw5Jio",
        id: 2,
        caption: "High protein pudding je skvělý produkt s vyšším množstvím bílkovin bez zbytečného vysokého množství sacharidů (cukrů)",
        src: img3
    },
    {
        url: "https://www.instagram.com/p/CKWxcm0FWzZ/?igshid=5xmxwrgb3rlx&fbclid=IwAR2oYRP28RXMuhzcG-pNtwcVjaqVcASOUgCVRbMQ_qvsCvNZpEejjoVBtls",
        id: 3,
        caption: "Tip na jednoduché a výborné pečivo pouze ze 3 ingrediencí, za 15 minut hotové a za 278 kalorií ",
        src: img4
    },
    {
        url: "https://www.instagram.com/p/CG1witeH_tB/?igshid=ab90hpliq6ps&fbclid=IwAR1xvx_2OFUDcDTajKE8GDvG6rXwrj6pMKm8j5Jk1h6dPyQjoOXQNv9cPLA",
        id: 4,
        caption: "Dnes si dáme recept na sladký, zdravý dezert, ať si osladíme den",
        src: img5
    },
    {
        url: "https://www.instagram.com/p/CG670D6HLVW/?igshid=3owuqe3b2rj7&fbclid=IwAR1IgRvw-dEVBKJuzOpyXPH6p_FS0YmxFESlTOKK7LcNK-nDUJtQPLnTp_Q",
        id: 5,
        caption: "3R všichni znají (roušky, ruce, rozestupy",
        src: img6
    }
];

export default function InstagramBlock() {

    return (
        <div className={styles.instagramFeed}>
            {PHOTOS.map(({ id, caption, src, url }) => (
                <BlankHref key={id} href={url}>
                    <img src={src} alt={caption} />
                </BlankHref>
            ))}
        </div>
    );
}