import React from 'react';
import { withRouter } from "react-router";
import {ReactComponent as Logo} from "../../files/images/logo-backgrounded.svg";
import {ReactComponent as LogoWithoutBg} from "../../files/images/logo-only-raspberry.svg";

import styles from "./Header.module.scss";
import MainMenu from "./MainMenu";
import {Spring, animated} from 'react-spring';

import {headerHeightTop, headerHeightBottom, headerHeightMedium} from "../../props/constants";
import variables from "../../variables.module.scss";
import VIPMenu from "./VIPMenu";
const background = "255,255,255";

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            burgerBlack: !!props.narrow,
            currProps: {
                height: props.narrow ? headerHeightBottom : (this.isMedium() ? headerHeightMedium : headerHeightTop),
                backgroundColor: props.narrow ? "white" : `rgba(${background},0)`,
                color: props.narrow ? "black" : "white"
            }
        };

        this.hideLogo = {
            opacity: 0
        };
        this.showLogo = {
            opacity: 1
        };
    }

    isMedium() {
        return window.innerWidth <= Number(variables.mediumWidth);
    }

    onScroll = () => {
        if (window.scrollY >= 20) {
            if (!this.state.burgerBlack)
                this.setState({
                    burgerBlack: true,
                    currProps: {
                        height: headerHeightBottom,
                        backgroundColor: `rgba(${background},1)`,
                        color: "black"
                    }
                });
        } else {
            if (this.state.burgerBlack)
                this.setState({
                    burgerBlack: false,
                    currProps: {
                        height: this.isMedium() ? headerHeightMedium : headerHeightTop,
                        backgroundColor:`rgba(${background},0)`,
                        color: "white"
                    }
                });
        }
    };

    onResize = () => {
        if (this.isMedium() && window.scrollY < 20) {
            this.setState({
                currProps: {
                    height: headerHeightMedium,
                    backgroundColor: this.state.currProps.backgroundColor,
                    color: this.state.currProps.color
                }
            });
        } else if (window.scrollY < 20) {
            this.setState({
                currProps: {
                    height: headerHeightTop,
                    backgroundColor: this.state.currProps.backgroundColor,
                    color: this.state.currProps.color
                }
            });
        }
    };

    componentDidMount() {
        if (!this.props.narrow) {
            window.addEventListener("scroll", this.onScroll);
            window.addEventListener("resize", this.onResize);
        }
    }

    componentWillUnmount() {
        if (!this.props.narrow) {
            window.removeEventListener("scroll", this.onScroll);
            window.removeEventListener("resize", this.onResize);
        }
    }
    render() {
        const {burgerBlack, currProps} = this.state;

        let menu = <VIPMenu className={styles.items} burgerBlack={true} />;
        if (this.props.mainMenu)
            menu = <MainMenu className={styles.items} burgerBlack={burgerBlack}/>;

        return (
            <React.Fragment>
            <Spring
                native
                to={currProps}
            >
                {
                    props =>
                        <animated.header className={styles.header} style={props}>
                            <Spring
                                native
                                to={burgerBlack ? this.hideLogo : this.showLogo}
                            >
                                {props =>
                                    <animated.div style={props} onClick={()=>this.props.history.push("/")}>
                                        <Logo className={styles.logo} />
                                    </animated.div>
                                }
                            </Spring>
                            <Spring
                                native
                                to={burgerBlack ? this.showLogo : this.hideLogo}
                            >
                                {props =>
                                    <animated.div style={props} onClick={()=>this.props.history.push("/")}>
                                        <LogoWithoutBg className={styles.logoWithoutBg} />
                                    </animated.div>
                                }
                            </Spring>
                            {menu}
                        </animated.header>
                }
            </Spring>
                {this.props.narrow && <div style={{height: headerHeightBottom}}/>}
            </React.Fragment>
        );
    }
}

export default withRouter(Header);
