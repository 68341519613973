import React, {useRef, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import styles from "./SearchField.module.scss";
import Button from "./Button";
import {allCategoriesURLParams} from "../../props/helpers";
import {useQuery, useSearchParams} from "../../props/hooks";
import SearchBox from "./SearchBox";

function addCategory(categories, category) {
    return (categories==="" ? categories : categories+"+")+category;
}

function existsIn(categories, category) {
    if (categories==="") return true;
    return categories.indexOf(category)!==-1;
}

function removeCategory(categories, category) {
    if (categories==="") categories = allCategoriesURLParams();
    let res;
    res = categories.replace(category+"+", "");
    res = res.replace(category+" ", "");
    res = res.replace(category, "");
    if (res.indexOf("+")===0 || res.indexOf(" ")===0)
        res = res.substr(1);
    if (res.lastIndexOf("+")===res.length-1 || res.lastIndexOf(" ")===res.length-1)
        res = res.substr(0, (res.lastIndexOf("+")===-1 ? res.lastIndexOf(" ") : res.lastIndexOf("+")));
    return res;
}

export default function SearchField({categories}) {
    const queries = useQuery();
    const [value, setValue] = useState(queries.get("hledat")!==null ? queries.get("hledat") : "");
    const timer = useRef(-1);
    const searchParams = useSearchParams();

    const setURL = (category) => {
        if (existsIn(categories, category))
            searchParams("kategorie,stranka", removeCategory(categories, category), 1);
        else
            searchParams("kategorie,stranka", addCategory(categories, category), 1);
    };

    const changeValue = (e) => {
        const val = e.target.value;
        setValue(val);
        if (timer.current!==-1)
            clearTimeout(timer.current);
        timer.current = setTimeout(()=>searchParams("hledat,stranka", val, 1), 500);
    };

    const forceChange = (val) => {
        if (timer.current!==-1)
            clearTimeout(timer.current);
        searchParams("hledat,stranka", val, 1);
    };

    return (
        <React.Fragment>
            <SearchBox value={value} onChange={changeValue} forceChange={forceChange} />
            <div className={styles.wrapBtns}>
                <Button small
                        selected={existsIn(categories, "recepty")}
                        onClick={()=>setURL("recepty")}
                        
                >
                    Recepty
                </Button>
                <Button small
                        selected={existsIn(categories, "zacvic-si")}
                        onClick={()=>setURL("zacvic-si")}
                >
                    Zacvič si
                </Button>
                <Button small
                        selected={existsIn(categories, "skola-malin")}
                        onClick={()=>setURL("skola-malin")}
                >
                    Škola malin
                </Button>
            </div>
        </React.Fragment>
    )
}