import React from "react";

import styles from "./ProgressBar.module.scss";

export default function ({progress, text, disableText, show}) {
    if (!progress) progress=0;

    return (
        <div className={styles.wrap} style={{maxHeight: show ? 100 : 0}}>
            {
                !disableText &&
                    <div className={styles.text}>Nahrává se...</div>
            }
            <div className={styles.bar}>
                <div className={styles.progress} style={{width: `${progress*100}%`}} />
            </div>
        </div>
    );
}