import {apiAddress, CATEGORY, QUILL} from "./constants";
import Quill from 'quill/dist/quill.min.js';
import "quill/dist/quill.core.js";

import React from "react";

import PhotoSwipe from "photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import useScrollTo from "react-spring-scroll-to-hook";
import {config} from "react-spring";


export function useScrollToAnchor(setMobileMenu) {
    const {scrollTo} = useScrollTo(config.slow);

    return (anchor) => {
        anchor = "#" + anchor;
        const el = document.querySelector(anchor);
        scrollTo(el);
        window.history.pushState(undefined, "", anchor);
        setMobileMenu(false);
    };
}

export const dateToCzech = (date) => {
    return date.toLocaleTimeString("cs-CZ", {year: "numeric", day: "numeric", month: "numeric", hour: '2-digit', minute:'2-digit'});
};

export const truncateText = (text, length) => {
    if (text.length<length) return text;
    const result = text.substr(0, length-3);
    return result+"...";
};

export const round = (value, precision) => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
};

export const numberToCzech = (number) => {
    return new Intl.NumberFormat('cs-CZ').format(number);
};

export const convertValues = (values) => {
    let result = {};
    for (const [key, value] of Object.entries(values)) {
        result[key] = value.value;
    }
    return result;
};

export const allCategoriesURLParams = () => {
    let resultURL = "";
    for (let category of Object.values(CATEGORY)) {
        if (resultURL==="")
            resultURL = category.webalize;
        else
            resultURL = resultURL + "+" + category.webalize;
    }
    return resultURL;
};

export const readDataURL = (file, callback) => {
    const reader = new FileReader();
    reader.onload = () => callback(reader.result);
    reader.readAsDataURL(file);
};

export const htmlFromDelta = (description) => {
    const quill = new Quill(document.createElement("div"), {formats: QUILL.FORMATS});
    quill.setContents(description);
    return quill.root.innerHTML;
};

export const getImageSrc = (link) => {
    return `${apiAddress}${link}?csrf-token=${localStorage.getItem("csrfToken")}`;
};

export const convertCzechFloatString = (value) => {
    if (value)
        return value.toString().replaceAll(",",".");
    return "";
};

export const convertToCzechFloatString = (value) => {
    value = Math.round(value * 10) / 10;
    return value.toString().replaceAll(".", ",");
};

export const localNumber = (value) => {
    return value.toLocaleString();
};

export function isFloat(n){
    return Number(n) === n && n % 1 !== 0;
}

export const inflectionOfUnit = (value, unit) => {
    value = Number(value);

    switch (unit) {
        case "čajová lžíce":
            if (isFloat(value) || [2,3,4].includes(value)) return "čajové lžíce";
            if (value===1) return "čajová lžíce";
            return "čajových lžic";

        case "polévková lžíce":
            if (isFloat(value) || [2,3,4].includes(value)) return "polévkové lžíce";
            if (value===1) return "polévková lžíce";
            return "polévkových lžic";

        default:
            return unit;
    }
};

export const valueUnitString = (value, unit) => {
    if (unit==="bez množství") return "";
    return <React.Fragment>{localNumber(value)}&nbsp;{inflectionOfUnit(value, unit)}</React.Fragment>;
};

export const leadingZero = (value) => {
    return ('0'+value).substr(-2);
};

export const getTimeString = (date) => {
    return `${date.getFullYear()}-${leadingZero(date.getMonth()+1)}-${leadingZero(date.getDate())} ${leadingZero(date.getHours())}:${leadingZero(date.getMinutes())}`;
};

export const getMacrosFromObject = (calories) => {
    return {
        total: calories.calories,
        proteins: [calories.proteins_l, calories.proteins_u],
        carbohydrates: [calories.carbohydrates_l, calories.carbohydrates_u],
        fats: [calories.fats_l, calories.fats_u]
    }
};

export const translateActivity = (activity) => {
    switch (activity) {
        case "low": return "nízká";
        case "light": return "lehká";
        case "moderate": return "střední";
        case "vigorous": return "vysoká";
    }
    return <i>nedefinovaná</i>;
};

export const translateMethod = (method) => {
    switch (method) {
        case "loss": return "hubnutí";
        case "maintain": return "udržování";
        case "gain": return "nabírání";
    }
    return <i>nedefinovaná</i>;
};

// PSWP

const getThumbBoundsFn = (index, refs) => {
    const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
    const rect = (refs[index] ? refs[index] : refs[0]).getBoundingClientRect();

    return {x:rect.left, y:rect.top+pageYScroll, w:rect.width};
};

export const openPhotos = (images, index, refs) => {
    const pswpElement = document.querySelectorAll('.pswp')[0];

    const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, images,
        {
            index: index,
            history: false,
            getThumbBoundsFn: (index)=>getThumbBoundsFn(index,refs),
            errorMsg: `<div class="pswp__error-msg"><a href="%url%" target="_blank">Obrázek</a> se bohužel nepovedlo načíst.</div>`
        });
    gallery.init();
};
