import React from 'react';
import styles from './DefaultLayout.module.scss';

function DefaultLayout({className, children, innerClassName, ...props}) {
    return (
    <section className={styles.section+(className ? " "+className : "")} {...props}>
        <section className={styles.centeredSection+(innerClassName ? " "+innerClassName : "")}>
            {children}
        </section>
    </section>
    );
}

export default DefaultLayout;