import React from 'react';
import DefaultLayout from "./DefaultLayout";

import styles from "./BeRaspberryLayout.module.scss";

export default function BeRaspberryLayout({backgroundImage, children, className, ...props}) {
    return (
        <section className={styles.section+(className ? " "+className : "")} {...props}>
            <DefaultLayout className={styles.beRaspberryBlock}>
                {children}
            </DefaultLayout>
            <DefaultLayout className={styles.backgroundWrap}>
                {React.Children.map(backgroundImage, child => (
                    React.cloneElement(child, {className: styles.backgroundImage})
                ))}
            </DefaultLayout>
        </section>
    );
}