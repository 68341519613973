import React, {useContext, useEffect, useState} from "react";
import FormWrap from "./FormWrap";
import Button from "../parts/content/Button";
import AppContext from "../props/appContext";
import SelectBox from "./fields/SelectBox";
import {notice} from "../props/constants";

export default function ChangePlanForm({initPlan}) {
    const app = useContext(AppContext);

    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const [options, setOptions] = useState([]);

    useEffect(()=>{
        let unmounted = false;

        const apiRequest = app.createApiRequest(
            "GET",
            "/registration/plans"
        );
        apiRequest.setLoading(setLoading);
        apiRequest.run().then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    if (!unmounted) {
                        setOptions(data.filter(plan => plan.id!==4)
                            .map((plan)=>({key: plan.id, val: plan.id, caption: plan.name+" ("+plan.price+" Kč / "+plan.duration+" dní)"})));
                    }
                });
            }
        });

        return ()=>{
            unmounted = true;
        };
    }, []);

    const submit = (values, clear) => {
        setError(undefined);
        const apiRequest = app.createApiRequest(
            "POST",
            "/profile/personal-info/plan"
        );
        apiRequest.setLoading(setLoading);
        apiRequest.setBody({plan: Number(values.plan.value)});
        apiRequest.run().then((response)=>{
            if (response.ok) {
                setError({type: "success", msg: notice(1006)});
            } else
                setError(notice(7));
        });
    };

    return (
        <FormWrap submit={submit} error={error} setError={setError}>
            <SelectBox id="plan" caption="Plán" options={options} value={initPlan} required />
            <Button loading={loading}>změnit</Button>
        </FormWrap>
    );
}