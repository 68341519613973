import React, {useContext, useEffect, useState} from "react";
import CrossHeader from "../../parts/header/CrossHeader";
import NarrowLayout from "../../layout/NarrowLayout";
import {useHistory} from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSadTear, faThumbsDown, faThumbsUp} from "@fortawesome/free-solid-svg-icons";

import styles from "./AfterPaymentPage.module.scss";
import Alert from "../../forms/fields/Alert";
import BlankHref from "../../parts/content/BlankHref";
import {useQuery} from "../../props/hooks";
import AppContext from "../../props/appContext";
import Loading from "../../parts/content/Loading";
import {notice, PAYMENT_STATE} from "../../props/constants";

export default function AfterPaymentPage() {
    const app = useContext(AppContext);

    const history = useHistory();
    const params = useQuery();
    const [loading, setLoading] = useState();
    const [status, setStatus] = useState("");

    useEffect(()=>{
        if (params.get("id")) {
            const apiRequest = app.createApiRequest(
                "GET",
                `/payment/${params.get("id")}`
            );
            apiRequest.setLoading(setLoading);
            apiRequest.run().then(response => {
                if (response.ok) {
                    response.json().then(data=>setStatus(data.state));
                } else
                    app.messages.flashMessage("failed", notice(7));
            });
        }
    }, []);

    let content = {};
    if (status === PAYMENT_STATE.PAID)
        content = {
            icon: faThumbsUp,
            iconStyle: styles.success,
            alert: {
                type: "success",
                msg: "Platba proběhla úspěšně. Vaše členství bude aktivováno/prodlouženo."
            },
            paragraph:
                <React.Fragment>
                    Aktivace/prodloužení může nějakou chvíli trvat, pokud se tak nestane do hodiny, kontaktujte nás na <BlankHref href="mailto:info@fitmaliny.cz">info@fitmaliny.cz</BlankHref>.
                </React.Fragment>
        };
    else if (status === "")
        content = {
            icon: faSadTear,
            alert: {
                type: "info",
                msg: "Stav platby nebyl zjištěn."
            }
        };
    else
        content = {
            icon: faThumbsDown,
            iconStyle: styles.failed,
            alert: {
                type: "failed",
                msg: "Platbu jsme zatím nezaevidovali."
            },
            paragraph:
                <React.Fragment>
                    Po zaplacení přes GoPay může chvíli trvat, než se o tom dozví aplikace. Trvá-li tento stav déle než
                    hodinu, kontaktujte nás na <BlankHref href="mailto:info@fitmaliny.cz">info@fitmaliny.cz</BlankHref>.
                </React.Fragment>
        };

    return (
        <Loading show={loading}>
            <NarrowLayout>
                <CrossHeader animate={true} heading={"Platba"} crossClick={()=>history.push("/vip-zona")} />
                <div className={styles.iconWrap+(content.iconStyle ? " "+content.iconStyle : "")}>
                    <FontAwesomeIcon icon={content.icon} />
                </div>
                <Alert className={styles.alert}>
                    {content.alert}
                </Alert>
                <div className={styles.line} />
                {
                    content.paragraph &&
                        <p className={styles.paragraph}>
                            {content.paragraph}
                        </p>
                }
            </NarrowLayout>
        </Loading>
    );
}