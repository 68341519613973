import React, {useEffect, useState} from "react";
import ChooseVideoForm from "../../forms/ChooseVideoForm";

import styles from "./ChooseVideo.module.scss";

export default function ChooseVideo({onChange, value}) {
    const [code, setCode] = useState(value);

    useEffect(()=>{
        setCode(value);
    }, [value]);

    return (
        <React.Fragment>
            <ChooseVideoForm init={value} callback={(val)=>{setCode(val); onChange(val);}} />
            <iframe className={styles.iframe}
                    src={"https://www.youtube.com/embed/"+code}
                    frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen />
        </React.Fragment>
    );
}