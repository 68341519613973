import React, {useContext, useState} from "react";
import FormWrap from "./FormWrap";
import TextInput from "./fields/TextInput";
import Button from "../parts/content/Button";
import AppContext from "../props/appContext";
import PassCheckerInput from "./fields/PassCheckerInput";
import {notice} from "../props/constants";
import {convertValues} from "../props/helpers";

export default function ChangePassForm() {
    const app = useContext(AppContext);

    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const submit = (values, clear) => {
        setError(undefined);

        const apiRequest = app.createApiRequest(
            "PUT",
            "/profile/personal-info/change-pass"
        );

        apiRequest.setBody(convertValues(values));
        apiRequest.setLoading(setLoading);
        apiRequest.run().then(response => {
            if (response.ok) {
                setError({type: "success", msg: notice(5)});
                clear();
            } else if (response.status===403)
                setError(notice(6));
            else
                setError(notice(7));
        });
    };

    return (
        <FormWrap submit={submit} error={error} setError={setError}>
            <TextInput id="oldPass" caption="Aktuální heslo" type="password" clearAfterSubmit required />
            <PassCheckerInput id="newPass" clearAfterSubmit required />
            <Button loading={loading}>změnit</Button>
        </FormWrap>
    );
}