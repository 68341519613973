import React from "react";
import Modal from "./Modal";

export default function RecurrenceConditionsModal({show, close}) {
    return (
        <Modal
            show={show}
            close={close}
            heading={"Podmínky opakovaných plateb"}
        >
            <p>
                1. Důvod opakovaných plateb je vznik členství (platba za předplatné).
            </p>
            <p>
                2. Při zaplacení inicializační platby se vytvoří automatická platba, kdy následně probíhají automaticky platby 2 dny před koncem členství. Pro odmítnutí následující platby je tedy nutné ji zrušit dříve než 2 dny před koncem členství.
            </p>
            <p>
                3. Členství se prodlužuje automaticky po dobu maximálně 5 let. Poté je nutné znovu vytvořit inicializační platbu (po přihlášení v nastavení profilu, karta platby a fakturace, okamžitá platba).
            </p>
            <p>
                4. Částka je fixní, avšak její výši si může uživatel upravit sám tak, že změní plán členství po přihlášení do aplikace v nastavení profilu v kartě platby a fakturace, příští platba poté bude odpovídat nově zvolenému členství (rovněž i délka prodloužení členství).
            </p>
            <p>
                5. Opakované platby je možné zrušit po přihlášení do aplikace ve správě profilu na kartě platby a fakturace, kliknutím na tlačítko zrušit opakování plateb. V případě, že se nezdaří zrušit automatická platba, uživatel použije kontaktní email info@fitmaliny.cz a prodávající tento problém vyřeší.
            </p>
            <p>
                6. Prodávající si klade možnost změnit ceník jednotlivých členství, v takovém případě bude všem uživatelům, kteří by tím pádem platili jinou částku, zrušena automatická platba a uživatel si musí po přihlášení vybrat členství jiné (o této skutečnosti bude informován emailem). Výběr nového členství probíhá novou inicializační platbou stejně jako v případě vypršení platnosti automatických plateb ve 2. bodě těchto podmínek.
            </p>
            <p>
                7. Odesláním formuláře se zaškrtnutou položkou souhlasím s podmínkami opakovaných plateb vyjadřujete souhlas, že jste byli seznámeni s těmito podmínkami. Zároveň souhlasíte s uložením platebních údajů na straně platební brány GoPay. S údaji Vaší platební karty nakládá GoPay podle mezinárodního bezpečnostního standartu PCI-DSS Level 1 (jedná se o nejvyšší úroveň datové bezpečnosti ve finančním sektoru).
            </p>
            <p>
                8. Tyto Podmínky nabývají účinnosti dne 20.02.2021
            </p>
        </Modal>
    );
}