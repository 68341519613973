import React from 'react';

import styles from "./MenuItem.module.scss"

export default function MenuItem({children, className, special, withoutBurger, func, hide}) {

    if (hide) return null;

    return (
        <div onClick={func} className={styles.menuItem+(className ? " "+className : "")+(special ? " "+styles.special : "")+(withoutBurger ? " "+styles.withoutBurger : "")}>
            {children}
        </div>
    );
}