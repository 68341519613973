import React, {useContext, useEffect, useState} from "react";
import DataGrid from "../../parts/content/table/DataGrid";
import AddVoucherForm from "../../forms/AddVoucherForm";
import AppContext from "../../props/appContext";
import {notice} from "../../props/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

export default function VouchersPage() {
    const app = useContext(AppContext);
    const [vouchers, setVouchers] = useState({data:[]});
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const load = () => {
        const apiRequest = app.createApiRequest(
            "GET",
            "/admin/voucher/"+page
        );
        apiRequest.setLoading(setLoading);
        apiRequest.run().then(response => {
            if (response.ok)
                response.json().then(setVouchers);
            else
                app.messages.flashMessage("failed", notice(7));
        });
    };

    useEffect(()=>{
        load();
    }, [page]);

    const makeInvalid = ({id}) => {
        const conf = window.confirm(notice(10));
        if (conf) {
            const apiRequest = app.createApiRequest(
                "DELETE",
                "/admin/voucher/" + id
            );
            apiRequest.setLoading(setLoading);
            apiRequest.run().then(response => {
                if (response.ok) {
                    app.messages.flashMessage("success", notice(1005));
                    load();
                } else
                    app.messages.flashMessage("failed", notice(7));
            });
        }
    };

    return (
        <React.Fragment>
            <h3>Přidat</h3>
            <AddVoucherForm refresh={load} />
            <h3>Přehled</h3>
            <DataGrid
                header={[
                    {type: "text", id:"code", caption: "Kód"},
                    {type: "datetime", id:"valid_until", caption: "Platné do"},
                    {type: "boolean", id:"valid", caption: "Validní"},
                    {type: "text", id:"duration", caption: "Doba [dny]"},
                    {type: "boolean", id:"one_use", caption: "Na jedno použití"},
                    {type: "buttons", components: [
                            {
                                onClick: makeInvalid,
                                caption: "znevalidnit",
                                frontIcon: <FontAwesomeIcon icon={faTimes} />,
                                showCondition: (item)=>item.valid
                            }
                    ]}
                ]}
                showHeader
                data={vouchers.data}
                pagination={{
                    page: vouchers.page,
                    lastPage: vouchers.lastPage,
                    setPage: setPage
                }}
                loading={loading}
            />
        </React.Fragment>
    );
}