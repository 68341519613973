import React, {useEffect, useState} from "react";
import DateInput from "./DateInput";
import TimeInput from "./TimeInput";

import styles from "./DateTimeInput.module.scss";
import baseInputStyles from "../../forms/fields/BaseInput.module.scss";
import {leadingZero} from "../../props/helpers";

export default function DateTimeInput({caption, required, onChange, value, maxDate}) {
    const [dateTime, setDateTime] = useState(value ? value : new Date());

    const setTime = (value) => {
        const split = value.split(":");
        setDateTime((oldDateTime)=>new Date(oldDateTime.setHours(Number(split[0]), Number(split[1]))));
    };

    const setDay = (value) => {
        setDateTime((oldDateTime)=>new Date(oldDateTime.setDate(value.getDate())))
    };

    useEffect(()=>{
        onChange(dateTime);
    }, [dateTime]);

    const time = `${leadingZero(dateTime.getHours())}:${leadingZero(dateTime.getMinutes())}`

    return (
        <div className={baseInputStyles.inputGroup}>
            {
                caption &&
                <div className={baseInputStyles.caption}>
                    {caption}{required ? <span>*</span> : ""}
                </div>
            }
            <div className={styles.wrap}>
                <DateInput
                    disableInline
                    onChange={setDay}
                    selected={dateTime}
                    maxDate={maxDate}
                />
                <TimeInput
                    onChange={setTime}
                    className={styles.timeInput}
                    value={time}
                />
            </div>
        </div>
    )
}