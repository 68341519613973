import React from "react";

import styles from "./InputWrap.module.scss";

export default function InputWrap({children, className, row}) {
    return (
        <div className={(row ? styles.row : "")+(className ? " "+className : "")}>
            {children}
        </div>
    );
}