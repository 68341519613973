import React, {useContext} from "react";
import {Route, Switch} from "react-router-dom";
import VIPPage from "../page/VIPmain/VIPPage";
import {useRouteMatch} from "react-router-dom";
import AppContext from "../props/appContext";
import Copyright from "../layout/Copyright";

import styles from "./VIPRouter.module.scss";
import ProfilePage from "../page/user/ProfilePage";
import RegistrationPage from "../page/registration/RegistrationPage";
import ArticlesPage from "../page/articles/ArticlesPage";
import AddArticlePage from "../page/articles/AddArticlePage";
import ArticlePage from "../page/articles/ArticlePage";
import QuestionPage from "../page/questions/QuestionPage";
import UserDetailPage from "../page/user/UserDetailPage";

export default function () {
    let match = useRouteMatch();
    const app = useContext(AppContext);

    return (
        <section className={styles.wrap}>
            <section className={styles.content}>
                <Switch>
                    <Route path={`${match.path}/registrace`}>
                        <RegistrationPage />
                    </Route>
                    {
                        app.isLoggedIn &&
                            <Route path={`${match.path}/profil`}>
                                <ProfilePage />
                            </Route>
                    }
                    {
                        app.isLoggedIn && app.premium.isActive &&
                        <Route path={`${match.path}/poradna`}>
                            <QuestionPage />
                        </Route>
                    }
                    {
                        app.isLoggedIn && app.premium.isActive &&
                            <Route path={`${match.path}/clanek/:id`}>
                                <ArticlePage />
                            </Route>
                    }
                    {
                        app.isLoggedIn && app.user.isAdmin &&
                            <Route path={`${match.path}/clanky/pridat`}>
                                <AddArticlePage />
                            </Route>
                    }
                    {
                        app.isLoggedIn && app.user.isAdmin &&
                        <Route path={`${match.path}/clanky/upravit/:id`}>
                            <AddArticlePage />
                        </Route>
                    }
                    {
                        app.isLoggedIn && app.user.isAdmin &&
                        <Route path={`${match.path}/uzivatel/:id`}>
                            <UserDetailPage />
                        </Route>
                    }
                    {
                        app.isLoggedIn && app.premium.isActive &&
                                <Route path={`${match.path}/clanky`}>
                                    <ArticlesPage />
                                </Route>
                    }
                    <Route path={match.path}>
                        {
                            app.isLoggedIn
                                ? (app.premium.isActive ? <ArticlesPage /> : <ProfilePage />)
                                : <VIPPage />
                        }
                    </Route>
                </Switch>
            </section>
            {
                app.isLoggedIn &&
                <Copyright className={styles.copyright} />
            }
        </section>
    );
}