import React, {useContext, useEffect, useRef, useState} from "react";
import FileChooser from "../../forms/fields/FileChooser";
import AppContext from "../../props/appContext";
import Image from "./Image";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

import styles from "./UploadPhotoGallery.module.scss";
import ProgressBar from "../../forms/fields/ProgressBar";
import TextInput from "../../forms/fields/TextInput";
import {notice} from "../../props/constants";
import Loading from "./Loading";

export default function UploadPhotoGallery({onChange, value}) {

    const app = useContext(AppContext);

    const [images, setImages] = useState([]);
    const [progress, setProgress] = useState(0);
    const [progressShow, setProgressShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const idGenerator = useRef(0);

    useEffect(()=>{
        onChange(images);
    }, [images]);

    useEffect(()=>{
        let tmp = [...value];
        for (let i=0; i<value.length; i++) {
            tmp[i].frontId = idGenerator.current++;
        }
        setImages(tmp);
    }, [value]);

    const uploadImages = (newImages) => {
        const apiRequest = app.createApiRequest(
            "POST",
            "/admin/article/images"
        );

        setProgress(0);
        setProgressShow(true);
        apiRequest.setProgress(setProgress);

        apiRequest.partsUpload(newImages).then((xhrs)=>{
            let data = [];

            for (let xhr of xhrs) {
                if (xhr.status===200) {
                    const tmpData = JSON.parse(xhr.response);
                    for (let image of tmpData) {
                        image.frontId = idGenerator.current++;
                        data.push(image);
                    }
                }
            }

            setProgressShow(false);
            setImages((old)=>([...old, ...data]));
        });
    };

    const removeImage = (imageId, frontId) => {
        const conf = window.confirm(notice(10));
        if (conf) {
            const apiRequest = app.createApiRequest(
                "DELETE",
                `/admin/article/image/${imageId}`
            );

            apiRequest.setLoading(setLoading);

            apiRequest.run().then((response)=>{
                if (response.ok) {
                    setImages((oldImages)=>{
                       return oldImages.filter((image)=>image.frontId!==frontId);
                    });
                } else
                    app.messages.addFlashMessage("failed", notice(11));
            });
        }
    };

    const changeDescription = (frontId, value) => {
        setImages((oldImages)=>{
           return oldImages.map((image)=>{
               if (image.frontId===frontId)
                   return {...image, description: value};
               return image;
           });
        });
    };

    return (
        <Loading show={loading}>
            <div>
                <FileChooser
                    ref={(r)=>{}}
                    caption="Nahraj obrázky"
                    id="image"
                    accept="image/png,image/jpeg,image/gif"
                    validate="fileSize"
                    multiple
                    valueCallback={(images, invalid)=>{
                        if (!invalid) uploadImages(images);
                    }}
                />
                <ProgressBar show={progressShow} progress={progress} />
                <div className={styles.imagesWrap}>
                    {
                        images.map(
                            (image)=>
                                <div className={styles.imageWrap} key={image.frontId}>
                                    <div className={styles.header} onClick={()=>removeImage(image.id, image.frontId)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </div>
                                    <Image iri={"/premium/article/image/id/"}
                                           id={image.fitId} alt={image.description}
                                           imageParams={{className: styles.image}}
                                           wrapClassName={styles.imageSrcWrap}
                                    />
                                    <div className={styles.footer}>
                                        <TextInput ref={(r)=>{}}
                                                   caption={"Popis"}
                                                   id="description"
                                                   valueCallback={(value)=>changeDescription(image.frontId, value)}
                                                   value={image.description} />
                                    </div>
                                </div>
                        )
                    }
                </div>
            </div>
        </Loading>
    );
}