import React, {useEffect, useRef, useState} from "react";
import {useContext} from "react";
import AppContext from "../../props/appContext";
import Modal from "./Modal";
import DataGrid from "../content/table/DataGrid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandPointer, faLightbulb, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import SearchBox from "../content/SearchBox";
import {notice} from "../../props/constants";

import styles from "./IngredientsManageModal.module.scss";
import Pagination from "../content/Pagination";
import IngredientsParams from "./IngredientsParams";

export default function IngredientsManageModal({show, setShow, id, setId}) {
    const app = useContext(AppContext);

    const [value, setValue] = useState("");
    const timer = useRef(-1);

    const [searchData, setSearchData] = useState({data: []});
    const [currentData, setCurrentData] = useState({data: []});
    const [searchPage, setSearchPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchLoading, setSearchLoading] = useState(false);
    const [currentLoading, setCurrentLoading] = useState(false);

    useEffect(()=>{
        setCurrentData({data:[]});
    }, [id]);

    const searchRequest = (value) => {

        const apiRequest = app.createApiRequest(
            "GET",
            `/admin/article/ingredients/${searchPage}?search=${value}&itemsPerPage=5`
        );
        apiRequest.setLoading(setSearchLoading);
        apiRequest.run().then(response => {
            if (response.ok) {
                response.json().then(setSearchData);
            } else {
                app.messages.flashMessage("failed", notice(7));
            }
        });
    };

    const makeSearch = (val) => {
        if (searchPage!==1)
            setSearchPage(1);
        else
            searchRequest(val);
    };

    const changeValue = (e) => {
        const val = e.target.value;
        setValue(val);
        if (timer.current!==-1)
            clearTimeout(timer.current);
        timer.current = setTimeout(()=>makeSearch(val), 500);
    };

    const forceChangeValue = (value) => {
        if (timer.current!==-1)
            clearTimeout(timer.current);
        makeSearch(value);
    };

    useEffect(()=>{
        if (show)
            searchRequest(value);
    }, [show, searchPage])


    const chooseIngredient = (ingredientId) => {
        const apiRequest = app.createApiRequest(
            "POST",
            `/admin/article/choose-ingredient`
        );
        apiRequest.setLoading(setSearchLoading);
        let body = {id: ingredientId};
        if (id) body.articleId = id;
        apiRequest.setBody(body);
        apiRequest.run().then(response => {
            if (response.ok) {
                response.json().then(data => {
                    if (!id) setId(data.articleId);
                    else getCurrentIngredients();
                });
            } else if (response.status === 409)
                app.messages.flashMessage("info", notice(16));
            else
                app.messages.flashMessage("failed", notice(7));
        });
    };

    const getCurrentIngredients = () => {
        const apiRequest = app.createApiRequest(
            "GET",
            `/admin/article/choose-ingredients/${id}/${currentPage}`
        );
        apiRequest.setLoading(setCurrentLoading);
        apiRequest.run().then(response => {
            if (response.ok) {
                response.json().then(setCurrentData);
            } else
                app.messages.flashMessage("failed", notice(7));
        });
    };

    useEffect(()=>{
        if (show && id)
            getCurrentIngredients();
    }, [show, currentPage, id]);

    const removeCurrentIngredient = (ingredientId) => {
        const conf = window.confirm(notice(10));

        if (conf) {
            const apiRequest = app.createApiRequest(
                "DELETE",
                `/admin/article/choose-ingredient`
            );
            apiRequest.setLoading(setCurrentLoading);
            apiRequest.setBody({
                articleId: id,
                id: ingredientId
            });
            apiRequest.run().then(response => {
                if (response.ok) {
                    getCurrentIngredients();
                } else
                    app.messages.flashMessage("failed", notice(7));
            });
        }
    };

    return (
        <Modal
            heading="Ingredience"
            show={show}
            close={()=>setShow(false)}
        >
            <h3>Aktuální</h3>
            {
                currentData.data.length!==0 &&
                    currentData.data.map(data =>
                        <IngredientsParams
                            key={data.id}
                            id={data.id}
                            articleId={id}
                            ingredientName={data.ingredientName}
                            initAmount={data.amount}
                            initUnit={data.unit}
                            initAAmount={data.alternative_amount}
                            initAUnit={data.alternative_unit}
                            loading={currentLoading}
                            setLoading={setCurrentLoading}
                            remove={removeCurrentIngredient}
                        />
                    )
            }
            {
                currentData.data.length!==0 &&
                    <Pagination
                        page={currentData.page}
                        lastPage={currentData.lastPage}
                        setPage={setCurrentPage}
                    />
            }
            <h3 className={styles.addHeading}>Přidat</h3>
            <SearchBox
                onChange={changeValue}
                forceChange={forceChangeValue}
                value={value}
            />
            <DataGrid
                header={[
                    {type: "text", id: "name"},
                    {type: "buttons", components: [
                        {
                            onClick: ({id})=>chooseIngredient(id),
                            caption: "vybrat",
                            frontIcon: <FontAwesomeIcon icon={faHandPointer} />
                        }
                    ]}
                ]}

                pagination={{
                    page: searchData.page,
                    lastPage: searchData.lastPage,
                    setPage: setSearchPage
                }}

                loading={searchLoading}
                data={searchData.data}
            />
        </Modal>
    );
}