import React, {forwardRef} from "react";
import BaseInput from "./BaseInput";

import styles from "./CheckboxInput.module.scss";

export default forwardRef((props, ref) => {

    return (
        <BaseInput ref={ref} {...props} labelClassName={styles.label} captionClassName={styles.caption}>
            <input
                type="checkbox"
                className={styles.input}
            />
            <span className={styles.fake} />
        </BaseInput>
    );
});