import React, {useContext} from "react";

import styles from "./Copyright.module.scss";
import DefaultLayout from "./DefaultLayout";
import BlankHref from "../parts/content/BlankHref";
import packageJson from "../../package.json";
import AppContext from "../props/appContext";

export default function Copyright({className}) {
    const app = useContext(AppContext);

    return (
        <section className={styles.section+(className ? " "+className : "")}>
            <DefaultLayout>
                v{packageJson.version} &copy; 2021 FitMaliny | website development <BlankHref href="http://www.datasession.cz">
                www.datasession.cz</BlankHref> | design <BlankHref
                href="http://www.mediabay.cz">www.mediabay.cz</BlankHref>
                <div className={styles.terms}>
                    <span className="likeLink" onClick={()=>app.openTermsAndConditions()}>Obchodní podmínky</span><br />
                    <span className="likeLink" onClick={()=>app.openRecurrenceConditions()}>Podmínky opakovaných plateb</span>
                </div>
            </DefaultLayout>
        </section>
    );
}