import React from 'react';

import styles from "./TitleSubtitle.module.scss";

export default function TitleSubtitle(props) {
    return (
        <div {...props}>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.subtitle}>{props.subtitle}</div>
        </div>
    );
}