import React from "react";

import {animated} from "react-spring";

import styles from "./Review.module.scss";

export default class Review extends React.Component {

    render() {
        const {style, name, children, innerRef, image} = this.props;

        return (
            <animated.div className={styles.item} style={style} ref={innerRef}>
                <img className={styles.image} src={image} alt=""/>
                <div className={styles.name}>{name}</div>
                <div className={styles.content}>{children}</div>
            </animated.div>
        );
    }
}