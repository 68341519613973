import React, { useContext, useEffect, useState } from "react";
import Header from "../../parts/header/Header";
import DefaultLayout from "../../layout/DefaultLayout";
import Heading from "../../parts/content/Heading";
import AskAQuestionForm from "../../forms/AskAQuestionForm";
import AppContext from "../../props/appContext";
import { notice} from "../../props/constants";
import DataGrid from "../../parts/content/table/DataGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb, faTrash } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../parts/modal/Modal";

export default function QuestionPage() {
    const app = useContext(AppContext);
    
    const [questions, setQuestions] = useState({data: []});
    const [gridLoading, setGridLoading] = useState(false);

    const [modalContent, setModalContent] = useState(undefined);

    const [page, setPage] = useState(1);
    
    useEffect(()=>{
        if (app.user.isAdmin) {
            const apiRequest = app.createApiRequest(
                "GET",
                `/admin/question/${page}`
            );

            apiRequest.setLoading(setGridLoading);

            apiRequest.run().then(response => {
                if (response.ok) {
                    response.json().then(data => setQuestions(data));
                } else {
                    app.messages.flashMessage("failed", notice(7));
                }
            });
        }
    }, [page]);

    const removeFromQuestions = (id) => {
        setQuestions(oldQuestions => ({
            ...oldQuestions,
            data: oldQuestions.data.filter(question=>question.id!==id)
        }));
    };

    const answered = ({event, id}) => {
        event.stopPropagation();
        const conf = window.confirm(notice(15));
        if (conf) {
            const apiRequest = app.createApiRequest(
                "POST",
                `/admin/question/answered/${id}`
            );

            apiRequest.setLoading(setGridLoading);
    
            apiRequest.run().then(response => {
                if (response.ok) {
                    app.messages.flashMessage("success", notice(1004));
                    removeFromQuestions(id);
                } else {
                    app.messages.flashMessage("failed", notice(7));
                }
            });
        }
    };

    const remove = ({event, id}) => {
        event.stopPropagation();
        const conf = window.confirm(notice(10));
        if (conf) {
            const apiRequest = app.createApiRequest(
                "DELETE",
                `/admin/question/${id}`
            );

            apiRequest.setLoading(setGridLoading);
    
            apiRequest.run().then(response => {
                if (response.ok) {
                    app.messages.flashMessage("success", notice(1005));
                    removeFromQuestions(id);
                } else {
                    app.messages.flashMessage("failed", notice(7));
                }
            });
        }
    };

    const onClickRow = ({event, item}) => {
        setModalContent({
            heading: item.subject,
            content: item.content,
            username: item.user.username,
            email: item.user.email
        });
    };

    return (
        <React.Fragment>
            <Header narrow />
            <DefaultLayout>
                <Heading>Poradna</Heading>
                {
                    !app.user.isAdmin &&
                        <React.Fragment>
                            <p style={{maxWidth: "none"}}>
                                Neváhejte se na nás obrátit s jakýmkoli dotazem, rády zodpovíme Vaše otázky v pravidelném <strong>nedělním vydání Q&A</strong>.
                            </p>
                            <AskAQuestionForm />
                        </React.Fragment>
                }
                {
                    app.user.isAdmin &&
                        <React.Fragment>
                            <DataGrid 
                                header={[
                                    {type: "text", id: "subject", truncate: 30},
                                    {type: "datetime", id: "created_at"},
                                    {type: "buttons", components: [
                                        {onClick: answered, caption: "zodpovězeno", frontIcon: <FontAwesomeIcon icon={faLightbulb} />},
                                        {onClick: remove, caption: "smazat", frontIcon: <FontAwesomeIcon icon={faTrash} />}
                                    ]}
                                ]}
                                loading={gridLoading}
                                data={questions.data}
                                pagination={{
                                    page: questions.page,
                                    lastPage: questions.lastPage,
                                    setPage: setPage
                                }}
                                onClickRow={onClickRow}
                                rowHover
                            />
                            <Modal
                                show={!!modalContent}
                                heading={modalContent ? modalContent.heading : ""}
                                close={()=>setModalContent(undefined)}
                            >
                                {
                                    modalContent &&
                                        <React.Fragment>
                                            <h3>{modalContent.username}:</h3>
                                            <p>
                                                {modalContent.content}
                                            </p>
                                        </React.Fragment>
                                }
                            </Modal>
                        </React.Fragment>
                }
            </DefaultLayout>
        </React.Fragment>
    );
}