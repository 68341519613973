import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import DataGrid from "./DataGrid";
import {notice} from "../../../props/constants";
import AppContext from "../../../props/appContext";
import {translateActivity, translateMethod} from "../../../props/helpers";

export default forwardRef((props, ref) => {
    const app = useContext(AppContext);

    const {admin, id} = props;

    const [weights, setWeights] = useState({data:[]});
    const [page, setPage] = useState(1);
    const [gridLoading, setGridLoading] = useState(false);

    const removeMeasurement = ({id}) => {
        const conf = window.confirm(notice(10));
        if (conf) {
            const apiRequest = admin
                ?
                    app.createApiRequest(
                        "DELETE",
                        "/admin/calories/measure/" + id
                    )
                :
                    app.createApiRequest(
                        "DELETE",
                        "/premium/calories/measure/" + id
                    );
            apiRequest.setLoading(setGridLoading);
            apiRequest.run().then(response => {
                if (response.ok) {
                    app.messages.flashMessage("success", notice(1005));
                    getMeasurements();
                } else
                    app.messages.flashMessage("failed", notice(7));
            });
        }
    };

    const getMeasurements = () => {
        const apiRequest = admin
            ? app.createApiRequest(
                "GET",
                `/admin/calories/${id}/${page}`
            )
            : app.createApiRequest(
                "GET",
                "/premium/calories/measure/"+page
            )
        apiRequest.setLoading(setGridLoading);
        apiRequest.run().then(response => {
            if (response.ok) {
                response.json().then(setWeights);
            } else
                app.messages.flashMessage("failed", notice(7));
        });
    };

    useImperativeHandle(
        ref,
        ()=> ({
            refresh() {
                getMeasurements();
            }
        })
    );

    useEffect(()=>{
        getMeasurements();
    }, [page]);

    const header = admin
        ? [
            {type: "float", id: "weight", append: "kg"},
            {type: "datetime", id: "recorded_at"},
            {type: "text", id: "calories", append: "kcal"},
            {type: "range", id: "proteins", append: "g", caption: "Proteiny"},
            {type: "range", id: "carbohydrates", append: "g", caption: "Sacharidy"},
            {type: "range", id: "fats", append: "g", caption: "Tuky"},
            {type: "text", id: "height", append: "cm", caption: "Výška"},
            {type: "text", id: "activity", caption: "Aktivita", translateFce: translateActivity},
            {type: "text", id: "method", caption: "Cíl", translateFce: translateMethod},
            {type: "buttons", components: [
                    {onClick: removeMeasurement, caption: "smazat", frontIcon: <FontAwesomeIcon icon={faTrash} />}
                ]}
        ]
        : [
            {type: "float", id: "weight", append: "kg"},
            {type: "datetime", id: "recorded_at"},
            {type: "buttons", components: [
                    {onClick: removeMeasurement, caption: "smazat", frontIcon: <FontAwesomeIcon icon={faTrash} />}
                ]}
        ]

    return (
        <DataGrid
            header={header}
            responsive
            showHeader
            loading={gridLoading}

            pagination={{
                page: weights.page,
                lastPage: weights.lastPage,
                setPage: setPage
            }}

            data={weights.data}
        />
    );
});