import React, {useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState} from "react";
import CrossHeader from "../../parts/header/CrossHeader";
import NarrowLayout from "../../layout/NarrowLayout";
import {useParams, useHistory, useLocation} from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAlignLeft, faImage, faPlus, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import {faYoutube} from "@fortawesome/free-brands-svg-icons";

import styles from "./AddArticlePage.module.scss";
import ArticleParamsForm from "../../forms/ArticleParamsForm";
import ArticleCreationBlock from "./ArticleCreationBlock";
import AppContext from "../../props/appContext";
import UnpublishedBlock from "./UnpublishedBlock";
import Loading from "../../parts/content/Loading";
import Button from "../../parts/content/Button";
import {notice} from "../../props/constants";

const initParams = {
    name: "",
    published: false,
    category: 1,
    image: undefined,
    macros: {}
};

let ids = 0;

function addMacrosParam(key, data, macros) {
    if (data.macros[key])
        macros[key] = data.macros[key];
    return macros;
}

export default function AddArticlePage() {
    const app = useContext(AppContext);
    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    let timer = useRef(null);
    let abortController = useRef(new window.AbortController());
    const formRef = useRef();
    const notSave = useRef(true);

    const [id, setId] = useState(null);
    const [loading, setLoading] = useState(false);

    const [saveLoading, setSaveLoading] = useState(false);
    const [saved, setSaved] = useState(false);

    const [values, setValues] = useState({
        blocks: [],
        params: initParams
    });

    const [prevLink, setPrevLink] = useState(`/vip-zona/`);

    const save = () => {
        const apiRequest = app.createApiRequest(
            "POST",
            "/admin/article/save"
        );

        let body = {
            name: values.params.name,
            published: values.params.published,
            category: values.params.category,
            values: values.blocks
        };

        if (id !== null) body.id = id;

        if (Number(values.params.category)===1)
            body.macros = values.params.macros;

        apiRequest.setBody(body);
        apiRequest.setLoading(setSaveLoading);

        if (timer.current)
            clearTimeout(timer.current);
        if (!abortController.current.signal.aborted) {
            abortController.current.abort();
            abortController.current = new window.AbortController();
        }

        apiRequest.setController(abortController);

        setSaved(false);
        setSaveLoading(true);
        timer.current = setTimeout(
            ()=>apiRequest.run().then((response) => {
                if (response.ok) {
                    response.json().then(data => {
                        setId(data.id);
                    });
                    setSaved(true);
                }
            }),
            1000
        );
    };

    useEffect(()=>{
        if (notSave.current) {
            notSave.current = false;
            return;
        }

        save();
    }, [values]);

    useEffect(()=>{
        if (location.state && location.state.prevLink) {
            setPrevLink(location.state.prevLink);
        }
    }, [location.state]);

    const loadValues = (futureId) => {
        const apiRequest = app.createApiRequest(
            "GET",
            "/premium/article/"+futureId
        );
        apiRequest.setLoading(setLoading);

        apiRequest.run().then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    notSave.current = true;
                    setId(futureId);
                    const blocks = [];
                    for (let value of data.values) {
                        blocks.push({
                          ...value,
                          initContent: value.content
                        });
                    }
                    let macros = {};
                    if (data.category===1 && data.macros) {
                        macros = addMacrosParam("calories", data, macros);
                        macros = addMacrosParam("proteins", data, macros);
                        macros = addMacrosParam("carbohydrates", data, macros);
                        macros = addMacrosParam("fats", data, macros);
                    }
                    setValues({
                        blocks: blocks,
                        params: {
                            name: data.name,
                            published: data.published,
                            category: data.category,
                            image: data.fit_image_id,
                            macros: macros
                        }
                    });
                });
            }
        });
    };

    const addBlock = (type) => {
        setValues((oldValues)=>({
            blocks: [...oldValues.blocks, {
                type: type,
                key: ids++,
                content: type==="images" ? [] : "",
                initContent: type==="images" ? [] : ""
            }],
            params: oldValues.params
        }));
    };

    useEffect(()=>{
        if (params.id) {
            if (params.id!==id)
                loadValues(params.id);
        } else {
            clear();
        }
    }, [params]);

    const clear = () => {
        notSave.current = true;
        setId(null);
        setSaved(false);
        setValues({
            blocks: [],
            params: initParams
        });
        formRef.current.clear();
    };

    const removeArticle = () => {
        const conf = window.confirm(notice(10));
        if (conf) {
            const apiRequest = app.createApiRequest(
                "DELETE",
                "/admin/article/"+id
            );
            apiRequest.setLoading(setLoading);
            apiRequest.run().then((response) => {
                if (response.ok) {
                    history.replace(`/vip-zona/clanky/pridat`);
                } else
                    app.messages.flashMessage("failed", notice(11));
            });
        }
    };

    const changeAndSaveParam = (key, val) => {
        setValues((oldValues)=>{
            if (oldValues.params[key]===val) return oldValues;
            return {
                blocks: oldValues.blocks,
                params: {...oldValues.params, [key]:val}
            };
        });
    };

    const macrosChange = (key, val) => {
        setValues((oldValues)=>({...oldValues, params: {
                ...oldValues.params,
                macros: {
                    ...oldValues.params.macros,
                    [key]: val
                }
        }}));
    };

    const orders = useMemo(()=>{
        let result = [];
        for (let i=1; i<=values.blocks.length; i++)
            result.push({
                key: i,
                val: i,
                caption: `${i}.`
            });
        return result;
    }, [values]);

    const changeOrder = (key, order) => {
        setValues((oldValues)=>{
            order = Number(order);
            const newBlocks = [];
            let found = false;
            for (let i=0; i<oldValues.blocks.length; i++) {
                if (oldValues.blocks[i].key === key) {
                    found = true;
                    newBlocks[order-1] = oldValues.blocks[i];
                } else if (i>=order-1 && !found) {
                    newBlocks[i+1] = oldValues.blocks[i];
                } else newBlocks[i] = oldValues.blocks[i];
            }
            return {
                params: oldValues.params,
                blocks: newBlocks
            };
        });
    };

    return (
        <Loading show={loading}>
            <NarrowLayout>
                <CrossHeader animate={true} heading={(params.id ? "Upravit" : "Přidat")+" článek"} crossClick={()=>history.push(prevLink)} />
                {
                    (params.id || id!==null) &&
                        <Button 
                            onClick={()=>history.push("/vip-zona/clanky/pridat")}
                            frontIcon={<FontAwesomeIcon icon={faPlus}/>} 
                            small
                        >
                            Vytvořit nový
                        </Button>
                }

                <UnpublishedBlock id={id} />

                <div className={styles.saveLoading+(saveLoading ? " "+styles.show : "")}>
                    <FontAwesomeIcon icon={faSave} />
                </div>

                <h2>Parametry</h2>
                <ArticleParamsForm
                    ref={formRef}

                    id={id}
                    setId={setId}

                    nameChanged={(val)=>changeAndSaveParam("name", val)}
                    publishedChange={(val)=>changeAndSaveParam("published", val)}
                    categoryChange={(val)=>changeAndSaveParam("category", val)}
                    imageChange={(val)=>{
                        notSave.current = true;
                        changeAndSaveParam("image", val);
                    }}
                    macrosChange={macrosChange}

                    name={values.params.name}
                    published={values.params.published}
                    category={values.params.category}
                    image={values.params.image}
                    macros={values.params.macros}
                />

                <h2>Obsah</h2>
                <div className={styles.blocksWrap}>
                    {values.blocks.map((block, i)=>
                        <ArticleCreationBlock
                            key={block.key}

                            remove={() => setValues((oldValues) => ({
                                    params: oldValues.params,
                                    blocks: oldValues.blocks.filter((b) => block.key !== b.key)
                                }))}

                            type={block.type}

                            callback={(val) => setValues((oldValues)=>({
                                params: oldValues.params,
                                blocks: oldValues.blocks.map((b)=>{
                                    if (b.key===block.key)
                                        b.content = val;
                                    return b;
                                })
                            }))}

                            orders={orders}
                            order={i+1}
                            setOrder={(order)=>changeOrder(block.key, order)}

                            value={block.initContent}

                            changedValue={block.content}
                        />
                    )}
                </div>
                <div className={styles.chooseBlock}>
                    <div title={"Přidat text"} onClick={()=>addBlock("text")}>
                        <FontAwesomeIcon icon={faAlignLeft} />
                    </div>
                    <div title={"Přidat fotogalerii"} onClick={()=>addBlock("images")}>
                        <FontAwesomeIcon icon={faImage} />
                    </div>
                    <div title={"Přidat youtube video"} onClick={()=>addBlock("youtube_video")}>
                        <FontAwesomeIcon icon={faYoutube} />
                    </div>
                </div>

                <div className={styles.status}>
                    <strong>Stav: </strong> <em>{saveLoading ? "ukládám" : (saved ? "uloženo" : "neuloženo")}</em>
                    {
                        id!==null && <React.Fragment>&nbsp;<Button onClick={removeArticle} frontIcon={<FontAwesomeIcon icon={faTrash} />} small>smazat</Button></React.Fragment>
                    }
                </div>
            </NarrowLayout>
        </Loading>
    )
}