import React from "react";

import styles from "./Reviews.module.scss";

import {animated} from "react-spring";

import {ReactComponent as LeftTop} from "../../files/images/review-left-top.svg";
import {ReactComponent as CenterTop} from "../../files/images/review-center-top.svg";
import {ReactComponent as RightCenter} from "../../files/images/review-right-center.svg";
import {ReactComponent as CenterBottom} from "../../files/images/review-center-bottom.svg";
import {ReactComponent as LeftBottom} from "../../files/images/review-left-bottom.svg";

import Measure from "react-measure";

import review from "./Review.module.scss";
import variables from "../../variables.module.scss";
import AnimationPack from "./AnimationPack";

export default class Reviews extends React.Component {

    static reviewWidth = 300;
    static reviewHeight = 470;

    static commonStyle = {
        position: "absolute",
        zIndex: -1
    };

    constructor(props) {
        super(props);

        this.props = props;
        this.innerRef = null;

        this.state = {
            width: 0,
            height: 0,
            center: true,
            oneByRow: false,

            leftTop: false
        };
    }

    resize = (props) => {

        let center = true;
        if (props.client.width<=Number(variables.smallWidth)) {
            center = false;
        }

        let oneByRow = false;
        if (props.client.width<=(Number(variables.smallWidth)-100)) {
            oneByRow = true;
        }

        Reviews.reviewWidth = 300;
        if (props.client.width<=Number(variables.verySmall)) {
            Reviews.reviewWidth = props.client.width;
        }

        this.setState({
            width: props.client.width,
            height: props.client.height,
            center: center,
            oneByRow: oneByRow
        });
    };



    render() {
        const columns = this.state.oneByRow ? 1 : 2;
        const rows = Math.ceil(this.props.children.length/columns);
        const rowSpace = (this.state.width-(Reviews.reviewWidth*columns))/(columns+1);

        let raspberriesAddition = "";
        for (let i=0; i<rows; i++) {
            if (i===0) raspberriesAddition += review.raspberryOutSize;
            else raspberriesAddition += ` + ${review.raspberryOutSize}`;
        }

        const height = `calc(${(Reviews.reviewHeight*rows)+(Number(variables.largeSpace)*3)}px + ${raspberriesAddition})`;

        return (
            <Measure
                client
                innerRef={r => this.innerRef = r}
                onResize={this.resize}
            >
                {
                    ({ measureRef }) => (
                        <section ref={measureRef} className={styles.pack} style={{height: height}} {...this.props}>
                            {React.Children.map(this.props.children, (child, i) => {

                                let x=rowSpace, y=0;

                                const row = Math.floor(i/columns);
                                let innerCalc = review.raspberryOutSize;
                                for (let i=0; i<row; i++)
                                    innerCalc+=` + ${review.raspberryOutSize}`;

                                y = `calc(${Reviews.reviewHeight*row}px + ${innerCalc})`;

                                if (i===0 || i===2 || this.state.oneByRow) { // first column element
                                    x += 0;
                                } else { // second column element
                                    x += Reviews.reviewWidth+rowSpace;
                                }

                                return (
                                    <AnimationPack delay={i===0 || i===1 ? i*200 : (i===3 ? 200*2 : 200*3)}>
                                        {
                                            React.cloneElement(child, {
                                                style: {
                                                    width: Reviews.reviewWidth,
                                                    height: Reviews.reviewHeight,
                                                    top: y,
                                                    left: x
                                                },
                                                ...child.props
                                            })
                                        }
                                    </AnimationPack>
                                );
                            })}


                            <AnimationPack>
                                <animated.div className={styles.leftTop} style={{
                                    left: rowSpace-styles.leftTopW-Number(variables.baseSpace),
                                    top: 0
                                }}>
                                    <LeftTop className={styles.leftTop} />
                                </animated.div>
                            </AnimationPack>
                            <AnimationPack delay={200}>
                                <animated.div className={styles.centerTop} style={{
                                    left: ((this.state.width-styles.centerTopW)/2)+Number(variables.baseSpace),
                                    top: `calc(${review.raspberryOutSize} - ${variables.baseSpace*4}px)`,
                                    display: (this.state.center ? "block" : "none")
                                }}>
                                    <CenterTop className={styles.centerTop} />
                                </animated.div>
                            </AnimationPack>
                            <AnimationPack delay={400}>
                                <animated.div className={styles.rightCenter} style={{
                                    left: ((Reviews.reviewWidth+rowSpace)*2)+Number(variables.baseSpace),
                                    top: `calc(50% - ${styles.rightCenterH/2}px)`,
                                    display: (this.state.oneByRow ? "none" : "block")
                                }}>
                                    <RightCenter className={styles.rightCenter} />
                                </animated.div>
                            </AnimationPack>
                            <AnimationPack delay={400}>
                                <animated.div className={styles.centerBottom} style={{
                                    left: ((this.state.width-styles.centerBottomW)/2),
                                    top: `calc(${Reviews.reviewHeight+(Reviews.reviewHeight-styles.centerBottomH)/2}px + ${raspberriesAddition} )`,
                                    display: (this.state.center ? "block" : "none")
                                }}>
                                    <CenterBottom className={styles.centerBottom} />
                                </animated.div>
                            </AnimationPack>
                            <AnimationPack delay={600}>
                                <animated.div className={styles.leftBottom} style={{
                                    left: rowSpace-(Number(variables.baseSpace)*8),
                                    bottom: -(Number(variables.largeSpace)*2.4)
                                }}>
                                    <LeftBottom className={styles.leftBottom} />
                                </animated.div>
                            </AnimationPack>
                        </section>
                    )
                }
            </Measure>
        );
    }
}