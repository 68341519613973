import React, {useState} from "react";
import FormWrap from "./FormWrap";
import TextInput from "./fields/TextInput";
import Button from "../parts/content/Button";

export default function ChooseVideoForm({callback, init}) {
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const submit = (values) => {
        setError(undefined);
        callback(values.code.value);
    };

    return (
        <FormWrap submit={submit} error={error} setError={setError}>
            <TextInput id="code" caption="Kód" value={init} required />
            <Button loading={loading}>vložit</Button>
        </FormWrap>
    );
}